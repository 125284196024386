import VARB from "../../assets/icon/VARB.png";
import ABB from "../../assets/icon/ABB.png";
import ACB from "../../assets/icon/ACB.png";
import BIDV from "../../assets/icon/BIDV.png";
import BVB from "../../assets/icon/BVB.png";
import BVB1 from "../../assets/icon/BVB-1.png";
import CTG from "../../assets/icon/CTG.png";
import DAB from "../../assets/icon/DAB.png";
import EIB from "../../assets/icon/EIB.png";
import IVB from "../../assets/icon/IVB.png";
import LBP from "../../assets/icon/LBP.png";
import MB from "../../assets/icon/MB.png";
import MSB from "../../assets/icon/MSB.png";
import NVB from "../../assets/icon/NVB.png";
import OJB from "../../assets/icon/OJB.png";
import PGB from "../../assets/icon/PGB.png";
import PVCB from "../../assets/icon/PVCB.png";
import PVCB1 from "../../assets/icon/PVCB-1.png";
import SCB from "../../assets/icon/SCB.png";
import SEAB from "../../assets/icon/SEAB.png";
import SHB from "../../assets/icon/SHB.png";
import TCB from "../../assets/icon/TCB.png";
import TPB from "../../assets/icon/TPB.png";
import VCB from "../../assets/icon/VCB.png";
import VIB from "../../assets/icon/VIB.png";
import VPB from "../../assets/icon/VPB.png";
import WOO from "../../assets/icon/WOO.png";

export const partners = [
    VARB,
    ABB,
    ACB,
    BIDV,
    BVB,
    BVB1,
    CTG,
    DAB,
    EIB,
    IVB,
    LBP,
    MB,
    MSB,
    NVB,
    OJB,
    PGB,
    PVCB,
    PVCB1,
    SCB,
    SEAB,
    SHB,
    TCB,
    TPB,
    VCB,
    VIB,
    VPB,
    WOO,
];
