import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";

const getArticlesByCate = async (articleByCateID) => {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_WEB}get-list-article-by-category/${articleByCateID}`, configHeaders());
    return data;
};
const getArticle = async (articleSlug) => {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_WEB}article/${articleSlug}`, configHeaders());
    return data;
};

const articlesWebService = {
    getArticlesByCate,
    getArticle,
};

export default articlesWebService;
