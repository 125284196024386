import React from "react";
import "./Footer.scss";
import logo from "../../assets/icon/logo2.png";
import facebook from "../../assets/icon/fb-menu2.png";
import twitter from "../../assets/icon/twitter.png";
import instagram from "../../assets/icon/in-menu2.png";
import ggplay2 from "../../assets/icon/ggplay2.png";
import appstore2 from "../../assets/icon/appstore2.png";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div>
                    <img src={logo} className="img-fluid" alt="logo" />
                </div>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <h2 className="title">THÔNG TIN LIÊN HỆ</h2>
                        <ul className="desc list-unstyled">
                            <li>
                                <p className="fw-bold">CỔNG THANH TOÁN / VÍ ĐIỆN TỬ HPAY</p>
                            </li>
                            <li>
                                <p>Hotline: 1900 0128</p>
                            </li>
                            <li>
                                <p>Địa chỉ: Yên Hoà, Q.Cầu Giấy, Hà Nội</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-6">
                        <h2 className={"title"}>VỀ HPAY</h2>
                        <ul className="desc list-unstyled">
                            <li>
                                <Link to={"/ve-chung-toi"} className="link-hover">
                                    Giới thiệu HPay
                                </Link>
                            </li>
                            <li className="mt-3">
                                <Link to={"/thoa-thuan-nguoi-dung"} className="link-hover">
                                    Thỏa thuận của người dùng
                                </Link>
                            </li>
                            <li className="mt-3">
                                <Link to={"/chinh-sach-bao-mat"} className="link-hover">
                                    Chính sách bảo mật
                                </Link>
                            </li>
                            <li className="mt-3">
                                <Link to={"/quy-dinh-khieu-nai-va-hoan-tien"} className="link-hover">
                                    Quy định về khiếu nại và hoàn tiền
                                </Link>
                            </li>
                        </ul>
                        <div className="mt-3">
                            <img src={facebook} className="me-3" alt="facebook" />
                            <img src={twitter} className="me-3" alt="twitter" />
                            <img src={instagram} alt="instagram" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-6">
                        <h2 className="title">SẢN PHẨM</h2>
                        <ul className="desc list-unstyled">
                            <li>
                                <Link to={"/san-pham/vi-dien-tu"} className="link-hover">
                                    Ví điện tử
                                </Link>
                            </li>
                            <li className="mt-3">
                                <Link to={"/san-pham"} className="link-hover">
                                    Thanh toán trực tuyến
                                </Link>
                            </li>
                            <li className="mt-3">
                                <Link to={"/san-pham/thu-ho-chi-ho"} className="link-hover">
                                    Thu hộ chi hộ
                                </Link>
                            </li>
                        </ul>
                        <p className="desc mt-5" style={{ color: "#6B6D6F" }}>
                            Ứng dụng HPay có mặt trên nền tảng Android và iOS
                        </p>
                        <div className="d-flex mt-4 flex-wrap gap-2">
                            <a href="https://apps.apple.com/vn/app/hpay/id6479690061" target="_blank">
                                <img src={appstore2} alt="ios" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.hpay.ewalletapp" target="_blank">
                                <img src={ggplay2} alt="adr" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
