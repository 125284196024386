import { useEffect, useState } from "react";
import logo from "../../assets/icon/logo1.png";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, Button, theme, Dropdown, Modal } from "antd";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { TbCategory } from "react-icons/tb";
import { FaPenToSquare } from "react-icons/fa6";
import { MdOutlineContactMail } from "react-icons/md";
import authService from "../../services/adminService/AuthAdminService";
import infoAdminService from "../../services/adminService/InfoAdminService";
import { FaRegListAlt } from "react-icons/fa";
import checkStatusUser from "../../config/UnAuthenticated";
import defaultAvatar from "../../assets/icon/user-icon.png";
import Authority from "../Authority/Authority";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
    const [isModalChangePasswordOpen, setIsModalChangePasswordOpen] = useState(false);
    const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [loadingChangePassword, setLoadingChangePassword] = useState(false);
    const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [isStatusUser, setIsStatusUser] = useState(false);

    const navigate = useNavigate();

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        setSelectedImage(imageFile);
    };

    useEffect(() => {
        try {
            const data = infoAdminService.getInfoAdmin();
            data.then((res) => {
                if (res?.status === 200) {
                    const data = res?.data;
                    setUserInfo(data);
                    formikUpdateProfile.setValues({ name: data.name, mobile: data.mobile, address: data.address });
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 1000,
                        theme: "light",
                    });
                }
            }).catch((err) => {
                if (checkStatusUser(err?.response?.statusText)) {
                    setIsStatusUser(true);
                }
                return;
            });
        } catch (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 1000,
                theme: "light",
            });
            return;
        }
    }, [isModalProfileOpen]);

    const showModalChangePassword = () => {
        setIsModalChangePasswordOpen(true);
    };
    const handleCancelChangePassword = () => {
        setLoadingChangePassword(false);
        formikChangePass.resetForm();
        setIsModalChangePasswordOpen(false);
    };

    const showModalProfile = () => {
        setIsModalProfileOpen(true);
    };

    const handleCancelProfile = () => {
        setLoadingUpdateProfile(false);
        formikUpdateProfile.resetForm();
        setIsModalProfileOpen(false);
    };
    const handleLogout = () => {
        try {
            const data = authService.AdminLogout();
            data.then((res) => {
                toast.success(res?.message, {
                    position: "top-center",
                    autoClose: 500,
                    theme: "light",
                });

                setTimeout(() => {
                    localStorage.clear();
                    navigate("/admin/login");
                }, 1000);
            }).catch((err) => {
                toast.error(err?.message, {
                    position: "top-center",
                    autoClose: 1000,
                    theme: "light",
                });
                setTimeout(() => {
                    localStorage.clear();
                    navigate("/admin/login");
                }, 1000);
            });
        } catch (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 1000,
                theme: "light",
            });
        }
    };
    const items = [
        {
            key: "1",
            label: <div onClick={showModalProfile}>Cập nhật thông tin</div>,
        },
        {
            key: "2",
            label: <div onClick={showModalChangePassword}>Đổi mật khẩu cá nhân</div>,
        },
        {
            type: "divider",
        },
        {
            key: "3",
            label: (
                <div className="w-100" onClick={handleLogout}>
                    Đăng xuất
                </div>
            ),
        },
    ];

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const formikChangePass = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Vui lòng nhập mật khẩu hiện tại"),
            newPassword: Yup.string().required("Vui lòng nhập mật khẩu mới"),
            confirmPassword: Yup.string()
                .required("Vui lòng nhập lại mật khẩu mới")
                .oneOf([Yup.ref("newPassword")], "Nhập lại mật khẩu không chính xác"),
        }),
        onSubmit: async (values) => {
            setLoadingChangePassword(true);
            try {
                const formData = new FormData();
                formData.append("old_password", values.oldPassword);
                formData.append("new_password", values.newPassword);
                formData.append("new_password_confirm", values.confirmPassword);

                const data = infoAdminService.changePass(formData);

                data.then((res) => {
                    if (res?.status === 200) {
                        toast.success(res?.message, {
                            position: "top-center",
                            autoClose: 500,
                            theme: "light",
                        });
                        handleCancelChangePassword();
                    } else {
                        toast.warn(res?.message, {
                            position: "top-center",
                            autoClose: 1000,
                            theme: "light",
                        });
                        handleCancelChangePassword();
                    }
                }).catch((err) => {
                    toast.error(err, {
                        position: "top-center",
                        autoClose: 2000,
                        theme: "light",
                    });
                    handleCancelChangePassword();
                    if (checkStatusUser(err?.response?.statusText)) {
                        setIsStatusUser(true);
                    }
                    return;
                });
            } catch (error) {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 1000,
                    theme: "light",
                });
                handleCancelChangePassword();
            }
        },
    });

    const formikUpdateProfile = useFormik({
        initialValues: {
            name: "",
            mobile: "",
            address: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Vui lòng điền tên"),
            mobile: Yup.string().required("Vui lòng điền số điện thoại"),
            address: Yup.string().required("Vui lòng điền địa chỉ"),
        }),
        onSubmit: (values) => {
            setLoadingUpdateProfile(true);
            try {
                const formData = new FormData();

                formData.append("name", values.name);
                formData.append("mobile", values.mobile);
                if (!!selectedImage) {
                    formData.append("avatar", selectedImage);
                }
                formData.append("address", values.address);

                const data = infoAdminService.updateProfile(formData);

                data.then((res) => {
                    if (res?.status === 200) {
                        toast.success(res?.message, {
                            position: "top-center",
                            autoClose: 1000,
                            theme: "light",
                        });

                        handleCancelProfile();
                    } else {
                        toast.warn(res?.message, {
                            position: "top-center",
                            autoClose: 1000,
                            theme: "light",
                        });
                        handleCancelProfile();
                    }
                }).catch((err) => {
                    toast.error(err, {
                        position: "top-center",
                        autoClose: 2000,
                        theme: "light",
                    });
                    handleCancelProfile();
                    if (checkStatusUser(err?.response?.statusText)) {
                        setIsStatusUser(true);
                    } else {
                        return;
                    }
                });
            } catch (error) {
                toast.error(error, {
                    position: "top-center",
                    autoClose: 1000,
                    theme: "light",
                });
                handleCancelProfile();
            }
        },
    });

    const handleOkCheckStatusUser = () => {
        setIsStatusUser(false);
        localStorage.clear();
        navigate("/admin/login");
    };

    return (
        <Layout style={{ height: "100vh" }}>
            {isStatusUser && <Authority oke={handleOkCheckStatusUser} status={isStatusUser} />}
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ backgroundColor: "#fff" }}>
                <Link
                    to={"/admin"}
                    style={{
                        height: "65px",
                        background: "#fff",
                        borderBottomRightRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px",
                        borderBottom: "1px solid #ccc",
                        fontSize: "100px",
                    }}
                >
                    <img src={logo} style={{ width: "100%", height: "40px", objectFit: "contain" }} alt="" />
                </Link>
                <Menu
                    theme="light"
                    className="fs-6"
                    mode="inline"
                    defaultSelectedKeys={["1"]}
                    onClick={({ key }) => {
                        if (key === "sign-out") {
                            localStorage.clear();
                            window.location.reload();
                            setTimeout(() => {
                                navigate("/");
                            }, 300);
                        } else {
                            navigate(key);
                        }
                    }}
                    items={[
                        {
                            key: "",
                            icon: <FaRegListAlt />,
                            label: "Thống kê",
                        },
                        {
                            key: "category",
                            icon: <TbCategory />,
                            label: "Danh mục",
                        },
                        {
                            key: "articles",
                            icon: <FaPenToSquare />,
                            label: "Bài viết",
                        },
                        {
                            key: "contact",
                            icon: <MdOutlineContactMail />,
                            label: "Liên hệ",
                        },
                    ]}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: "16px",
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="bottomRight"
                        arrow={{
                            pointAtCenter: true,
                        }}
                    >
                        <Button className="d-flex align-items-center" style={{ border: "none", marginRight: "30px" }}>
                            <img
                                src={!!userInfo.avatar ? userInfo.avatar : defaultAvatar}
                                className="img-fluid"
                                style={{ width: "55px", height: "auto", borderRadius: "50%" }}
                                alt="avatar"
                            />
                        </Button>
                    </Dropdown>
                </Header>
                <Content
                    style={{
                        maxHeight: "100vh",
                        overflow: "scroll",
                        background: "colorBgContainer",
                    }}
                >
                    <Outlet />
                </Content>

                <Modal title="THAY ĐỔI MẬT KHẨU" open={isModalChangePasswordOpen} footer={[null]} onCancel={handleCancelChangePassword}>
                    <form onSubmit={formikChangePass.handleSubmit}>
                        <div className="mt-4">
                            <label htmlFor="currenPass" className="form-label fs-6">
                                Mật khẩu hiện tại
                            </label>
                            <input
                                type="password"
                                className="form-control fs-6 text-error"
                                id="currenPass"
                                placeholder="Nhập mật khẩu hiện tại"
                                aria-describedby="currenPassHelp"
                                onChange={formikChangePass.handleChange("oldPassword")}
                                onBlur={formikChangePass.handleBlur("oldPassword")}
                                value={formikChangePass.values.oldPassword}
                            />
                            <div id="currenPassHelp" className="form-text text-danger ps-2">
                                {formikChangePass.touched.oldPassword && formikChangePass.errors.oldPassword
                                    ? formikChangePass.errors.oldPassword
                                    : null}
                            </div>
                        </div>
                        <div className="mt-3">
                            <label htmlFor="newPass" className="form-label fs-6">
                                Mật khẩu mới
                            </label>
                            <input
                                type="password"
                                className="form-control fs-6 text-error"
                                id="newPass"
                                placeholder="Nhập mật khẩu mới ( tối thiểu 6 ký tự )"
                                aria-describedby="newPassHelp"
                                onChange={formikChangePass.handleChange("newPassword")}
                                onBlur={formikChangePass.handleBlur("newPassword")}
                                value={formikChangePass.values.newPassword}
                            />
                            <div id="newPassHelp" className="form-text text-danger ps-2">
                                {formikChangePass.touched.newPassword && formikChangePass.errors.newPassword
                                    ? formikChangePass.errors.newPassword
                                    : null}
                            </div>
                        </div>
                        <div className="mt-3">
                            <label htmlFor="confirmPass" className="form-label fs-6">
                                Xác thực mật khẩu
                            </label>
                            <input
                                type="password"
                                className="form-control fs-6 text-error"
                                id="confirmPass"
                                placeholder="Nhập lại mật khẩu mới"
                                aria-describedby="confirmPassHelp"
                                onChange={formikChangePass.handleChange("confirmPassword")}
                                onBlur={formikChangePass.handleBlur("confirmPassword")}
                                value={formikChangePass.values.confirmPassword}
                            />
                            <div id="confirmPassHelp" className="form-text text-danger ps-2">
                                {formikChangePass.touched.confirmPassword && formikChangePass.errors.confirmPassword
                                    ? formikChangePass.errors.confirmPassword
                                    : null}
                            </div>
                        </div>

                        <button
                            className="btn mt-5"
                            type="submit"
                            style={{ width: "200px", height: "40px", backgroundColor: "#1677ff", color: "#fff" }}
                        >
                            {loadingChangePassword ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <span className="text-white fw-bold"> Xác nhận thay đổi </span>
                            )}
                        </button>
                    </form>
                </Modal>
                <Modal title="CẬP NHẬT THÔNG TIN" open={isModalProfileOpen} footer={[null]} onCancel={handleCancelProfile}>
                    <form onSubmit={formikUpdateProfile.handleSubmit}>
                        <div className="mt-4">
                            <label htmlFor="userName" className="form-label fs-6">
                                Họ và Tên
                            </label>
                            <input
                                type="text"
                                className="form-control fs-6 text-error"
                                id="userName"
                                placeholder="Nhập họ và tên cần thay đổi tại đây ..."
                                aria-describedby="userNameHelp"
                                onChange={formikUpdateProfile.handleChange("name")}
                                onBlur={formikUpdateProfile.handleBlur("name")}
                                value={formikUpdateProfile.values.name}
                            />
                            <div id="userNameHelp" className="form-text text-danger ps-2">
                                {formikUpdateProfile.touched.name && formikUpdateProfile.errors.name ? formikUpdateProfile.errors.name : null}
                            </div>
                        </div>
                        <div className="mt-3">
                            <label htmlFor="mobile" className="form-label fs-6">
                                Số điện thoai
                            </label>
                            <input
                                type="text"
                                className="form-control fs-6 text-error"
                                id="mobile"
                                placeholder="Nhập số điện thoại cần thay đổi tại đây ..."
                                aria-describedby="mobileHelp"
                                onChange={formikUpdateProfile.handleChange("mobile")}
                                onBlur={formikUpdateProfile.handleBlur("mobile")}
                                value={formikUpdateProfile.values.mobile}
                            />
                            <div id="mobileHelp" className="form-text text-danger ps-2">
                                {formikUpdateProfile.touched.mobile && formikUpdateProfile.errors.mobile ? formikUpdateProfile.errors.mobile : null}
                            </div>
                        </div>
                        <div className="mt-3">
                            <label htmlFor="avatar" className="form-label fs-6">
                                Ảnh đại diện
                            </label>
                            <input type="file" className="form-control fs-6" id="avatar" aria-describedby="avatarHelp" onChange={handleImageChange} />
                        </div>
                        <div className="mt-3">
                            <label htmlFor="address" className="form-label fs-6">
                                Địa chỉ
                            </label>
                            <input
                                type="text"
                                className="form-control fs-6 text-error"
                                id="address"
                                placeholder="Nhập địa chỉ cần thay đổi tại đây ..."
                                aria-describedby="addressHelp"
                                onChange={formikUpdateProfile.handleChange("address")}
                                onBlur={formikUpdateProfile.handleBlur("address")}
                                value={formikUpdateProfile.values.address}
                            />
                            <div id="addressHelp" className="form-text text-danger ps-2">
                                {formikUpdateProfile.touched.address && formikUpdateProfile.errors.address
                                    ? formikUpdateProfile.errors.address
                                    : null}
                            </div>
                        </div>

                        <button
                            className="btn mt-5"
                            type="submit"
                            style={{ width: "200px", height: "40px", backgroundColor: "#1677ff", color: "#fff" }}
                        >
                            {loadingUpdateProfile ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <span className="text-white fw-bold"> Xác nhận thay đổi </span>
                            )}
                        </button>
                    </form>
                </Modal>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
