import React, { useEffect, useState } from "react";
import time from "../../assets/icon/Clip path group.png";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import articlesWebService from "../../services/webService/ArticleWebService";
import moment from "moment";
import { toast } from "react-toastify";

const NewsByCategory = () => {
    const [dataNewsByCategory, setDataNewsByCategory] = useState([]);
    const location = useLocation();
    const newsByCateSlug = location.pathname.split("/")[2];
    const formatDate = (date) => moment.utc(date).format("DD-MM-YYYY HH:mm:ss");

    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            const data = articlesWebService.getArticlesByCate(newsByCateSlug);
            data.then((res) => {
                if (res?.status === 200) {
                    setDataNewsByCategory(res?.data);
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 500,
                        theme: "light",
                    });
                }
            });
        } catch (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 500,
                theme: "light",
            });
        }
    }, [newsByCateSlug]);

    return (
        <div className="section-top">
            <div className="container">
                <Breadcrumb pathFrom={"/tin-tuc"} textFrom={"Tin tức"} to={dataNewsByCategory?.find((name) => name.category)?.category?.title} />
                <div className="row mb-5">
                    {dataNewsByCategory?.map((NewsByCategory) => (
                        <Link
                            key={NewsByCategory.id}
                            to={`/bai-viet/${NewsByCategory.slug}`}
                            className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-none card-custom"
                        >
                            <button className="btn-card">Xem ngay</button>

                            <div className="d-flex flex-column justify-content-center">
                                <img src={NewsByCategory.thumbnail_url} className="img-fluid img-card" alt="thumbnail" />
                                <h4 className="fs-6 text-hover text-uppercase text-limit mt-3" style={{ height: "40px" }}>
                                    {NewsByCategory.title}
                                </h4>
                                <p className="text-lowercase my-2 text-secondary text-limit">{NewsByCategory.description}</p>
                                <div className="d-flex align-items-center">
                                    <img src={time} className="img-fluid me-2" alt="" />
                                    <span className="text-secondary">{formatDate(NewsByCategory.created_at)}</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NewsByCategory;
