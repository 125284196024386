import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Card, Select, Table, Tag, Button, Tooltip, Modal, Input, DatePicker } from "antd";
import categoryService from "../../services/adminService/CateAdminService";
import moment from "moment";
import { MdDelete, MdEdit, MdAdsClick } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import checkStatusUser from "../../config/UnAuthenticated";
import * as Yup from "yup";
import Authority from "../../components/Authority/Authority";

const { RangePicker } = DatePicker;

const Category = () => {
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [priority, setPriority] = useState(1);
    const [status, setStatus] = useState(1);
    const [preDataUpdate, setPreDataUpdate] = useState({});
    const [data, setData] = useState([]);
    const [categoryID, setCategoryID] = useState(null);
    const [visible, setVisible] = useState(false);

    const [titleSearch, setTitleSearch] = useState("");
    const [statusSearch, setStatusSearch] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectPage, setSelectPage] = useState(1);
    const [selectLimit, setSelectLimit] = useState(20);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectStartDate, setSelectStartDate] = useState(null);
    const [selectEndDate, setSelectEndDate] = useState(null);

    const [open, setOpen] = useState(false);
    const [isStatusUser, setIsStatusUser] = useState(false);

    const categoriesRef = useRef(null);
    const navigate = useNavigate();

    const showModalDelete = (id) => {
        setCategoryID(id);
        setVisible(true);
    };

    const handleCancelDelete = () => {
        setVisible(false);
    };

    const resetForm = () => {
        formik.resetForm();
        setPriority(1);
        setStatus(1);
        setCategoryID(null);
    };

    const showModal = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        resetForm();
        setOpen(false);
    };
    const handlePageChange = (page) => {
        setSelectPage(page);
    };

    const formik = useFormik({
        initialValues: {
            title: preDataUpdate?.title || "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Vui lòng điền tiêu đề"),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append("title", values.title);
                formData.append("priority", priority);
                formData.append("status", status);
                if (categoryID) {
                    const data = categoryService.updatedCategory(formData, categoryID);
                    data.then((res) => {
                        if (res?.status === 200) {
                            toast.success(res?.message, {
                                position: "top-center",
                                autoClose: 500,
                                theme: "light",
                            });
                            setLoading(false);
                            fetchData();
                            handleCancel();
                        } else {
                            toast.warn(res?.message, {
                                position: "top-right",
                                autoClose: 1000,
                                theme: "light",
                            });

                            setLoading(false);
                        }
                    }).catch((err) => {
                        setLoading(false);
                        if (checkStatusUser(err?.response?.statusText)) {
                            setIsStatusUser(true);
                        } else {
                            return;
                        }
                    });
                } else {
                    const data = categoryService.createdCategory(formData);
                    data.then((res) => {
                        if (res?.status === 200) {
                            toast.success(res?.message, {
                                position: "top-center",
                                autoClose: 500,
                                theme: "light",
                            });
                            setLoading(false);
                            resetForm();
                            fetchData();
                            handleCancel();
                        } else {
                            toast.warn(res?.message, {
                                position: "top-center",
                                autoClose: 1000,
                                theme: "light",
                            });
                            setLoading(false);
                        }
                    }).catch((err) => {
                        setLoading(false);
                        if (checkStatusUser(err?.response?.statusText)) {
                            setIsStatusUser(true);
                        } else {
                            return;
                        }
                    });
                }
            } catch (err) {
                toast.error(err, {
                    position: "top-center",
                    autoClose: 500,
                    theme: "light",
                });
                setLoading(false);
            }
        },
    });
    useEffect(() => {
        try {
            const data = categoryService.getCategory(categoryID);
            data.then((res) => {
                if (res?.status === 200) {
                    const data = res?.data;
                    setPriority(data.priority);
                    setStatus(data.status);
                    formik.setValues({ title: data.title });
                    setPreDataUpdate(data);
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch((err) => {
                if (checkStatusUser(err?.response?.statusText)) {
                    setIsStatusUser(true);
                } else {
                    return;
                }
            });
        } catch (err) {
            toast.error(err, {
                position: "top-center",
                autoClose: 500,
                theme: "light",
            });
        }
    }, [categoryID]);

    const handleDelete = async () => {
        try {
            const data = categoryService.deletedCategory(categoryID);
            data.then((res) => {
                if (res?.status === 200) {
                    toast.success(res?.message, {
                        position: "top-center",
                        autoClose: 500,
                        theme: "light",
                    });
                    setVisible(false);
                    fetchData();
                    handleCancelDelete();
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 500,
                        theme: "light",
                    });
                }
            }).catch((err) => {
                if (checkStatusUser(err?.response?.statusText)) {
                    setIsStatusUser(true);
                } else {
                    return;
                }
            });
        } catch (err) {
            toast.error(err, {
                position: "top-center",
                autoClose: 500,
                theme: "light",
            });
        }
    };

    const handleUpdate = async (id) => {
        setCategoryID(id);
        showModal();
    };

    const columns = [
        {
            title: "STT",
            dataIndex: "key",
            key: "key",
        },
        {
            title: "Tiêu đề",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            render: (status) => {
                const tag = +status === 1 ? "Hoạt động" : "Không hoạt động";
                const color = +status === 1 ? "green" : "volcano";
                return (
                    <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                    </Tag>
                );
            },
        },

        {
            title: "Ưu tiên",
            dataIndex: "priority",
            key: "priority",
            render: (priority) => {
                const tag = +priority === 1 ? "Ưu tiên" : "Không ưu tiên";
                const color = +priority === 1 ? "green" : "volcano";
                return (
                    <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                    </Tag>
                );
            },
        },

        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "updated_at",
            key: "updated_at",
        },
        {
            title: "Tác giả",
            dataIndex: "author",
            key: "author",
        },
        {
            title: "Hành động",
            dataIndex: "action",
            key: "action",
            render: (id) => {
                return (
                    <div className="d-flex gap-2">
                        <Tooltip placement="top" title={"Xóa"}>
                            <MdDelete className="fs-4 text-danger" style={{ cursor: "pointer" }} onClick={() => showModalDelete(id)} />
                        </Tooltip>

                        <Tooltip placement="top" title={"Cập nhật"}>
                            <MdEdit className="fs-4 text-success" style={{ cursor: "pointer" }} onClick={() => handleUpdate(id)} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        fetchData();
    }, [selectPage, selectLimit]);

    const fetchData = async (params = {}) => {
        try {
            const data = categoryService.getCategories({ ...params, page: selectPage, limit: selectLimit });
            data.then((res) => {
                if (res?.status === 200) {
                    setData(res?.data);
                    setCurrentPage(res?.current_page);
                    setPerPage(res?.per_page);
                    setTotalRecords(res?.total_records);
                    setLoading(false);
                    setLoadingSearch(false);
                    if (Object.keys(params).length !== 0) {
                        categoriesRef.current.scrollIntoView({ behavior: "smooth" });
                    }
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 500,
                        theme: "light",
                    });
                    setLoading(false);
                    setLoadingSearch(false);
                }
            }).catch((err) => {
                setLoadingSearch(false);
                setLoading(false);
                if (checkStatusUser(err?.response?.statusText)) {
                    setIsStatusUser(true);
                }
                return;
            });
        } catch (err) {
            toast.error(err, {
                position: "top-center",
                autoClose: 500,
                theme: "light",
            });
        }
    };

    const handleClickSearchArticles = () => {
        setLoadingSearch(true);
        const searchData = {};

        if (titleSearch) {
            searchData.title = titleSearch;
        }

        if (statusSearch) {
            searchData.status = statusSearch;
        }

        if (selectStartDate) {
            searchData.start_date = selectStartDate;
        }

        if (selectEndDate) {
            searchData.end_date = selectEndDate;
        }
        fetchData(searchData);
    };

    const handleData = data?.map((item) => {
        const formatDate = (date) => moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
        return {
            ...item,
            created_at: formatDate(item.created_at),
            updated_at: formatDate(item.updated_at),
            author: item.user.name,
            action: item.id,
        };
    });

    const RedirectToAddCate = () => {
        resetForm();
        showModal();
    };

    const handleChangeSearchStatus = (value) => {
        setStatusSearch(value);
    };

    const handleRangePickerChange = (dates, dateStrings) => {
        if (dates && dates?.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
            setSelectStartDate(dateStrings[0]);
            setSelectEndDate(dateStrings[1]);
        } else {
            if (dates === null) {
                setStartDate(null);
                setEndDate(null);
                setSelectStartDate(null);
                setSelectEndDate(null);
            }
        }
    };

    const handleOkCheckStatusUser = () => {
        setIsStatusUser(false);
        localStorage.clear();
        navigate("/admin/login");
    };

    return (
        <>
            <div className="p-3">
                {isStatusUser && <Authority oke={handleOkCheckStatusUser} status={isStatusUser} />}

                <Card
                    title="TÌM KIẾM DANH MỤC"
                    extra={
                        <Button type="primary" style={{ width: "135px" }} onClick={handleClickSearchArticles}>
                            {loadingSearch ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <span className="fw-bold f-flex align-items-center">
                                    <MdAdsClick className="me-2 fs-5" />
                                    Tìm kiếm
                                </span>
                            )}
                        </Button>
                    }
                    bordered={false}
                >
                    <div className="d-flex gap-5">
                        <div style={{ width: "500px" }}>
                            <label htmlFor="email" className="fw-bolder text-secondary mb-1">
                                Tiêu đề
                            </label>
                            <Input
                                className="form-control d-flex"
                                id="email"
                                placeholder="Nhập tiêu đề danh mục cần tìm tại đây ..."
                                aria-describedby="emailHelp"
                                onChange={(e) => setTitleSearch(e.target.value)}
                                value={titleSearch}
                                allowClear
                            />
                        </div>
                        <div className="d-flex flex-column" style={{ width: "180px" }}>
                            <label htmlFor="status" className="fw-bolder text-secondary mb-1">
                                Trạng thái
                            </label>
                            <Select
                                defaultValue={statusSearch}
                                id="status"
                                size="large"
                                onChange={handleChangeSearchStatus}
                                options={[
                                    {
                                        value: "",
                                        label: "Tất cả",
                                    },
                                    {
                                        value: 1,
                                        label: "Hoạt động",
                                    },
                                    {
                                        value: 2,
                                        label: "Không hoạt động",
                                    },
                                ]}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <label htmlFor="date" className="fw-bolder text-secondary mb-1">
                                Ngày/Tháng/Năm
                            </label>
                            <RangePicker
                                size="large"
                                classNames={"fs-6"}
                                id={"date"}
                                onChange={handleRangePickerChange}
                                value={[startDate, endDate]}
                                format="DD/MM/YYYY"
                                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                                className="fs-6"
                            />
                        </div>
                    </div>
                </Card>
            </div>
            <Modal title="Xác nhận xóa" open={visible} onCancel={handleCancelDelete} onOk={handleDelete} okText="Xác nhận" cancelText="Hủy">
                <p>Bạn có chắc chắn muốn xóa danh mục này không?</p>
            </Modal>
            <Modal title={!!categoryID ? "CẬP NHẬT DANH MỤC" : " TẠO DANH MỤC"} open={open} onCancel={handleCancel} width={800} footer={[null]}>
                <div className="p-2">
                    <form method="POST" onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="title" className="text-label">
                                Tiêu đề <span className="text-danger">*</span>
                            </label>
                            <textarea
                                type="text"
                                rows="4"
                                cols="50"
                                className="form-control fs-6 ps-3 text-error"
                                id="title"
                                placeholder="Thêm tiêu đề danh mục tại đây ..."
                                aria-describedby="titleHelp"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title}
                            />
                            <div id="titleHelp" className="form-text text-danger ps-1">
                                {formik.touched.title && formik.errors.title ? formik.errors.title : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-12 d-flex flex-column">
                                <label htmlFor="priority" className="text-label">
                                    Độ ưu tiên
                                </label>
                                <Select
                                    value={priority || 1}
                                    size="large"
                                    id="priority"
                                    onChange={(value) => setPriority(value)}
                                    options={[
                                        {
                                            value: 1,
                                            label: "Ưu tiên",
                                        },
                                        {
                                            value: 2,
                                            label: "Không ưu tiên",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="col-md-6 col-12 d-flex flex-column">
                                <label htmlFor="status" className="text-label">
                                    Trạng thái
                                </label>
                                <Select
                                    value={status || 1}
                                    size="large"
                                    id="status"
                                    onChange={(value) => setStatus(value)}
                                    options={[
                                        {
                                            value: 1,
                                            label: "Hoạt động",
                                        },
                                        {
                                            value: 2,
                                            label: "Không hoạt động",
                                        },
                                    ]}
                                />
                            </div>
                        </div>

                        <button
                            className="btn mt-5"
                            type="submit"
                            style={{ width: "200px", height: "40px", backgroundColor: "#1677ff", color: "#fff" }}
                        >
                            {loading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <span className="text-white fw-bold"> {!!categoryID ? "Cập nhật danh mục" : " Tạo danh mục"} </span>
                            )}
                        </button>
                    </form>
                </div>
            </Modal>

            <div ref={categoriesRef} className="p-3">
                <Card
                    title="DANH SÁCH DANH MỤC"
                    extra={
                        <Button type="primary" className="fw-bold f-flex align-items-center" onClick={RedirectToAddCate}>
                            <FaPlus style={{ marginTop: "1px" }} className="me-2 mb-1 fs-6" />
                            <span> Tạo Danh Mục</span>
                        </Button>
                    }
                    bordered={false}
                >
                    <Table
                        columns={columns}
                        dataSource={handleData?.map((item, index) => ({ ...item, status: item.status, key: index + 1 + (currentPage - 1) * perPage }))}
                        pagination={{
                            current: currentPage,
                            pageSize: perPage,
                            total: totalRecords,
                            showSizeChanger: true,
                            onChange: handlePageChange,
                            onShowSizeChange: (current, size) => {
                                setCurrentPage(current);
                                setPerPage(size);
                                setSelectLimit(size);
                            },
                        }}
                    />
                </Card>
            </div>
        </>
    );
};

export default Category;
