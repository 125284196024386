import React, { useEffect } from "react";
import Aos from "aos";
import "./Banner.scss";
const Banner = (props) => {
    const { bgr, img, title, desc, apps } = props;
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);
    return (
        <div>
            <div className="d-none d-lg-block">
                <div className="banner-web" style={{ backgroundImage: `linear-gradient(${bgr})` }}>
                    <div className="btn-content">
                        <h2 className="fs-1 fw-bold">{title}</h2>
                        <div className="desc-2 pb-3">
                            {desc?.map((des, index) => (
                                <p key={index} className="mt-3">
                                    {des}
                                </p>
                            ))}
                        </div>
                        <div className="bn-app d-flex gap-3 mt-3">
                            {apps && (
                                <>
                                    <a href="https://play.google.com/store/apps/details?id=com.hpay.ewalletapp" target="_blank">
                                        <img src={apps[0]} alt={apps[0]} />
                                    </a>
                                    <a href="https://apps.apple.com/vn/app/hpay/id6479690061" target="_blank">
                                        <img src={apps[1]} alt={apps[1]} />
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="btn-image">
                        <div data-aos="fade-up-left">
                            <img src={img} alt={img} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-block d-lg-none">
                <div className="banner-mobile">
                    <div>
                        <div data-aos="fade-up-left">
                            <img src={img} alt={img} className="img-fluid" />
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="bn-title">
                            <h2 className="fs-1 fw-bold">{title}</h2>
                        </div>
                        <div className="desc-2 pb-2">
                            {desc.map((des, index) => (
                                <p key={index} className="mt-3">
                                    {des}
                                </p>
                            ))}
                        </div>
                        <div className="bn-app d-flex gap-3 mt-3">
                            {apps && (
                                <>
                                    <a href="https://play.google.com/store/apps/details?id=com.hpay.ewalletapp" target="_blank">
                                        <img src={apps[0]} alt={apps[0]} />
                                    </a>
                                    <a href="https://apps.apple.com/vn/app/hpay/id6479690061" target="_blank">
                                        <img src={apps[1]} alt={apps[1]} />
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
