import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";

const getCategories = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_WEB}category`, configHeaders());
    return data;
};

const cateWebService = {
    getCategories,
};

export default cateWebService;
