import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";
import dataLocalStorage from "../../config/DataLocalStorage";

const createArticle = async (formData) => {
    const customHeaders = {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}article/store`, formData, configHeaders(customHeaders));
    return data;
};

const getArticles = async (params) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_ADMIN}article`, { ...configHeaders(customHeaders), params: params });
    return data;
};

const getArticle = async (articleID) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };

    const { data } = await axios.get(`${process.env.REACT_APP_HOST_ADMIN}article/edit/${articleID}`, configHeaders(customHeaders));
    return data;
};

const updatedArticle = async (formData, articleID) => {
    const customHeaders = {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}article/update/${articleID}`, formData, configHeaders(customHeaders));

    return data;
};
const deletedArticle = async (articleID) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.delete(`${process.env.REACT_APP_HOST_ADMIN}article/${articleID}`, configHeaders(customHeaders));

    return data;
};

const articleService = {
    createArticle,
    getArticles,
    updatedArticle,
    getArticle,
    deletedArticle,
};

export default articleService;
