import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
const Agreement = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="section-top">
            <div className="container">
                <Breadcrumb pathFrom={"/"} textFrom={"Trang chủ"} to={"Thỏa thuận người dùng"} />
                <div className="mx-3 mx-md-5">
                    <h2 className="fs-3 fw-bold my-4 text-center text-uppercase">Thỏa thuận của người dùng</h2>
                    <div className="mb-5">
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>1. GIỚI THIỆU</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    HPay nghiêm túc thực hiện trách nhiệm của mình liên quan đến bảo mật thông tin&nbsp;theo các quy định về bảo vệ bí
                                    mật thông tin cá nhân của pháp luật Việt Nam Và cam kết&nbsp;tôn trọng quyền riêng tư và sự quan tâm của tất cả
                                    người dùng đối với các sản phẩm, dịch&nbsp;vụ do HPay và/hoặc các đối tác của HPay cung cấp và được phân phối qua
                                    HPay ("Dịch&nbsp;Vụ"), bao gồm:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>- Dịch vụ cổng thanh toán điện tử (nội địa và quốc tế);</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>- Dịch vụ hỗ trợ thu hộ, chi hộ;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>- Dịch vụ Ví điện tử HPay.</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    - Các dịch vụ khác do Bên A cung cấp tại từng thời điểm phù hợp quy định của Pháp luật&nbsp;và sự chấp thuận hoặc
                                    cấp phép của Cơ quan nhà nước có thẩm quyền.&nbsp;Trong phạm vi điều chỉnh của Chính sách này, Hpay&nbsp;là bên
                                    Bên Kiểm soát và xử lý dữ&nbsp;liệu cá nhân có quyền quyết định mục đích và phương tiện xử lý dữ liệu cá nhân.
                                    Tuy&nbsp;nhiên, Người dùng đồng ý rằng mức độ bảo mật còn phụ thuộc vào thiết bị người dùng&nbsp;đang sử dụng, kết
                                    nối internet hoặc kết nối nội bộ của người dùng và các yếu tố khác thuộc&nbsp;về trách nhiệm của bên thứ ba. Người
                                    dùng hiểu rõ và chấp nhận rủi ro về bảo mật (nếu&nbsp;có) khi sử dụng Dịch vụ do Nền tảng&nbsp;HPay cung cấp.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    1.1. Người dùng có nghĩa là người truy cập nền tảng để sử dụng các Dịch Vụ (gọi chung và&nbsp;gọi riêng là “Người
                                    Dùng”, “bạn” hoặc “của bạn” hoặc "Chủ thể dữ liệu").
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    1.2. Mục đích thiết lập Chính sách bảo mật này ("Chính sách bảo mật" hay "Chính sách"): Giúp&nbsp;người
                                    dùng&nbsp;hiểu được cách thức HPay thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá&nbsp;nhân mà bạn đã cung
                                    cấp cho&nbsp;HPay và/hoặc lưu giữ về bạn ở thời điểm hiện nay hoặc&nbsp;trong tương lai, cũng như để giúp bạn đưa
                                    ra quyết định sáng suốt trước khi cung cấp cho&nbsp;HPay bất kỳ dữ liệu cá nhân nào của bạn. Do đó, người dùng
                                    cần&nbsp;tham khảo đầy đủ chính&nbsp;sách của các bên liên quan trước khi quyết định truy cập và sử dụng Dịch vụ
                                    của HPay.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    1.3. "Dữ Liệu Cá Nhân" hay "dữ liệu cá nhân" có nghĩa là thông tin dưới dạng ký hiệu, chữ&nbsp;viết, chữ số, hình
                                    ảnh, âm thanh hoặc dạng tương tự trên môi trường điện tử gắn liền với&nbsp;một con người cụ thể hoặc giúp xác định
                                    một con người cụ thể. Dữ liệu cá nhân bao gồm&nbsp;dữ liệu cá nhân cơ bản và dữ liệu cá nhân nhạy cảm.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>Cụ thể:&nbsp;Dữ liệu cá nhân cơ bản bao gồm:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>a) Họ, chữ đệm và tên khai sinh, tên gọi khác (nếu có);</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>b) Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc mất tích;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>c) Giới tính;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    d) Nơi sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm trú, nơi ở hiện tại, quê quán,
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>địa chỉ liên hệ;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>đ) Quốc tịch;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>e) Hình ảnh của cá nhân;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    g) Số điện thoại, số chứng minh nhân dân, số định danh cá nhân, số hộ chiếu, số giấy phép&nbsp;lái xe, số biển số
                                    xe, số mã số thuế cá nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>h) Tình trạng hôn nhân;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>i) Thông tin về mối quan hệ gia đình (cha mẹ, con cái);</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    k) Thông tin về tài khoản số của cá nhân; dữ liệu cá nhân phản ánh hoạt động, lịch sử hoạt
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>động trên không gian mạng;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    l) Các thông tin khác gắn liền với một con người cụ thể hoặc giúp xác định một con người
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>cụ thể không thuộc quy định tại khoản 4 Điều này.</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>Dữ liệu cá nhân nhạy cảm:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Là dữ liệu cá nhân gắn liền với quyền riêng tư của cá nhân mà khi bị xâm phạm sẽ gây
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>ảnh hưởng trực tiếp tới quyền và lợi ích hợp pháp của cá nhân gồm:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>a) Quan điểm chính trị, quan điểm tôn giáo;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b) Tình trạng sức khỏe và đời tư được ghi trong hồ sơ bệnh án, không bao gồm thông tin
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>về nhóm máu;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>c) Thông tin liên quan đến nguồn gốc chủng tộc, nguồn gốc dân tộc;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    d) Thông tin về đặc điểm di truyền được thừa hưởng hoặc có được của cá nhân;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>đ) Thông tin về thuộc tính vật lý, đặc điểm sinh học riêng của cá nhân;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>e) Thông tin về đời sống tình dục, xu hướng tình dục của cá nhân;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    g) Dữ liệu về tội phạm, hành vi phạm tội được thu thập, lưu trữ bởi các cơ quan thực thi&nbsp;pháp luật;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    h) Thông tin khách hàng của tổ chức tín dụng, chi nhánh ngân hàng nước ngoài, tổ chức&nbsp;cung ứng dịch
                                    vụ&nbsp;trung gian thanh toán, các tổ chức được phép khác, gồm: thông tin định&nbsp;danh khách hàng theo quy định
                                    của pháp luật, thông tin về tài khoản, thông tin về tiền gửi,&nbsp;thông tin về tài sản gửi, thông tin về giao
                                    dịch, thông&nbsp;tin về tổ chức, cá nhân là bên bảo&nbsp;đảm tại tổ chức tín dụng, chi nhánh ngân hàng, tổ chức
                                    cung ứng dịch vụ trung gian thanh&nbsp;toán;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>i) Dữ liệu về vị trí của cá nhân được xác định qua dịch vụ định vị;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    k) Dữ liệu cá nhân khác được pháp luật quy định là đặc thù và cần có biện pháp bảo mật&nbsp;cần thiết.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    1.4. Bên xử lý dữ liệu cá nhân khác: là tổ chức, cá nhân thực hiện việc xử lý dữ liệu thay mặt&nbsp;cho HPay trong
                                    một số trường hợp (như lưu trữ), thông qua một hợp đồng hoặc thỏa thuận&nbsp;với HPay.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    1.5. Bên thứ ba là tổ chức, cá nhân ngoài Người dùng, HPay, Bên xử lý dữ liệu cá nhân khác&nbsp;được phép
                                    xử&nbsp;lý dữ liệu cá nhân của Người dùng theo Chính sách, quy định của HPay và&nbsp;pháp luật.1.6. Bằng việc sử
                                    dụng Các Dịch Vụ, đăng ký một tài khoản với HPay hoặc truy cập Dịch vụ,&nbsp;bạn xác nhận và đồng ý rằng bạn chấp
                                    nhận các phương pháp, yêu cầu, và/hoặc chính sách&nbsp;được mô tả trong Chính sách bảo mật này, và theo đây bạn
                                    đồng ý cho phép HPay thu&nbsp;thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn như mô tả trong đây.
                                    NẾU&nbsp;BẠN KHÔNG ĐỒNG Ý CHO PHÉP XỬ LÝ DỮ LIỆU CÁ NHÂN CỦA BẠN NHƯ&nbsp;MÔ TẢ TRONG CHÍNH SÁCH NÀY, VUI LÒNG
                                    KHÔNG SỬ DỤNG CÁC DỊCH&nbsp;VỤ CỦA HPAY HAY TRUY CẬP NỀN TẢNG HOẶC TRANG WEB CỦA HPAY.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    1.7. Nếu HPay thay đổi Chính sách bảo mật, HPay sẽ thông báo cho bạn bao gồm cả thông qua&nbsp;việc đăng tải những
                                    thay đổi đó hoặc Chính sách bảo mật sửa đổi trên Nền tảng của HPay.&nbsp;Trong phạm vi pháp luật&nbsp;cho phép,
                                    việc tiếp tục sử dụng các Dịch Vụ hoặc Nền Tảng, bao&nbsp;gồm giao dịch của bạn, được xem là bạn đã công nhận và
                                    đồng ý với các thay đổi trong&nbsp;Chính Sách Bảo Mật này.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>2. MỤC ĐÍCH, PHẠM VI THU THẬP VÀ XỬ LÝ THÔNG TIN:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    HPay thu thập thông tin cá nhân cần thiết của người dùng nhằm các mục đích sau:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    2.1. Xử lý các yêu cầu của Quý khách hàng khi đề nghị cung cấp các sản phẩm dịch vụ:
                                </span>
                            </span>
                        </p>
                        <ul style={{ paddingLeft: 48 }}>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Nhận biết/nhận diện, xác minh danh tính, lý lịch;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Cập nhật thông tin cá nhân;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Kiểm tra, đối chiếu với các nguồn dữ liệu liên quan;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Nhập liệu, kiểm tra, kiểm soát tính đầy đủ và chính xác của các dữ liệu được nhập vào&nbsp;hệ thống;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Kiểm tra, đánh giá, thẩm định, phê duyệt điều kiện sử dụng dịch vụ của khách hàng;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Phục vụ mục đích phòng, chống rửa tiền, chống tài trợ khủng bố, tuân thủ cấm vận hoặc
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>gửi cho cơ quan có thẩm quyền theo quy định từng thời kỳ;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Xác thực Khách hàng/người dùng Dịch vụ để có thể truy cập tài khoản số/điện tử, để cá
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            biệt hóa trải nghiệm của Quý Khách hàng đối với các sản phẩm dịch vụ do HPay cung
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>cấp;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Kích hoạt/mở, quản lý, duy trì, đóng/khóa (các) dịch vụ và tiện ích sản phẩm dịch vụ mà
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Khách hàng sử dụng;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Cung cấp/gửi báo cáo giao dịch, tin nhắn/xác nhận số dư/hóa đơn, thư tín, thư hoặc các&nbsp;thông báo
                                            khác&nbsp;(bao gồm nhưng không giới hạn: thông báo cập nhật về các thay đổi, bao&nbsp;gồm mọi sửa đổi, bổ
                                            sung, mở rộng, đình chỉ và thay thế đối với sản phẩm dịch vụ và&nbsp;tiện ích tới Khách hàng hoặc các bên
                                            khác có liên quan;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Thực hiện các chương trình quảng cáo, tiếp thị, khuyến mại, ưu đãi, hỗ trợ chăm sóc&nbsp;khách hàng của
                                            HPay.
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Trả lời các câu hỏi và khiếu nại của Quý khách hàng và giải quyết tranh chấp.
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "8pt",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Phục vụ cho việc xử lý các yêu cầu của Quý khách hàng liên quan đến đề nghị cung cấp&nbsp;Dịch vụ.
                                        </span>
                                    </span>
                                </p>
                            </li>
                        </ul>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    2.2. Sử dụng, phân tích để phát triển, cung cấp, cải tiến nâng cao chất lượng các sản phẩm&nbsp;dịch vụ gồm:
                                </span>
                            </span>
                        </p>
                        <ul style={{ paddingLeft: 48 }}>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Tạo dữ liệu, báo cáo và thống kê, phản hồi cho HPay và/hoặc cho các bên có liên quan; Tiến hành nghiên
                                            cứu thị trường, khảo sát và phân tích dữ liệu liên quan đến sản phẩm&nbsp;dịch vụ, tiện ích do HPay cung
                                            cấp và/hoặc HPay cùng với các bên khác cung cấp;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Đánh giá rủi ro, phân tích xu hướng, thống kê, lên kế hoạch, bao gồm phân tích xử lý dữ&nbsp;liệu thống
                                            kê, rủi ro để tạo lập và duy trì các hệ thống quản trị rủi ro;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "8pt",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Thu thập, ghi nhận đánh giá, ý kiến phản hồi của Khách hàng thông qua các cuộc khảo&nbsp;sát để cải thiện
                                            chất lượng dịch vụ.
                                        </span>
                                    </span>
                                </p>
                            </li>
                        </ul>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    2.3. Đáp ứng các yêu cầu để HPay thực hiện quyền và nghĩa vụ của mình trong việc tuân thủ&nbsp;quy định pháp luật
                                    và quy định của HPay, gồm:
                                </span>
                            </span>
                        </p>
                        <ul style={{ paddingLeft: 48 }}>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Quản lý và ghi âm các cuộc gọi/ghi hình và các liên lạc thông qua kênh điện tử với Khách&nbsp;hàng và các
                                            bên có liên quan khác;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Quản lý các lợi ích hoặc quyền lợi liên quan đến mối quan hệ của HPay với Khách hàng&nbsp;hoặc phát sinh
                                            từ việc Khách hàng tham gia vào các sự kiện/chiến dịch/chương trình&nbsp;quảng cáo, tiếp thị, khuyến mại,
                                            ưu đãi, hỗ trợ của HPay hoặc HPay kết hợp với tổ chức,&nbsp;cá nhân khác;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Quản lý cơ sở hạ tầng và hoạt động kinh doanh của HPay;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Bảo vệ hoặc thực thi các quyền của HPay, bao gồm nhưng không giới hạn: việc để thu&nbsp;các khoản phí, thu
                                            hồi nợ; làm việc với cơ quan chức năng, cá nhân liên quan để thu hồi&nbsp;nợ;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Đáp ứng hoặc tuân thủ các chính sách nội bộ của HPay;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Đáp ứng hoặc tuân thủ các thủ tục, văn bản quy phạm pháp luật, quy tắc, quy định, hướng&nbsp;dẫn, công
                                            văn, chỉ thị hoặc yêu cầu được ban hành bởi bất kỳ tòa án, cơ quan có thẩm&nbsp;quyền (trong nước hoặc
                                            quốc tế)&nbsp;(bao gồm nhưng không giới hạn việc tiết lộ thông tin&nbsp;cho cơ quan có thẩm quyền hoặc bất
                                            kỳ mục đích nào khác được yêu cầu hoặc cho phép&nbsp;bởi bất kỳ luật, quy định, hướng dẫn của các cơ quan
                                            có thẩm quyền);
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Để ngăn chặn hoặc điều tra bất kỳ hoạt động gian lận, phi pháp, thiếu sót hay hành vi sai&nbsp;trái nào,
                                            cho dù đã diễn ra hay chưa, có liên quan đến việc Khách hàng sử dụng Các Dịch&nbsp;Vụ của HPay hay không
                                            hay bất kỳ vấn đề nào phát sinh từ quan hệ của bạn với HPay
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Tiến hành giao dịch mua bán, chuyển nhượng, chuyển giao việc kinh doanh và/hoặc tài&nbsp;sản của HPay;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Cho mục đích lưu trữ và tra cứu thông tin, dữ liệu;</span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "0cm",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>
                                            Thực hiện và tuân thủ với các thoả thuận, hợp đồng giữa HPay với các bên khác;
                                        </span>
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        lineHeight: "107%",
                                        marginBottom: "8pt",
                                        marginRight: "0cm",
                                        marginTop: "0cm",
                                        textAlign: "justify",
                                    }}
                                >
                                    <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                        <span style={{ lineHeight: "107%" }}>Cung cấp cho các bên cung cấp dịch vụ cho HPay.</span>
                                    </span>
                                </p>
                            </li>
                        </ul>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    2.4. Thực hiện các hoạt động khác có liên quan đến việc phát triển, cải tiến, cung cấp, vận&nbsp;hành, xử lý và
                                    quản lý của HPay đối với các sản phẩm, dịch vụ ngân hàng.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    2.5. Vì Các Mục Đích mà HPay sẽ/có thể thu thập, sử dụng, tiết lộ hoặc xử lý dữ liệu cá nhân&nbsp;của bạn phụ
                                    thuộc vào hoàn cảnh hiện có, mục đích đó có thể không xuất hiện bên trên.&nbsp;Tuy nhiên, HPay sẽ thông báo cho
                                    bạn biết mục đích khác đó tại thời điểm xin sự cho&nbsp;phép của bạn, trừ phi việc xử lý dữ liệu áp dụng mà không
                                    có sự đồng ý của bạn là được&nbsp;phép theo các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân hoặc
                                    theo quy&nbsp;định pháp luật.2.6. Phục vụ việc thực hiện quyền và nghĩa vụ của HPay theo quy định pháp luật, quy
                                    định&nbsp;của HPay, và các mục đích khác mà HPay cho là cần thiết tại từng thời điểm.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>3. THỜI ĐIỂM THU THẬP THÔNG TIN:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>HPay sẽ/có thể thu thập dữ liệu cá nhân về bạn khi:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    a. khi bạn đăng ký và/hoặc sử dụng Các Dịch Vụ hoặc Nền tảng của HPay, hoặc mở một tài&nbsp;khoản với HPay;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b. khi bạn gửi bất kỳ biểu mẫu nào, bao gồm đơn đăng ký hoặc các mẫu đơn khác liên quan&nbsp;đến bất kỳ sản phẩm
                                    và dịch vụ nào của HPay, bằng hình thức trực tuyến hay dưới hình&nbsp;thức khác;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    c. khi bạn ký kết bất kỳ thỏa thuận nào hoặc cung cấp các tài liệu hoặc thông tin khác liên&nbsp;quan đến tương
                                    tác giữa bạn với HPay, hoặc khi bạn sử dụng các sản phẩm và dịch vụ của&nbsp;HPay;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    d. khi bạn tương tác với HPay, chẳng hạn như thông qua các cuộc gọi điện thoại (có thể&nbsp;được ghi âm lại), thư
                                    từ, fax, gặp gỡ trực tiếp, các nền ứng dụng truyền thông xã hội và&nbsp;email;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    e. khi bạn sử dụng các dịch vụ điện tử của HPay, hoặc tương tác với HPay qua Nền tảng&nbsp;hoặc Trang Web hoặc Các
                                    Dịch Vụ của HPay. Trường hợp này bao gồm thông qua tập&nbsp;tin cookie mà HPay có thể triển khai khi bạn tương tác
                                    với các Nền tảng hoặc Trang Web&nbsp;của HPay;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>f. khi bạn thực hiện các giao dịch thông qua Dịch vụ của HPay;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    g. khi bạn sử dụng dịch vụ liên kết của bên thứ ba được tích hợp trên nền tảng của HPay;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>h. khi bạn cung cấp ý kiến phản hồi hoặc gửi khiếu nại cho HPay;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>i. khi bạn đăng ký tham gia một cuộc thi;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>j. khi bạn gửi dữ liệu cá nhân của bạn cho HPay vì bất kỳ lý do gì.</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>4. PHẠM VI THU THẬP</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    4.1. Dữ liệu cá nhân mà HPay có thể thu thập được nêu tại Khoản 1.3 của Chính sách bảo mật&nbsp;này.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    4.2. Bạn đồng ý không cung cấp cho HPay bất cứ thông tin nào không chính xác hoặc gây hiểu&nbsp;nhầm và bạn đồng ý
                                    sẽ thông báo cho HPay về bất cứ thông tin nào không chính xác hoặc&nbsp;khi có sự thay đổi thông tin. HPay bảo lưu
                                    quyền theo quyết định riêng của HPay được&nbsp;yêu cầu các tài liệu cần thiết khác để xác minh thông tin được bạn
                                    cung cấp.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    4.3. Nếu bạn không muốn HPay thu thập thông tin/dữ liệu cá nhân nói trên, bạn có thể chọn&nbsp;không tham gia vào
                                    bất kỳ lúc nào bằng cách thông báo bằng văn bản đến HPay. Tuy&nbsp;nhiên, lưu ý rằng việc từ chối hoặc hủy bỏ cho
                                    phép HPay thu thập, sử dụng hoặc xử lý dữ&nbsp;liệu cá nhân của bạn có thể ảnh hưởng đến việc bạn sử dụng Các Dịch
                                    Vụ và Nền tảng.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>5. THU THẬP CÁC DỮ LIỆU KHÁC</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    5.1. Như với hầu hết các trang web và các ứng dụng di động khác, thiết bị của bạn gửi thông&nbsp;tin có
                                    thể&nbsp;gồm có dữ liệu về bạn, được một máy chủ ghi lại khi bạn sử dụng Nền tảng của&nbsp;HPay. Thông tin này
                                    bao&nbsp;gồm&nbsp;địa chỉ IP, hệ điều hành của máy tính/thiết bị di động, loại&nbsp;trình duyệt, loại thiết bị di
                                    động, các đặc điểm&nbsp;của thiết bị di động, mã định danh thiết bị&nbsp;thống nhất (UDID) hoặc mã định danh thiết
                                    bị di động (MEID) của thiết bị di động củabạn, địa chỉ tham chiếu của Trang Web (nếu có), các trang mà bạn đã truy
                                    cập đến trên&nbsp;trang web hoặc ứng dụng di động của HPay và thời gian truy cập và đôi khi là "cookie"&nbsp;(có
                                    thể vô hiệu&nbsp;hóa bằng cách sử dụng tùy chọn trình duyệt của bạn) để giúp trang web ghi&nbsp;nhớ lần truy cập
                                    cuối cùng của bạn. Nếu bạn đăng nhập, thông tin này được liên kết với&nbsp;tài khoản cá nhân của bạn. Thông tin
                                    này cũng được đưa vào các số liệu thống kê ẩn danh&nbsp;để giúp HPay hiểu được khách truy cập sử dụng Nền tảng của
                                    HPay như thế nào.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    5.2. Dịch vụ của HPay có thể thu thập thông tin chính xác về địa chỉ của thiết bị di động của&nbsp;bạn sử
                                    dụng&nbsp;các công nghệ như GPS, Wi-Fi,…. HPay thu thập, sử dụng, công bố và/hoặc&nbsp;xử lý các thông tin này
                                    cho&nbsp;một hoặc nhiều mục đích bao gồm các dịch vụ được cung cấp&nbsp;dựa trên vị trí mà bạn yêu cầu hoặc
                                    chuyển&nbsp;các nội dung có liên quan đến bạn dựa trên vị&nbsp;trí của bạn hoặc cho phép bạn chia sẻ vị trí của
                                    bạn cho các&nbsp;Người sử dụng khác như là&nbsp;một phần của các Dịch vụ được cung cấp bởi các ứng dụng di động
                                    của HPay. Đối với&nbsp;phần lớn các thiết bị di động, bạn có thể rút lại sự cho phép để HPay được thu thập
                                    các&nbsp;thông tin&nbsp;này dựa trên vị trí của bạn thông qua các cài đặt trên thiết bị. Nếu bạn có câu
                                    hỏi&nbsp;nào về cách thức vô hiệu hóa các dịch vụ vị trí trên thiết bị di động của bạn, vui lòng liên&nbsp;hệ với
                                    các nhà cung cấp dịch vụ thiết bị di động hoặc nhà sản xuất thiết bị của bạn.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>6. XEM VÀ TẢI CÁC NỘI DUNG VÀ QUẢNG CÁO</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Do khi bạn xem các trang trên trang web hoặc ứng dụng di động của HPay, khi bạn xem&nbsp;các nội dung
                                    và&nbsp;quảng cáo và truy cập vào các phần mềm khác trên Nền tảng của Hpay&nbsp;hoặc thông qua Dịch vụ, phần lớn
                                    các thông tin giống nhau sẽ được gửi đến cho HPay (bao&nbsp;gồm địa chỉ IP, hệ điều hành, v.v…); nhưng, thay vì
                                    các lượt xem trang, thiết bị của bạn&nbsp;gửi đến HPay thông tin về các nội dung, quảng cáo được xem và/hoặc phần
                                    mềm được cài&nbsp;đặt trên các Dịch vụ và Nền tảng và thời gian.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>7. XỬ LÝ DỮ LIỆU CÁ NHÂN TRONG TRƯỜNG HỢP KHÔNG CẦN SỰ ĐỒNG Ý</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>CỦA CHỦ THỂ DỮ LIỆU</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    7.1. Trong trường hợp khẩn cấp, cần xử lý ngay dữ liệu cá nhân có liên quan để bảo vệ tính&nbsp;mạng, sức khỏe của
                                    Người dùng hoặc người khác. HPay, Bên xử lý dữ liệu khác hoặc Bên&nbsp;thứ ba có trách nhiệm chứng minh trường hợp
                                    này.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>7.2. Việc công khai dữ liệu cá nhân theo quy định của luật.</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    7.3. Việc xử lý dữ liệu của cơ quan nhà nước có thẩm quyền trong trường hợp tình trạng khẩn&nbsp;cấp về quốc
                                    phòng, an ninh quốc gia, trật tự an toàn xã hội, thảm họa lớn, dịch bệnh nguy&nbsp;hiểm; khi có nguy cơ đe
                                    dọa&nbsp;an ninh, quốc phòng nhưng chưa đến mức ban bố tình trạng&nbsp;khẩn cấp; phòng, chống bạo loạn, khủng bố,
                                    phòng, chống tội phạm và vi phạm pháp luật&nbsp;theo quy định của luật.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    7.4. Để thực hiện nghĩa vụ theo hợp đồng của chủ thể dữ liệu với cơ quan, tổ chức, cá nhân có&nbsp;liên quan theo
                                    quy định của luật.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    7.5. Phục vụ hoạt động của cơ quan nhà nước đã được quy định theo luật chuyên ngành.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>8. PHƯƠNG THỨC BẢO VỆ VÀ LƯU TRỮ THÔNG TIN NGƯỜI DÙNG</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    8.1. HPay thực hiện các biện pháp bảo mật khác nhau và luôn nỗ lực để đảm bảo sự an toàn&nbsp;của dữ liệu cá nhân
                                    của bạn trên các hệ thống của HPay. Dữ liệu cá nhân của người dùngđược lưu trữ đằng sau các mạng bảo mật và chỉ có
                                    thể được truy cập bởi một số nhân viên&nbsp;có quyền truy cập đặc biệt đến các hệ thống của HPay.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    8.2. HPay sẽ duy trì dữ liệu cá nhân tuân theo các quy định của pháp luật về bảo vệ bí mật&nbsp;thông tin cá nhân
                                    và/hoặc các điều luật hiện hành khác. Có nghĩa là, HPay sẽ hủy hoặc xóa&nbsp;thông tin nhận dạng ra khỏi dữ liệu
                                    cá nhân của bạn khi HPay có lý do hợp lý để xác định&nbsp;rằng:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    i. việc lưu giữ dữ liệu cá nhân đó không còn phục vụ mục đích thu thập dữ liệu cá nhân đó&nbsp;nữa;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    ii. việc lưu giữ không còn cần thiết cho bất kỳ mục đích hợp pháp hay mục đích kinh doanh&nbsp;nào và;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    iii. không còn các lợi ích hợp pháp nào khác để tiếp tục lưu giữ các dữ liệu cá nhân này. Nếu&nbsp;bạn ngừng sử
                                    dụng Dịch vụ, hoặc quyền của bạn được sử dụng Nền tảng và/hoặc Các Dịch&nbsp;Vụ bị chấm dứt hoặc hủy bỏ,&nbsp;HPay
                                    có thể tiếp tục lưu, sử dụng và/hoặc tiết lộ dữ liệu cá&nbsp;nhân của bạn tuân theo Chính sách bảo mật này và các
                                    nghĩa vụ của HPay theo các quy&nbsp;định của pháp luật về bảo vệ bí mật thông tin cá nhân. Tùy thuộc vào quy định
                                    của pháp&nbsp;luật, HPay có thể tiêu hủy dữ liệu cá nhân của bạn một cách an toàn mà không cần thông&nbsp;báo
                                    trước cho bạn.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    iv. Khi có yêu cầu xóa dữ liệu hợp pháp từ Người dùng, trừ trường hợp pháp luật có quy định&nbsp;khác.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>9. NHỮNG NGƯỜI HOẶC TỔ CHỨC CÓ THỂ TIẾP CẬN VÀ XỬ LÝ THÔNG TIN:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>9.1. Cán bộ, nhân viên, cộng tác viên trong hệ thống của HPay;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    9.2. Trong quá trình thực hiện hoạt động kinh doanh, HPay sẽ/có thể cần phải tiết lộ dữ liệu cá&nbsp;nhân của bạn
                                    cho các nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc các công ty liên kết&nbsp;hoặc công ty liên quan của HPay,
                                    và/hoặc các bên thứ ba khác, vì một hay nhiều Mục Đích&nbsp;nói trên, và việc tiết lộ này sẽ được thực hiện theo
                                    đúng trình tự và quy định của pháp luật&nbsp;hiện hành. Các nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc các
                                    công ty liên kết hoặc&nbsp;công ty liên quan và/hoặc các bên thứ ba khác như thế sẽ xử lý dữ liệu cá nhân của
                                    bạn&nbsp;hoặc thay mặt HPay hoặc khác, vì một hoặc nhiều Mục Đích nói trên. Các bên thứ ba như&nbsp;thế bao gồm:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>a. Tổ chức ngân hàng;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>b. Công ty liên kết và công ty liên quan của HPay;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    c. Nhà thầu, đại lý, nhà cung cấp dịch vụ và các bên thứ ba khác mà HPay thuê để hỗ trợ hoặc&nbsp;hợp tác, bổ sung
                                    cho hoạt động kinh doanh của HPay. Những bên này bao gồm, nhưng&nbsp;không giới hạn ở những bên cung cấp các dịch
                                    vụ quản trị hoặc các dịch vụ khác cho Hpay&nbsp;chẳng hạn như công ty bưu chính, vận chuyển, công ty viễn thông,
                                    đối tác quảng cáo và&nbsp;truyền thông, công ty công nghệ thông tin, an toàn thông tin, trung tâm dữ liệu, các tổ
                                    chức&nbsp;hoạt động thương mại điện tử, các tổ chức hoạt động trung gian thanh toán khác mà Hpay&nbsp;có quan hệ
                                    hợp tác, trung tâm dữ liệu;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    d. Người mua hoặc người thừa nhiệm khác trong trường hợp sáp nhập, thoái vốn, tái cơ cấu,&nbsp;tái tổ chức, giải
                                    thể hoặc bán hay chuyển nhượng một phần hoặc tất cả tài sản của HPay,&nbsp;cho dù là vấn đề đang diễn ra hay đang
                                    trong thủ tục phá sản, thanh lý hoặc thủ tục tương&nbsp;tự, trong đó dữ liệu cá nhân HPay lưu giữ về người dùng
                                    của HPay nằm trong các tài sảnđược chuyển nhượng; hoặc cho một bên đối tác trong một giao dịch tài sản kinh doanh
                                    mà&nbsp;HPay hoặc bất kỳ công ty liên kết hay công ty liên quan nào của nó có tham gia giao dịch;&nbsp;và
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>e. Công ty kiểm toán, đơn vị cung cấp dịch vụ pháp lý;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    f. Bên thứ ba mà HPay tiết lộ thông tin vì một trong các Mục Đích và các bên thứ ba đó&nbsp;ngược lại họ sẽ thu
                                    thập và xử lý dữ liệu cá nhân của bạn vì một hoặc nhiều Mục Đích.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    9.3. HPay có thể chia sẻ thông tin thống kê và nhân khẩu học về Người Dùng cũng như việc sử&nbsp;dụng Các Dịch Vụ
                                    của người dùng với đối tác cung cấp dịch vụ quảng cáo và lập trình.&nbsp;HPay cũng sẽ chia sẻ thông tin thống kê
                                    và thông tin nhân khẩu học về người dùng của&nbsp;HPay và việc họ sử dụng Các Dịch Vụ với các đối tác quảng cáo và
                                    bên thứ ba cung cấp&nbsp;dịch vụ quảng cáo, tái quảng cáo, và/hoặc lập trình. Trường hợp này sẽ không bao gồm
                                    bất&nbsp;kỳ thông tin nào có thể được sử dụng để xác định danh tính cụ thể của bạn hoặc tiết lộ&nbsp;thông tin cá
                                    nhân về bạn.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    9.4. Để tránh nghi ngờ, trong trường hợp các quy định của pháp luật về bảo vệ bí mật thông tin&nbsp;cá nhân hoặc
                                    các điều luật hiện hành khác cho phép một tổ chức chẳng hạn như HPay thu&nbsp;thập, sử dụng hoặc tiết lộ dữ liệu
                                    cá nhân của bạn mà không cần sự đồng ý của bạn, sự cho&nbsp;phép đó của pháp luật sẽ tiếp tục áp dụng.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    9.5. Các bên thứ ba có thể chặn hoặc truy cập trái phép dữ liệu cá nhân được gửi đến hoặc có&nbsp;trên trang web,
                                    các công nghệ có thể hoạt động không chính xác hoặc không hoạt động&nbsp;như dự kiến, hoặc có người có thể truy
                                    cập, lạm dụng hoặc sử dụng sai trái thông tin mà&nbsp;không phải lỗi của HPay. Tuy nhiên HPay sẽ triển khai các
                                    biện pháp bảo mật hợp lý để&nbsp;bảo vệ dữ liệu cá nhân của bạn theo quy định của các quy định của pháp luật về
                                    bảo vệ bí&nbsp;mật thông tin cá nhân; tuy nhiên không thể đảm bảo sự bảo mật tuyệt đối chẳng hạn như&nbsp;trường
                                    hợp tiết lộ trái phép phát sinh từ hoạt động tin tặc vì ý đồ xấu hoặc phức tạp của&nbsp;các nội dung có độc không
                                    phải lỗi của HPay.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>10. THÔNG TIN VỀ TRẺ EM</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    10.1. Các Dịch Vụ này không dành cho trẻ em. HPay không cố tình thu thập hay lưu giữ bất&nbsp;kỳ dữ liệu cá nhân
                                    hay thông tin không nhận dạng cá nhân nào của bất kỳ ai dưới 18 tuổi,&nbsp;bất kỳ phần nào của Nền tảng của HPay
                                    hoặc Các Dịch Vụ khác cũng không dành cho&nbsp;trẻ em. Bố/mẹ hoặc người giám hộ của trẻ em vui lòng giám sát và
                                    đảm bảo thông tin cá&nbsp;nhân của trẻ mà mình đang giám hộ không cung cấp thông tin cá nhân cho HPay.
                                    Trong&nbsp;trường hợp thông tin cá nhân của của trẻ em do quý phụ huynh giám hộ cung cấp cho&nbsp;HPay, Bố/mẹ hoặc
                                    người giám hộ đồng ý với việc xử lý thông tin của trẻ em có liên&nbsp;quan, và đồng ý chịu sự điều chỉnh của Chính
                                    Sách này thay mặt cho người được giám&nbsp;hộ.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    10.2. HPay sẽ ngừng xử lý dữ liệu cá nhân của trẻ em, xóa không thể khôi phục hoặc hủy dữ&nbsp;liệu cá nhân của
                                    trẻ em trong trường hợp:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    a. Xử lý dữ liệu không đúng mục đích hoặc đã hoàn thành mục đích xử lý dữ liệu cá nhân&nbsp;được chủ thể dữ liệu
                                    đồng ý, trừ trường hợp pháp luật có quy định khác;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b. Cha, mẹ hoặc người giám hộ của trẻ em rút lại sự đồng ý cho phép xử lý dữ liệu cá nhân&nbsp;của trẻ em, trừ
                                    trường hợp pháp luật có quy định khác;c. Theo yêu cầu của cơ quan chức năng có thẩm quyền khi có đủ căn cứ chứng
                                    minh việc&nbsp;xử lý dữ liệu cá nhân gây ảnh hưởng tới quyền và lợi ích hợp pháp của trẻ em, trừ trường&nbsp;hợp
                                    pháp luật có quy định khác.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>11. THÔNG TIN THU THẬP BỞI CÁC BÊN THỨ BA</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    HPay, và các bên thứ ba, có thể thỉnh thoảng cung cấp các bản tải về ứng dụng phần mềm&nbsp;cho bạn sử dụng bởi
                                    Nền tảng hoặc thông qua Các Dịch Vụ. Những ứng dụng này có thể&nbsp;truy cập riêng, và cho phép một bên thứ ba
                                    xem, thông tin nhận dạng của bạn, chẳng hạn&nbsp;như tên, ID người dùng của bạn, Địa chỉ IP của thiết bị của bạn
                                    hoặc thông tin khác, và&nbsp;bất kỳ cookie nào trước đây bạn có thể đã cài đặt hoặc đã được cài đặt cho bạn bởi
                                    một&nbsp;ứng dụng phần mềm hoặc trang web của bên thứ ba. Ngoài ra, các ứng dụng này có thể&nbsp;yêu cầu bạn cung
                                    cấp thêm thông tin trực tiếp cho các bên thứ ba. Các sản phẩm hoặc&nbsp;dịch vụ của bên thứ ba được cung cấp thông
                                    qua các ứng dụng này không thuộc sở hữu&nbsp;hay quyền kiểm soát của HPay. Bạn nên đọc các điều khoản và các chính
                                    sách khác được&nbsp;công bố bởi các bên thứ ba đó trên trang web của họ hoặc nơi khác.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>12. LOẠI TRỪ TRÁCH NHIỆM VỀ NGHĨA VỤ BẢO MẬT VÀ CÁC TRANG WEB</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>BÊN THỨ BA</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    12.1. HPAY KHÔNG ĐẢM BẢO TÍNH BẢO MẬT ĐỐI VỚI DỮ LIỆU CÁ NHÂN&nbsp;VÀ/HOẶC THÔNG TIN KHÁC MÀ BẠN CUNG CẤP TRÊN CÁC
                                    TRANG WEB&nbsp;CỦA BÊN THỨ BA. HPay thực hiện các biện pháp bảo mật khác nhau để duy trì sự an&nbsp;toàn của dữ
                                    liệu cá nhân của bạn mà HPay lưu giữ hoặc kiểm soát. Dữ liệu cá nhân của&nbsp;bạn được lưu đằng sau các mạng bảo
                                    mật và chỉ có thể được truy cập bởi một số cá nhân&nbsp;giới hạn có quyền truy cập đặc biệt đến các hệ thống của
                                    HPay, và đã được yêu cầu bảo&nbsp;mật dữ liệu cá nhân đó. Khi bạn đặt hàng hoặc truy cập dữ liệu cá nhân của bạn,
                                    Hpay&nbsp;đề nghị sử dụng một máy chủ bảo mật. Tất cả dữ liệu cá nhân hoặc thông tin cá nhân bạn&nbsp;cung cấp sẽ
                                    được mã hóa vào các cơ sở dữ liệu của HPay để chỉ được truy cập như mô&nbsp;tả bên trên.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    12.1. Nhằm cung cấp cho bạn giá trị gia tăng, HPay có thể chọn các trang web hoặc ứng dụng&nbsp;hoặc dịch vụ của
                                    bên thứ ba khác nhau để liên kết, và đóng khung bên trong Nền tảng.&nbsp;HPay cũng có thể tham gia các quan hệ
                                    cùng tiếp thị và các quan hệ khác để cung cấp&nbsp;dịch vụ thương mại điện tử và các dịch vụ và tính năng khác cho
                                    khách truy cập. Những&nbsp;trang được liên kết này có các chính sách về quyền riêng tư cũng như các biện pháp
                                    bảo&nbsp;mật riêng và độc lập. Ngay cả khi bên thứ ba đó có liên kết với HPay, HPay cũng không&nbsp;kiểm soát các
                                    trang web/ứng dụng/dịch vụ được liên kết này, mỗi trang đó có các phương&nbsp;pháp bảo vệ quyền riêng tư và thu
                                    thập dữ liệu riêng biệt, độc lập với HPay. Dữ liệu thu&nbsp;thập bởi các đối tác cùng tiếp thị của HPay hoặc các
                                    trang web/ứng dụng/dịch vụ của bên&nbsp;thứ ba (ngay cả khi được cung cấp trên hoặc thông qua Nền tảng của HPay)
                                    có thể không&nbsp;được HPay tiếp cận và/hoặc lưu giữ.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    12.1. Do đó HPay không chịu trách nhiệm hay trách nhiệm pháp lý đối với nội dung, các biện&nbsp;pháp bảo mật (hoặc
                                    sự thiếu biện pháp bảo mật) và các hoạt động của các trang web/ứng&nbsp;dụng/dịch vụ được liên kết này. Những
                                    trang web/ứng dụng/dịch vụ được liên kết này&nbsp;chỉ vì sự thuận tiện cho bạn và do đó bạn tự chịu trách nhiệm
                                    khi truy cập chúng. Tuynhiên, HPay tìm cách bảo vệ tính toàn vẹn của Dịch vụ của HPay và các liên kết
                                    được&nbsp;đặt trên từng trang web đó và do đó HPay hoan nghênh ý kiến phản hồi về các trang web&nbsp;được liên kết
                                    này (bao gồm nếu một trang web cụ thể không hoạt động).
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    13. PHƯƠNG THỨC VÀ CÔNG CỤ ĐỂ NGƯỜI DÙNG TIẾP CẬN VÀ CHỈNH SỬA&nbsp;DỮ LIỆU CÁ NHÂN CỦA MÌNH
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>13.1. Rút Lại Sự Đồng ý cung cấp thông tin:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    a. Bạn có thể rút lại sự đồng ý cho phép thu thập, sử dụng và/hoặc tiết lộ dữ liệu cá nhân&nbsp;của bạn mà HPay
                                    đang lưu giữ hoặc kiểm soát bằng cách gửi email cho HPay tại địa chỉ&nbsp;email: cskh@hpay.com.vn, và HPay sẽ xử
                                    lý các yêu cầu này theo Chính Sách Bảo Mật&nbsp;cũng như quy định pháp luật có liên quan.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b. Tuy nhiên, việc bạn rút lại sự đồng ý có thể có nghĩa là HPay sẽ không thể tiếp tục cung&nbsp;cấp Các Dịch Vụ
                                    cho bạn, và HPay có thể cần phải chấm dứt quan hệ hiện tại của bạn&nbsp;và/hoặc hợp đồng của bạn với HPay.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>13.2. Yêu Cầu Truy Cập đến hoặc Sửa Dữ Liệu Cá Nhân</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    a. Nếu bạn truy cập Dịch vụ của HPay thông qua Tài khoản mạng xã hội. Cá nhân bạn có&nbsp;thể truy cập và/hoặc sửa
                                    dữ liệu cá nhân của bạn thông qua tài khoản mạng xã hội của&nbsp;bạn thông qua trang Thiết Lập Tài Khoản hoặc nhãn
                                    trên tài khoản (nếu có); Khi đó,&nbsp;thông tin của bạn sẽ được tự động cập nhật tại HPay tương ứng với thông tin
                                    mà bạn đã&nbsp;thay đổi tại Mạng xã hội.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b. Nếu bạn chưa đăng ký tài khoản với HPay, cá nhân bạn có thể yêu cầu truy cập và/hoặc&nbsp;sửa dữ liệu cá nhân
                                    của bạn mà HPay đang lưu giữ hoặc kiểm soát bằng cách gửi yêu&nbsp;cầu bằng văn bản cho HPay. HPay sẽ cần có đủ
                                    thông tin từ bạn để xác định danh tính&nbsp;của bạn cũng như bản chất yêu cầu của bạn để có thể giải quyết yêu cầu
                                    của bạn trong&nbsp;phạm vi có thể giải quyết. Do đó, vui lòng gửi yêu cầu bằng văn bản của bạn bằng cách&nbsp;gửi
                                    email cho nhân viên Quản lý dữ liệu của HPay theo địa chỉ email đã cung cấp nêu&nbsp;trên.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    c. HPay có thể tính một khoản phí hợp lý cho bạn để giải quyết và xử lý yêu cầu truy cập&nbsp;dữ liệu cá nhân của
                                    bạn. Nếu HPay có tính phí, HPay sẽ cung cấp cho bạn ước tính lệ phí&nbsp;bằng văn bản. Vui lòng lưu ý rằng HPay
                                    không buộc phải đáp ứng hay giải quyết yêu&nbsp;cầu truy cập của bạn trừ phi bạn đã đồng ý đóng phí.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    d. HPay bảo lưu quyền từ chối sửa dữ liệu cá nhân của bạn theo các quy định của pháp luật&nbsp;về bảo vệ bí mật
                                    thông tin cá nhân, trường hợp các điều luật đó yêu cầu và/hoặc cho phép&nbsp;một tổ chức từ chối sửa dữ liệu cá
                                    nhân trong các trường hợp như thế.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>13.3. Hạn chế xử lý:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    13.3.1. Người dùng có quyền yêu cầu HPay hạn chế xử lý dữ liệu trong các trường hợp sau:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    a. Trong thời gian HPay xác nhận tính chính xác hoặc trong thời gian cơ quan nhà nước có&nbsp;thẩm quyền giải
                                    quyết tranh chấp về dữ liệu cá nhân;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b. Việc xử lý dữ liệu là bất hợp pháp và người dùng phản đối việc xóa dữ liệu cá nhân và&nbsp;thay vào đó yêu cầu
                                    hạn chế sử dụng chúng;c. HPay không còn nhu cầu lưu trữ dữ liệu cá nhân cho mục đích xử lý, những dữ liệu
                                    được&nbsp;Người dùng yêu cầu để thiết lập, thực hiện hoặc bảo vệ các khiếu nại pháp lý. Trong&nbsp;trường hợp này,
                                    dữ liệu sẽ chuyển sang trạng thái hạn chế xử lý thay vì xóa theo quy&nbsp;định;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    d. Người dùng đã phản đối việc xử lý dữ liệu và trong khi chờ xác minh việc phản đối.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    13.3.2. Khi việc xử lý bị hạn chế theo quy định tại khoản 13.3.1, dữ liệu cá nhân đó, ngoại trừ&nbsp;việc lưu trữ,
                                    sẽ chỉ được xử lý khi có sự đồng ý của Người dùng hoặc để thiết lập, thực&nbsp;hiện hoặc bảo vệ các khiếu nại pháp
                                    lý hoặc để bảo vệ quyền của người khác hoặc pháp&nbsp;nhân hoặc theo quy định của pháp luật.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    13.3.3. Khi nguyên nhân phát sinh yêu cầu hạn chế xử lý dữ liệu (theo khoản 13.3.1) không còn,&nbsp;người dùng sẽ
                                    được HPay thông báo trước về việc biện pháp hạn chế xử lý được dỡ bỏ.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    13.4. Người dùng có các quyền khác đối với dữ liệu cá nhân của mình theo quy định của pháp&nbsp;luật.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>14. QUYỀN PHẢN ĐỐI XỬ LÝ DỮ LIỆU</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    14.1. Người dùng có quyền phản đối xử lý dữ liệu của mình nhằm ngăn chặn hoặc hạn chế tiết&nbsp;lộ dữ liệu cá nhân
                                    hoặc sử dụng cho mục đích quảng cáo, tiếp thị, trừ trường hợp luật có&nbsp;quy định khác.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    14.2. HPay thực hiện yêu cầu của người dùng trong vòng 72 giờ kể từ thời điểm nhận được&nbsp;yêu cầu. HPay sẽ
                                    không còn xử lý dữ liệu của Người dùng trừ khi chứng minh các cơ sở&nbsp;hợp pháp thuyết phục cho việc xử lý,
                                    những cơ sở này sẽ ảnh hưởng đến lợi ích, quyền&nbsp;và tự do của chủ thể dữ liệu hoặc để thiết lập, thực hiện
                                    hoặc bảo vệ các khiếu nại pháp&nbsp;lý hoặc Pháp luật có quy định khác.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>15. XÓA, HỦY DỮ LIỆU</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    15.1. Người dùng được yêu cầu HPay xóa dữ liệu cá nhân của mình trong các trường hợp sau:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    a) Nhận thấy không còn cần thiết cho mục đích thu thập đã đồng ý và chấp nhận các thiệt&nbsp;hại có thể xảy ra khi
                                    yêu cầu xóa dữ liệu;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>b) Rút lại sự đồng ý;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>c) Phản đối việc xử lý dữ liệu;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    d) HPay không có lý do chính đáng để tiếp tục thu thập, xử lý dữ liệu theo quy định tại&nbsp;Khoản 8.2 của Chính
                                    sách này;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    e) Dữ liệu cá nhân được xử lý không đúng với mục đích đã đồng ý hoặc việc xử lý dữ liệu&nbsp;cá nhân là vi phạm
                                    quy định của pháp luật;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>f) Dữ liệu cá nhân phải xóa theo quy định của pháp luật.</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    15.2. Việc xóa dữ liệu sẽ không áp dụng khi có đề nghị của chủ thể dữ liệu trong các trường&nbsp;hợp:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>a) Pháp luật quy định không cho phép xóa dữ liệu;</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b) Dữ liệu cá nhân được xử lý bởi cơ quan nhà nước có thẩm quyền với mục đích phục vụ&nbsp;hoạt động của cơ quan
                                    nhà nước theo quy định của pháp luật;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    c) Dữ liệu cá nhân đã được công khai theo quy định của pháp luật;d) Dữ liệu cá nhân được xử lý nhằm phục vụ yêu
                                    cầu pháp lý, nghiên cứu khoa học, thống&nbsp;kê theo quy định của pháp luật;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    e) Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc gia, trật tự an toàn&nbsp;xã hội, thảm họa
                                    lớn, dịch bệnh nguy hiểm; khi có nguy cơ đe dọa an ninh, quốc phòng&nbsp;nhưng chưa đến mức ban bố tình trạng khẩn
                                    cấp; phòng, chống bạo loạn, khủng bố,&nbsp;phòng, chống tội phạm và vi phạm pháp luật;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    f) Ứng phó với tình huống khẩn cấp đe dọa đến tính mạng, sức khỏe hoặc sự an toàn của&nbsp;chủ thể dữ liệu hoặc cá
                                    nhân khác.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    15.3. Việc xóa dữ liệu được thực hiện trong 72 giờ sau khi có yêu cầu của người dùng, trừ&nbsp;trường hợp pháp
                                    luật có quy định khác.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    15.4. HPay sẽ thực hiện xóa dữ liệu và không thể khôi phục trong trường hợp:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    a) Xử lý dữ liệu không đúng mục đích hoặc đã hoàn thành mục đích xử lý dữ liệu cá nhân&nbsp;được chủ thể dữ liệu
                                    đồng ý;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    b) Việc lưu trữ dữ liệu cá nhân không còn cần thiết với hoạt động của HPay, Bên thứ ba;
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    c) HPay, Bên thứ ba bị giải thể hoặc không còn hoạt động hoặc tuyên bố phá sản hoặc bị&nbsp;chấm dứt hoạt động
                                    kinh doanh theo quy định của pháp luật.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    16. LIÊN HỆ VỚI HPAY VỀ CHÍNH SÁCH BẢO MẬT VÀ CÁC THÔNG TIN CÓ&nbsp;LIÊN QUAN&nbsp;Tiếp nhận và giải quyết khiếu
                                    nại&nbsp;Khi phát hiện hoặc nghi ngờ thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi&nbsp;HPay đã công bố.
                                    Người dùng có thể phản ánh, khiếu nại theo các bước sau:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 1: Người dùng (khách hàng/người dùng) khiếu nại/phản ánh qua email:&nbsp;cskh@hpay.com.vn
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 2: Bộ phận Chăm Sóc Khách Hàng của HPay sẽ tiếp nhận các khiếu nại của khách&nbsp;hàng/người dùng và phản hồi
                                    trong vòng 24 giờ kể từ thời điểm nhận được khiếu nại,&nbsp;phản ánh. Tùy theo tính chất và mức độ của khiếu nại
                                    thì HPay sẽ có những biện pháp&nbsp;cụ thể để giải quyết khiếu nại, tranh chấp đó.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 3: Trong trường nằm ngoài khả năng và thẩm quyền của HPay thì ban quản trị sẽ&nbsp;yêu cầu khách hàng/người
                                    dùng đưa vụ việc này ra cơ quan nhà nước có thẩm quyền giải&nbsp;quyết theo pháp luật.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Agreement;
