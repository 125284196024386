import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import routerWeb from "./routers/routerWeb/index.js";
import DefaultStyle from "./components/Styles/DefaultStyle/DefaultStyle.jsx";
import MainLayoutAdmin from "./components/Layout/MainLayoutAdmin.jsx";
import Login from "./pages/AdminPages/Login.jsx";
import Category from "./pages/AdminPages/Category.jsx";
import ListContact from "./pages/AdminPages/ListContact.jsx";
import Articles from "./pages/AdminPages/Articles.jsx";
import Dashboard from "./pages/AdminPages/Dashboard.jsx";
import dataLocalStorage from "./config/DataLocalStorage.js";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/NotFound/NotFound.jsx";


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    {routerWeb.map((route, index) => {
                        const PageComponent = route.element;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <DefaultStyle>
                                        <PageComponent />
                                    </DefaultStyle>
                                }
                            />
                        );
                    })}

                    <Route path="/admin/login" element={<Login />} />

                    {dataLocalStorage.token ? (
                        <>
                            <Route path="/admin" element={<MainLayoutAdmin />}>
                                <Route index element={<Dashboard />} />
                                <Route path="category" element={<Category />} />
                                <Route path="category/:id" element={<Category />} />
                                <Route path="articles" element={<Articles />} />
                                <Route path="contact" element={<ListContact />} />
                            </Route>
                        </>
                    ) : (
                        <Route path="/admin/login" element={<Login />} />
                    )}
                    <Route path="/admin/*" element={<Navigate to="/admin/login" />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
            <ToastContainer />
            
        </div>
    );
}

export default App;
