import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const Term = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="section-top">
            {" "}
            <div className="container">
                <Breadcrumb pathFrom={"/"} textFrom={"Trang chủ"} to={"Chính sách bảo mật"} />
                <div className="mx-3 mx-md-5">
                    <h2 className="fs-3 fw-bold my-4 text-center text-uppercase"> CHÍNH SÁCH BẢO MẬT THÔNG TIN NGƯỜI DÙNG</h2>
                    <div className="mb-5">
                        <>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 1cm",
                                    textAlign: "justify",
                                    textIndent: "-18pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        1.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        GIỚI THIỆU
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27.0pt",
                                    textAlign: "justify",
                                    textIndent: "1.0cm",
                                    lineHeight: "normal",
                                }}
                            >
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    là Nền tảng trung gian thanh toán{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    được vận hành bởi Công ty cổ phần Tập đoàn công nghệ HTP và các công ty liên kết (
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    sau đây được{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    gọi riêng và gọi chung là "HPay"
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    hay "của HPay"). HPay nghiêm túc thực hiện trách nhiệm của mình liên quan đến bảo mật thông tin theo các quy định
                                    về bảo vệ bí mật thông tin cá nhân của pháp luật Việt Nam{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    v
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    à cam kết tôn trọng quyền riêng tư và sự quan tâm của tất cả người dùng đối với các sản phẩm, dịch vụ{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    do
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    và/hoặc
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    các đối tác của{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                    cung cấp
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    và được phân phối qua{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay ("Dịch Vụ")
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    , bao gồm:
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    marginTop: "0cm",
                                    marginRight: "0cm",
                                    marginBottom: "6.0pt",
                                    marginLeft: "54.0pt",
                                    textAlign: "justify",
                                    textIndent: "-27.0pt",
                                    lineHeight: "115%",
                                    msoPagination: "widow-orphan lines-together",
                                    pageBreakAfter: "avoid",
                                    msoList: "l22 level1 lfo23",
                                    textAutospace: "none",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    -
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dịch vụ cổng thanh toán điện tử (nội địa và quốc tế);
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    marginTop: "0cm",
                                    marginRight: "0cm",
                                    marginBottom: "6.0pt",
                                    marginLeft: "54.0pt",
                                    textAlign: "justify",
                                    textIndent: "-27.0pt",
                                    lineHeight: "115%",
                                    msoPagination: "widow-orphan lines-together",
                                    pageBreakAfter: "avoid",
                                    msoList: "l22 level1 lfo23",
                                    textAutospace: "none",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    -
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dịch vụ hỗ trợ thu hộ, chi hộ;
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    marginTop: "0cm",
                                    marginRight: "0cm",
                                    marginBottom: "6.0pt",
                                    marginLeft: "54.0pt",
                                    textAlign: "justify",
                                    textIndent: "-27.0pt",
                                    lineHeight: "115%",
                                    msoPagination: "widow-orphan lines-together",
                                    pageBreakAfter: "avoid",
                                    msoList: "l22 level1 lfo23",
                                    textAutospace: "none",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    -
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dịch vụ Ví điện tử HPay.
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    marginTop: "0cm",
                                    marginRight: "0cm",
                                    marginBottom: "6.0pt",
                                    marginLeft: "54.0pt",
                                    textAlign: "justify",
                                    textIndent: "-27.0pt",
                                    lineHeight: "115%",
                                    msoPagination: "widow-orphan lines-together",
                                    pageBreakAfter: "avoid",
                                    msoList: "l22 level1 lfo23",
                                    textAutospace: "none",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    -
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Các dịch vụ khác do Bên A cung cấp tại từng thời điểm phù hợp quy định của Pháp luật và sự chấp thuận hoặc cấp
                                    phép của Cơ quan nhà nước có thẩm quyền.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27.0pt",
                                    textAlign: "justify",
                                    textIndent: "9.0pt",
                                    lineHeight: "normal",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Trong phạm vi điều chỉnh của Chính sách này, HPay là bên{" "}
                                    <span
                                        style={{
                                            backgroundImage: "initial",
                                            backgroundPosition: "initial",
                                            backgroundSize: "initial",
                                            backgroundRepeat: "initial",
                                            backgroundAttachment: "initial",
                                            backgroundOrigin: "initial",
                                            backgroundClip: "initial",
                                        }}
                                    >
                                        Bên Kiểm soát và xử lý dữ liệu cá nhân có quyền quyết định mục đích và phương tiện xử lý dữ liệu cá nhân.
                                    </span>
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Tuy nhiên, Người dùng đồng ý rằng mức độ bảo mật còn phụ thuộc vào thiết bị người dùng đang sử dụng, kết nối
                                    internet hoặc kết nối nội bộ của người dùng và các yếu tố khác thuộc về trách nhiệm của bên thứ ba. Người dùng
                                    hiểu rõ và chấp nhận rủi ro về bảo mật (nếu có) khi sử dụng Dịch vụ do Nền tảng HPay cung cấp.
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    1.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Người dùng có nghĩa là người truy cập nền tảng để sử dụng các Dịch Vụ (gọi chung và gọi riêng là “Người Dùng”,
                                    “bạn” hoặc “của bạn” hoặc "Chủ thể dữ liệu").
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    1.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Mục đích thiết lập Chính sách bảo mật này ("Chính sách bảo mật" hay "Chính sách"): Giúp người dùng hiểu được cách
                                    thức HPay thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân mà bạn đã cung cấp cho HPay và/hoặc lưu giữ về
                                    bạn ở thời điểm hiện nay hoặc trong tương lai, cũng như để giúp bạn đưa ra quyết định sáng suốt trước khi cung cấp
                                    cho HPay bất kỳ dữ liệu cá nhân nào của bạn. Do đó, người dùng cần tham khảo đầy đủ chính sách của các bên liên
                                    quan trước khi quyết định truy cập và sử dụng Dịch vụ của HPay.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    1.3.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    "Dữ Liệu Cá Nhân" hay "dữ liệu cá nhân" có nghĩa là{" "}
                                    <span
                                        style={{
                                            backgroundImage: "initial",
                                            backgroundPosition: "initial",
                                            backgroundSize: "initial",
                                            backgroundRepeat: "initial",
                                            backgroundAttachment: "initial",
                                            backgroundOrigin: "initial",
                                            backgroundClip: "initial",
                                        }}
                                    >
                                        thông tin dưới dạng ký hiệu, chữ viết, chữ số, hình ảnh, âm thanh hoặc dạng tương tự trên môi trường điện tử
                                        gắn liền với một con người cụ thể hoặc giúp xác định một con người cụ thể. Dữ liệu cá nhân bao gồm dữ liệu cá
                                        nhân cơ bản và dữ liệu cá nhân nhạy cảm. Cụ thể:
                                    </span>
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12pt",
                                            lineHeight: "106%",
                                            fontFamily: '"Times New Roman", serif',
                                            color: "black",
                                            backgroundImage: "initial",
                                            backgroundPosition: "initial",
                                            backgroundSize: "initial",
                                            backgroundRepeat: "initial",
                                            backgroundAttachment: "initial",
                                            backgroundOrigin: "initial",
                                            backgroundClip: "initial",
                                        }}
                                    >
                                        Dữ liệu cá nhân cơ bản bao gồm:
                                    </span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    a) Họ, chữ đệm và tên khai sinh, tên gọi khác (nếu có);
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    b) Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc mất tích;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    c) Giới tính;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    d) Nơi sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm trú, nơi ở hiện tại, quê quán, địa chỉ liên hệ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    đ) Quốc tịch;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    e) Hình ảnh của cá nhân
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    {" "}
                                    (hình ảnh khuôn mặt khách hàng được chụp/quét trực tiếp thông qua thiết bị điện tử/di động được sử dụng để truy
                                    cập Dịch vụ của HPay)
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    ;
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    g) Số điện thoại, số chứng minh nhân dân, số định danh cá nhân, số hộ chiếu, số giấy phép lái xe, số biển số xe,
                                    số mã số thuế cá nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    h) Tình trạng hôn nhân;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    i) Thông tin về mối quan hệ gia đình (cha mẹ, con cái);
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    k) Thông tin về tài khoản số của cá nhân; dữ liệu cá nhân phản ánh hoạt động, lịch sử hoạt động trên không gian
                                    mạng;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    l) Các thông tin khác gắn liền với một con người cụ thể hoặc giúp xác định một con người cụ thể không thuộc quy
                                    định tại khoản 4 Điều này.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12pt",
                                            lineHeight: "106%",
                                            fontFamily: '"Times New Roman", serif',
                                            color: "black",
                                            backgroundImage: "initial",
                                            backgroundPosition: "initial",
                                            backgroundSize: "initial",
                                            backgroundRepeat: "initial",
                                            backgroundAttachment: "initial",
                                            backgroundOrigin: "initial",
                                            backgroundClip: "initial",
                                        }}
                                    >
                                        Dữ liệu cá nhân nhạy cảm:
                                    </span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    Là dữ liệu cá nhân gắn liền với quyền riêng tư của cá nhân mà khi bị xâm phạm sẽ gây ảnh hưởng trực tiếp tới quyền
                                    và lợi ích hợp pháp của cá nhân gồm:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    a) Quan điểm ​​chính trị, quan điểm tôn giáo;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    b) Tình trạng sức khỏe và đời tư được ghi trong hồ sơ bệnh án, không bao gồm thông tin về nhóm máu;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    c) Thông tin liên quan đến nguồn gốc chủng tộc, nguồn gốc dân tộc;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    d) Thông tin về đặc điểm di truyền được thừa hưởng hoặc có được của cá nhân;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    đ) Thông tin về thuộc tính vật lý, đặc điểm sinh học riêng của cá nhân;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    e) Thông tin về đời sống tình dục, xu hướng tình dục của cá nhân;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    g) Dữ liệu về tội phạm, hành vi phạm tội được thu thập, lưu trữ bởi các cơ quan thực thi pháp luật;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    h) Thông tin khách hàng của tổ chức tín dụng, chi nhánh ngân hàng nước ngoài, tổ chức cung ứng dịch vụ trung gian
                                    thanh toán, các tổ chức được phép khác, gồm: thông tin định danh khách hàng theo quy định của pháp luật, thông tin
                                    về tài khoản, thông tin về tiền gửi, thông tin về tài sản gửi, thông tin về giao dịch, thông tin về tổ chức, cá
                                    nhân là bên bảo đảm tại tổ chức tín dụng, chi nhánh ngân hàng, tổ chức cung ứng dịch vụ trung gian thanh toán;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    i) Dữ liệu về vị trí của cá nhân được xác định qua dịch vụ định vị;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 28.3pt",
                                    textAlign: "justify",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    k) Dữ liệu cá nhân khác được pháp luật quy định là đặc thù và cần có biện pháp bảo mật cần thiết.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-28.3pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    1.4.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bên xử lý dữ liệu cá nhân khác:{" "}
                                    <span
                                        style={{
                                            backgroundImage: "initial",
                                            backgroundPosition: "initial",
                                            backgroundSize: "initial",
                                            backgroundRepeat: "initial",
                                            backgroundAttachment: "initial",
                                            backgroundOrigin: "initial",
                                            backgroundClip: "initial",
                                        }}
                                    >
                                        là tổ chức, cá nhân thực hiện việc xử lý dữ liệu thay mặt cho HPay trong một số trường hợp (như lưu trữ),
                                        thông qua một hợp đồng hoặc thỏa thuận với HPay.
                                    </span>
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-28.3pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    1.5.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    Bên thứ ba là tổ chức, cá nhân ngoài Người dùng, HPay, Bên xử lý dữ liệu cá nhân khác được phép xử lý dữ liệu cá
                                    nhân của Người dùng theo Chính sách, quy định của HPay và pháp luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-28.3pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    1.6.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bằng việc sử dụng Các Dịch Vụ, đăng ký một tài khoản với HPay hoặc truy cập Dịch vụ, bạn xác nhận và đồng ý rằng
                                    bạn chấp nhận các phương pháp, yêu cầu, và/hoặc chính sách được mô tả trong Chính sách bảo mật này, và theo đây
                                    bạn đồng ý cho phép HPay thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của bạn như mô tả trong đây.{" "}
                                    <b>
                                        NẾU BẠN KHÔNG ĐỒNG Ý CHO PHÉP XỬ LÝ DỮ LIỆU CÁ NHÂN CỦA BẠN NHƯ MÔ TẢ TRONG CHÍNH SÁCH NÀY, VUI LÒNG KHÔNG SỬ
                                        DỤNG CÁC DỊCH VỤ CỦA HPAY HAY TRUY CẬP NỀN TẢNG HOẶC TRANG MẠNG CỦA{" "}
                                    </b>
                                </span>
                                <b>
                                    <span
                                        lang="EN-US"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                            msoAnsiLanguage: "EN-US",
                                        }}
                                    >
                                        CHÚNG TÔI
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        .
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    1.7.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nếu HPay thay đổi Chính sách bảo mật, HPay sẽ thông báo cho bạn bao gồm cả thông qua việc đăng tải những thay đổi
                                    đó hoặc Chính sách bảo mật sửa đổi trên Nền tảng của HPay. Trong phạm vi pháp luật cho phép, việc tiếp tục sử dụng
                                    các Dịch Vụ hoặc Nền Tảng, bao gồm giao dịch của bạn, được xem là bạn đã công nhận và đồng ý với các thay đổi
                                    trong Chính Sách Bảo Mật này.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 6pt 1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        2.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        MỤC ĐÍCH
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="EN-US"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                            msoAnsiLanguage: "EN-US",
                                        }}
                                    >
                                        , PHẠM VI
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        {" "}
                                        THU THẬP
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="EN-US"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                            msoAnsiLanguage: "EN-US",
                                        }}
                                    >
                                        {" "}
                                        VÀ XỬ LÝ
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        {" "}
                                        THÔNG TIN:
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginBottom: "6pt",
                                    textAlign: "justify",
                                    textIndent: "1cm",
                                    lineHeight: "107%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "107%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay thu thập thông tin cá nhân cần thiết của người dùng nhằm các mục đích sau:
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    margin: "1.9pt 0cm 1.9pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    2.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Xử lý các yêu cầu của Quý khách hàng khi đề nghị cung cấp các sản phẩm dịch vụ:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nhận biết/nhận diện, xác minh danh tính, lý lịch
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    ;
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12pt",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    Theo quy định tại Khoản 1 Điều 2 Quyết định số{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    2345/QĐ-NHNN
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12pt",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    {" "}
                                    ngày 18/12/2023 Quyết định{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    về triển khai các giải pháp an toàn, bảo mật trong thanh toán trực tuyến và thanh toán thẻ ngân hàng
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    . Chúng tôi cần sử dụng giải pháp xác thực khuôn mặt trùng khớp giữa hình ảnh, dữ liệu cá nhân khách hàng/người
                                    dùng cung cấp nhằm mục đích giám sát, giảm thiểu rủi ro từ các hành vi gian lận, giả mạo và các hành vi vi phạm
                                    pháp luật khác có thể xảy ra,…
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Cập nhật thông tin cá nhân;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Kiểm tra, đối chiếu với các nguồn dữ liệu liên quan;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nhập liệu, kiểm tra, kiểm soát tính đầy đủ và chính xác của các dữ liệu được nhập vào hệ thống;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Kiểm tra, đánh giá, thẩm định, phê duyệt{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    điều kiện sử dụng dịch vụ của khách hàng
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Phục vụ mục đích phòng, chống rửa tiền, chống tài trợ khủng bố, tuân thủ cấm vận hoặc gửi cho cơ quan có thẩm
                                    quyền theo quy định từng thời kỳ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Xác thực Khách hàng/người dùng{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Dịch vụ
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    để có thể truy cập tài khoản số/điện tử, để cá biệt hóa trải nghiệm của Quý Khách hàng đối với các sản phẩm dịch
                                    vụ
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                    do HPay cung cấp
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Kích hoạt/mở, quản lý, duy trì, đóng/khóa (các) dịch vụ và tiện ích sản phẩm dịch vụ mà Khách hàng sử dụng;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Cung cấp/gửi{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    báo cáo giao dịch
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    , tin nhắn/xác nhận số dư/hóa đơn, thư tín, thư hoặc các thông báo khác (bao gồm nhưng không giới hạn: thông báo
                                    cập nhật về các thay đổi, bao gồm mọi sửa đổi, bổ sung, mở rộng, đình chỉ và thay thế đối với sản phẩm dịch vụ và
                                    tiện ích tới Khách hàng hoặc các bên khác có liên quan;&nbsp;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Thực hiện các chương trình quảng cáo, tiếp thị, khuyến mại, ưu đãi, hỗ trợ chăm sóc khách hàng của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    .&nbsp;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Trả lời các câu hỏi và khiếu nại của Quý khách hàng và giải quyết tranh chấp.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Phục vụ cho việc xử lý các yêu cầu của Quý khách hàng liên quan đến đề nghị cung cấp{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    D
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ịch vụ.
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    margin: "1.9pt 0cm 1.9pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    2.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Sử dụng, phân tích để phát triển, cung cấp, cải tiến nâng cao chất lượng các sản phẩm dịch vụ gồm:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Tạo dữ liệu, báo cáo và thống kê, phản hồi cho{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    và/hoặc cho các bên có liên quan;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Tiến hành nghiên cứu thị trường, khảo sát và phân tích dữ liệu liên quan đến sản phẩm dịch vụ, tiện ích do{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    cung cấp và/hoặc{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    cùng với các bên khác cung cấp;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Đánh giá rủi ro, phân tích xu hướng, thống kê, lên kế hoạch, bao gồm phân tích xử lý dữ liệu thống kê, rủi ro để
                                    tạo lập và duy trì các hệ thống{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    quản trị rủi ro
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Thu thập, ghi nhận đánh giá, ý kiến phản hồi của Khách hàng thông qua các cuộc khảo sát để cải thiện chất lượng
                                    dịch vụ.
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    margin: "1.9pt 0cm 1.9pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    2.3.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Đáp ứng các yêu cầu để HPay thực hiện quyền và nghĩa vụ của mình trong việc tuân thủ quy định pháp luật và quy
                                    định của HPay, gồm:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Quản lý và ghi âm các cuộc gọi/ghi hình và các liên lạc thông qua kênh điện tử với Khách hàng và các bên có liên
                                    quan khác;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Quản lý các lợi ích hoặc quyền lợi liên quan đến mối quan hệ của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    với Khách hàng hoặc phát sinh từ việc Khách hàng tham gia vào các sự kiện/chiến dịch/chương trình quảng cáo, tiếp
                                    thị, khuyến mại, ưu đãi, hỗ trợ của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    hoặc{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    kết hợp với tổ chức, cá nhân khác;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Quản lý cơ sở hạ tầng và hoạt động kinh doanh của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bảo vệ hoặc thực thi các quyền của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    , bao gồm nhưng không giới hạn: việc để thu các khoản phí
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    ,{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    thu hồi nợ; làm việc với cơ quan chức năng, cá nhân liên quan để thu hồi nợ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Đáp ứng hoặc tuân thủ các chính sách nội bộ của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Đáp ứng hoặc tuân thủ các thủ tục, văn bản quy phạm pháp luật, quy tắc, quy định, hướng dẫn, công văn, chỉ thị
                                    hoặc yêu cầu được ban hành bởi bất kỳ tòa án, cơ quan có thẩm quyền (trong nước hoặc quốc tế) (bao gồm nhưng không
                                    giới hạn việc tiết lộ thông tin cho cơ quan có thẩm quyền hoặc bất kỳ mục đích nào khác được yêu cầu hoặc cho phép
                                    bởi bất kỳ luật, quy định, hướng dẫn của các cơ quan có thẩm quyền);
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Để ngăn chặn hoặc điều tra bất kỳ hoạt động gian lận, phi pháp, thiếu sót hay hành vi sai trái nào, cho dù đã diễn
                                    ra hay chưa, có liên quan đến việc{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Khách hàng
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    sử dụng Các Dịch Vụ của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    hay không hay bất kỳ vấn đề nào phát sinh từ quan hệ của bạn với HPay
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Tiến hành giao dịch mua bán, chuyển nhượng, chuyển giao việc kinh doanh và/hoặc tài sản của{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ;&nbsp;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Cho mục đích lưu trữ và tra cứu thông tin, dữ liệu;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Thực hiện và tuân thủ với các thoả thuận, hợp đồng giữa{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    với các bên khác;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "10.0pt",
                                        msoBidiFontSize: "12.0pt",
                                        fontFamily: "Symbol",
                                        msoFareastFontFamily: "Symbol",
                                        msoBidiFontFamily: "Symbol",
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ·
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Cung cấp cho các bên cung cấp dịch vụ cho{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    .
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    margin: "1.9pt 0cm 1.9pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    2.4.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Thực hiện các hoạt động khác có liên quan đến việc phát triển, cải tiến, cung cấp, vận hành, xử lý và quản lý của
                                    HPay đối với các sản phẩm, dịch vụ ngân hàng.
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    margin: "1.9pt 0cm 1.9pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    2.5.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Vì Các Mục Đích mà HPay sẽ/có thể thu thập, sử dụng, tiết lộ hoặc xử lý dữ liệu cá nhân của bạn phụ thuộc vào hoàn
                                    cảnh hiện có, mục đích đó có thể không xuất hiện bên trên. Tuy nhiên, HPay sẽ thông báo cho bạn biết mục đích khác
                                    đó tại thời điểm xin sự cho phép của bạn, trừ phi việc xử lý dữ liệu áp dụng mà không có sự đồng ý của bạn là được
                                    phép theo các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân hoặc theo quy định pháp luật.
                                </span>
                            </p>
                            <p
                                className="MsoListParagraph"
                                style={{
                                    margin: "1.9pt 0cm 1.9pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    lineHeight: "16.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    2.6.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Phục vụ việc thực hiện quyền và nghĩa vụ của HPay theo quy định pháp luật, quy định của HPay, và các mục đích khác
                                    mà HPay cho là cần thiết tại từng thời điểm.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 6pt 1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        3.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        THỜI ĐIỂM THU THẬP THÔNG TIN:
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginBottom: "6pt",
                                    textAlign: "justify",
                                    textIndent: "1cm",
                                    lineHeight: "107%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "107%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay sẽ/có thể thu thập dữ liệu cá nhân về bạn khi:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn đăng ký và/hoặc sử dụng Các Dịch Vụ hoặc Nền tảng của HPay, hoặc mở một tài khoản với HPay;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn gửi bất kỳ biểu mẫu nào, bao gồm đơn đăng ký hoặc các mẫu đơn khác liên quan đến bất kỳ sản phẩm và dịch
                                    vụ nào của HPay, bằng hình thức trực tuyến hay dưới hình thức khác;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    c.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn ký kết bất kỳ thỏa thuận nào hoặc cung cấp các tài liệu hoặc thông tin khác liên quan đến tương tác giữa
                                    bạn với HPay, hoặc khi bạn sử dụng các sản phẩm và dịch vụ của HPay;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    d.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn tương tác với HPay, chẳng hạn như thông qua các cuộc gọi điện thoại (có thể được ghi âm lại), thư từ, fax,
                                    gặp gỡ trực tiếp, các nền ứng dụng truyền thông xã hội và email;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    e.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn sử dụng các dịch vụ điện tử của HPay, hoặc tương tác với HPay qua Nền tảng hoặc Trang Mạng hoặc Các Dịch
                                    Vụ của HPay. Trường hợp này bao gồm thông qua tập tin cookie mà HPay có thể triển khai khi bạn tương tác với các
                                    Nền tảng hoặc Trang Mạng của HPay;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    f.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn thực hiện các giao dịch thông qua Dịch vụ của HPay;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    g.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn sử dụng dịch vụ liên kết của bên thứ ba được tích hợp trên nền tảng của HPay;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    h.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn cung cấp ý kiến phản hồi hoặc gửi khiếu nại cho HPay;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    i.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn đăng ký tham gia một cuộc thi;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "31.5pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    j.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    khi bạn gửi dữ liệu cá nhân của bạn cho HPay vì bất kỳ lý do gì.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        4.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        PHẠM VI THU THẬP
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    4.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dữ liệu cá nhân mà HPay có thể thu thập
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    được nêu tại Khoản 1.3 của Chính sách bảo mật này.
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    4.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bạn đồng ý không cung cấp cho HPay bất cứ thông tin nào không chính xác hoặc gây hiểu nhầm và bạn đồng ý sẽ thông
                                    báo cho HPay về bất cứ thông tin nào không chính xác hoặc khi có sự thay đổi thông tin. HPay bảo lưu quyền theo
                                    quyết định riêng của HPay được yêu cầu các tài liệu cần thiết khác để xác minh thông tin được bạn cung cấp.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    4.3.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nếu bạn không muốn HPay thu thập thông tin/dữ liệu cá nhân nói trên, bạn có thể chọn không tham gia vào bất kỳ lúc
                                    nào bằng cách thông báo bằng văn bản đến HPay. Tuy nhiên, lưu ý rằng việc từ chối hoặc hủy bỏ cho phép HPay thu
                                    thập, sử dụng hoặc xử lý dữ liệu cá nhân của bạn có thể ảnh hưởng đến việc bạn sử dụng Các Dịch Vụ và Nền tảng.{" "}
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-26.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        5.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        THU THẬP CÁC DỮ LIỆU KHÁC
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    5.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Như với hầu hết các trang{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    mạng
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    và các ứng dụng di động khác, thiết bị của bạn gửi thông tin có thể gồm có dữ liệu về bạn,{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    khi đó các thông tin sẽ được lưu trữ tạm thời trên hệ thống của HPay
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    . Thông tin này bao gồm địa chỉ IP, hệ điều hành của máy tính/thiết bị di động, loại trình duyệt, loại thiết bị di
                                    động, các đặc điểm của thiết bị di động, mã định danh thiết bị thống nhất (UDID) hoặc mã định danh thiết bị di
                                    động (MEID) của thiết bị di động của bạn, địa chỉ tham chiếu của Trang Mạng (nếu có), các trang mà bạn đã truy cập
                                    đến trên trang mạng hoặc ứng dụng di động của HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    ,{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    thời gian truy cập và{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    có thể là dữ liệu tạm dạng
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    "cookie" (có thể vô hiệu hóa bằng cách sử dụng tùy chọn trình duyệt của bạn) để giúp trang mạng ghi nhớ lần truy
                                    cập cuối cùng của bạn. Nếu bạn đăng nhập, thông tin này được liên kết với tài khoản cá nhân của bạn. Thông tin này
                                    cũng được đưa vào các số liệu thống kê ẩn danh để giúp HPay hiểu được khách truy cập sử dụng Nền tảng của HPay như
                                    thế nào.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    5.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dịch vụ của HPay có thể thu thập{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    và lưu trữ tạm thời các{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    thông tin{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    liên quan đến
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    thiết bị của bạn như GPS, Wi-Fi,…. HPay thu thập, sử dụng, công bố và/hoặc xử lý các thông tin này cho một hoặc
                                    nhiều mục đích bao gồm các dịch vụ được cung cấp dựa trên vị trí mà bạn yêu cầu hoặc chuyển các nội dung có liên
                                    quan đến bạn dựa trên vị trí của bạn hoặc cho phép bạn chia sẻ vị trí của bạn cho các Người sử dụng khác như là
                                    một phần của các Dịch vụ được cung cấp bởi các ứng dụng di động của HPay. Đối với phần lớn các thiết bị di động,
                                    bạn có thể rút lại sự cho phép để HPay được thu thập các thông tin này dựa trên vị trí của bạn thông qua các cài
                                    đặt trên thiết bị. Nếu bạn có câu hỏi nào về cách thức vô hiệu hóa các dịch vụ{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    định vị
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    trên thiết bị di động của bạn, vui lòng liên hệ với các nhà cung cấp dịch vụ thiết bị di động hoặc nhà sản xuất
                                    thiết bị của bạn.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 1cm",
                                    textAlign: "justify",
                                    textIndent: "-1cm",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        6.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        XEM VÀ TẢI CÁC NỘI DUNG VÀ QUẢNG CÁO
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 27pt",
                                    textAlign: "justify",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Do khi bạn xem các trang trên trang{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    mạng
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    hoặc ứng dụng di động của HPay, khi bạn xem các nội dung quảng cáo và truy cập vào các phần mềm khác trên Nền tảng
                                    của HPay hoặc thông qua Dịch vụ, phần lớn các thông tin{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    liên quan
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    sẽ được gửi đến cho HPay (bao gồm địa chỉ IP, hệ điều hành, v.v…); nhưng, thay vì các lượt xem trang, thiết bị của
                                    bạn{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    sẽ{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    gửi đến HPay thông tin về các nội dung, quảng cáo được xem và/hoặc phần mềm được cài đặt trên các Dịch vụ
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    ,{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nền tảng và thời gian.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        7.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        XỬ LÝ DỮ LIỆU CÁ NHÂN TRONG TRƯỜNG HỢP KHÔNG CẦN SỰ ĐỒNG Ý CỦA CHỦ THỂ DỮ LIỆU
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 0.0001pt 27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    7.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Trong trường hợp khẩn cấp, cần xử lý ngay dữ liệu cá nhân có liên quan để bảo vệ tính mạng, sức khỏe của Người
                                    dùng hoặc người khác. HPay, Bên xử lý dữ liệu khác hoặc Bên thứ ba có trách nhiệm chứng minh trường hợp này.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    7.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Việc công khai dữ liệu cá nhân theo quy định của luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    7.3.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Việc xử lý dữ liệu của cơ quan nhà nước có thẩm quyền trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh
                                    quốc gia, trật tự an toàn xã hội, thảm họa lớn, dịch bệnh nguy hiểm; khi có nguy cơ đe dọa an ninh, quốc phòng
                                    nhưng chưa đến mức ban bố tình trạng khẩn cấp; phòng, chống bạo loạn, khủng bố, phòng, chống tội phạm và vi phạm
                                    pháp luật theo quy định của luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    7.4.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Để thực hiện nghĩa vụ theo hợp đồng của chủ thể dữ liệu với cơ quan, tổ chức, cá nhân có liên quan theo quy định
                                    của luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 6pt 27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    lineHeight: "106%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    7.5.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "106%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Phục vụ hoạt động của cơ quan nhà nước đã được quy định theo luật chuyên ngành.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginBottom: "6pt",
                                    textAlign: "justify",
                                    textIndent: "-0.1pt",
                                    lineHeight: "107%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "107%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PHƯƠNG THỨC BẢO VỆ VÀ LƯU TRỮ THÔNG TIN NGƯỜI DÙNG
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "107%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    8.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay thực hiện các biện pháp bảo mật khác nhau và luôn nỗ lực để đảm bảo sự an toàn của dữ liệu cá nhân của bạn
                                    trên{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Nền tảng
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    của HPay. Dữ liệu cá nhân của người dùng được lưu trữ
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    , bảo vệ thông qua các lớp tường lửa,
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    các mạng bảo mật và chỉ có thể được truy cập bởi một số nhân viên có quyền truy cập đặc biệt đến các hệ thống của
                                    HPay.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    8.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay sẽ duy trì dữ liệu cá nhân tuân theo các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân và/hoặc
                                    các điều luật hiện hành khác. Có nghĩa là, HPay sẽ hủy hoặc xóa thông tin nhận dạng ra khỏi dữ liệu cá nhân của
                                    bạn khi HPay có lý do hợp lý để xác định rằng:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    i.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    việc lưu giữ dữ liệu cá nhân đó không còn phục vụ mục đích thu thập dữ liệu cá nhân đó nữa;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    ii.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    việc lưu giữ không còn cần thiết cho bất kỳ mục đích hợp pháp hay mục đích kinh doanh nào và;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;
                                    </span>
                                    iii.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    không còn các lợi ích hợp pháp nào khác để tiếp tục lưu giữ các dữ liệu cá nhân này. Nếu bạn ngừng sử dụng{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Dịch vụ
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    , hoặc quyền của bạn được sử dụng Nền tảng và/hoặc Các Dịch Vụ bị chấm dứt hoặc hủy bỏ, HPay có thể tiếp tục lưu,
                                    sử dụng và/hoặc tiết lộ dữ liệu cá nhân của bạn tuân theo Chính sách bảo mật này và các nghĩa vụ của HPay theo các
                                    quy định của pháp luật về bảo vệ bí mật thông tin cá nhân. Tùy thuộc vào quy định của pháp luật, HPay có thể tiêu
                                    hủy dữ liệu cá nhân của bạn một cách an toàn mà không cần thông báo trước cho bạn.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;
                                    </span>
                                    iv.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Khi có yêu cầu xóa dữ liệu hợp pháp từ Người dùng, trừ trường hợp pháp luật có quy định khác.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        9.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        NHỮNG NGƯỜI HOẶC TỔ CHỨC CÓ THỂ TIẾP CẬN{" "}
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="EN-US"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                            msoAnsiLanguage: "EN-US",
                                        }}
                                    >
                                        VÀ XỬ LÝ{" "}
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        THÔNG TIN:
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    9.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Cán bộ, nhân viên, cộng tác viên trong hệ thống của HPay;
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    9.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Trong quá trình thực hiện hoạt động kinh doanh, HPay sẽ/có thể cần phải tiết lộ dữ liệu cá nhân của bạn cho các
                                    nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc các công ty liên kết hoặc công ty liên quan của HPay, và/hoặc các
                                    bên thứ ba khác, vì một hay nhiều Mục Đích nói trên, và việc tiết lộ này sẽ được thực hiện theo đúng trình tự và
                                    quy định của pháp luật hiện hành. Các nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc các công ty liên kết hoặc
                                    công ty liên quan và/hoặc các bên thứ ba khác như thế sẽ xử lý dữ liệu cá nhân của bạn hoặc thay mặt HPay hoặc
                                    khác, vì một hoặc nhiều Mục Đích nói trên. Các bên thứ ba như thế bao gồm:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Tổ chức ngân hàng;
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Công ty liên kết và công ty liên quan của HPay;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    c.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nhà thầu, đại lý, nhà cung cấp dịch vụ và các bên thứ ba khác mà HPay thuê để hỗ trợ hoặc hợp tác, bổ sung cho
                                    hoạt động kinh doanh của HPay. Những bên này bao gồm, nhưng không giới hạn ở những bên cung cấp các dịch vụ quản
                                    trị hoặc các dịch vụ khác cho HPay chẳng hạn như công ty bưu chính, vận chuyển, công ty viễn thông, đối tác quảng
                                    cáo và truyền thông, công ty công nghệ thông tin,{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    an toàn thông tin, trung tâm dữ liệu,{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    các tổ chức hoạt động thương mại điện tử, các tổ chức hoạt động trung gian thanh toán
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                    khác mà HPay có quan hệ hợp tác
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    , trung tâm dữ liệu;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    d.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Người mua hoặc người thừa nhiệm khác trong trường hợp sáp nhập, thoái vốn, tái cơ cấu, tái tổ chức, giải thể hoặc
                                    bán hay chuyển nhượng một phần hoặc tất cả tài sản của HPay, cho dù là vấn đề đang diễn ra hay đang trong thủ tục
                                    phá sản, thanh lý hoặc thủ tục tương tự, trong đó dữ liệu cá nhân HPay lưu giữ về người dùng của HPay nằm trong
                                    các tài sản được chuyển nhượng; hoặc cho một bên đối tác trong một giao dịch tài sản kinh doanh mà HPay hoặc bất
                                    kỳ công ty liên kết hay công ty liên quan nào của nó có tham gia giao dịch; và
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    e.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Công ty kiểm toán, đơn vị cung cấp dịch vụ pháp lý;
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    f.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bên thứ ba mà HPay tiết lộ thông tin vì một trong các Mục Đích và các bên thứ ba đó ngược lại họ sẽ thu thập và xử
                                    lý dữ liệu cá nhân của bạn vì một hoặc nhiều Mục Đích
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    .
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    9.3.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay có thể chia sẻ thông tin thống kê và nhân khẩu học về Người Dùng cũng như việc sử dụng Các Dịch Vụ của người
                                    dùng với đối tác cung cấp dịch vụ quảng cáo và lập trình. HPay cũng sẽ chia sẻ thông tin thống kê và thông tin
                                    nhân khẩu học về người dùng của HPay và việc họ sử dụng Các Dịch Vụ với các đối tác quảng cáo và bên thứ ba cung
                                    cấp dịch vụ quảng cáo, tái quảng cáo, và/hoặc lập trình. Trường hợp này sẽ không bao gồm bất kỳ thông tin nào có
                                    thể được sử dụng để xác định danh tính cụ thể của bạn hoặc tiết lộ thông tin cá nhân về bạn.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    9.4.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Để tránh nghi ngờ, trong trường hợp các quy định của pháp luật về bảo vệ bí mật thông tin cá nhân hoặc các điều
                                    luật hiện hành khác cho phép một tổ chức chẳng hạn như HPay thu thập, sử dụng hoặc tiết lộ dữ liệu cá nhân của bạn
                                    mà không cần sự đồng ý của bạn, sự cho phép đó của pháp luật sẽ tiếp tục áp dụng.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "27pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    9.5.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Các bên thứ ba có thể chặn hoặc truy cập trái phép dữ liệu cá nhân được gửi đến hoặc có trên trang mạng, các công
                                    nghệ có thể hoạt động không chính xác hoặc không hoạt động như dự kiến, hoặc có người có thể truy cập, lạm dụng
                                    hoặc sử dụng sai trái thông tin mà không phải lỗi của HPay. Tuy nhiên HPay sẽ triển khai các biện pháp bảo mật hợp
                                    lý để bảo vệ dữ liệu cá nhân của bạn theo quy định của các quy định của pháp luật về bảo vệ bí mật thông tin cá
                                    nhân; tuy nhiên không thể đảm bảo sự bảo mật tuyệt đối chẳng hạn như trường hợp tiết lộ trái phép phát sinh từ
                                    hoạt động tin tặc vì ý đồ xấu hoặc phức tạp của các nội dung có độc không phải lỗi của HPay.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.45pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        10.
                                        <span
                                            style={{
                                                fontVariantNumeric: "normal",
                                                fontVariantEastAsian: "normal",
                                                fontVariantAlternates: "normal",
                                                fontKerning: "auto",
                                                fontOpticalSizing: "auto",
                                                fontFeatureSettings: "normal",
                                                fontVariationSettings: "normal",
                                                fontVariantPosition: "normal",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontSize: "7pt",
                                                lineHeight: "normal",
                                                fontFamily: '"Times New Roman"',
                                            }}
                                        >
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </b>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        THÔNG TIN VỀ TRẺ EM
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    10.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Các Dịch Vụ này không dành cho trẻ em. HPay không cố tình thu thập hay lưu giữ bất kỳ dữ liệu cá nhân hay thông
                                    tin không nhận dạng cá nhân nào của bất kỳ ai dưới 1
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    5
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    tuổi, bất kỳ phần nào của Nền tảng của HPay hoặc Các Dịch Vụ khác cũng không dành cho trẻ em. Bố/mẹ hoặc người
                                    giám hộ của trẻ em vui lòng giám sát và đảm bảo thông tin cá nhân của trẻ mà mình đang giám hộ không cung cấp
                                    thông tin cá nhân cho HPay. Trong trường hợp thông tin cá nhân của của trẻ em do quý phụ huynh giám hộ cung cấp
                                    cho HPay, Bố/mẹ hoặc người giám hộ đồng ý với việc xử lý thông tin của trẻ em có liên quan, và đồng ý chịu sự điều
                                    chỉnh của Chính Sách này thay mặt cho người được giám hộ.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 6pt 35.4pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    10.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay sẽ ngừng xử lý dữ liệu cá nhân của trẻ em, xóa không thể khôi phục hoặc hủy dữ liệu cá nhân của trẻ em trong
                                    trường hợp:
                                </span>
                            </p>
                            <ol style={{ marginTop: "0cm" }} start={1} type="a">
                                <li
                                    className="MsoNormal"
                                    style={{
                                        color: "black",
                                        marginTop: "6pt",
                                        textAlign: "justify",
                                        lineHeight: "107%",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "107%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        Xử lý dữ liệu không đúng mục đích hoặc đã hoàn thành mục đích xử lý dữ liệu cá nhân được chủ thể dữ liệu đồng
                                        ý, trừ trường hợp pháp luật có quy định khác;
                                    </span>
                                </li>
                                <li
                                    className="MsoNormal"
                                    style={{
                                        color: "black",
                                        textAlign: "justify",
                                        lineHeight: "107%",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "107%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        Cha, mẹ hoặc người giám hộ của trẻ em rút lại sự đồng ý cho phép xử lý dữ liệu cá nhân của trẻ em, trừ trường
                                        hợp pháp luật có quy định khác;
                                    </span>
                                </li>
                                <li
                                    className="MsoNormal"
                                    style={{
                                        color: "black",
                                        marginBottom: "6pt",
                                        textAlign: "justify",
                                        lineHeight: "107%",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "107%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        Theo yêu cầu của cơ quan chức năng có thẩm quyền khi có đủ căn cứ chứng minh việc xử lý dữ liệu cá nhân gây
                                        ảnh hưởng tới quyền và lợi ích hợp pháp của trẻ em, trừ trường hợp pháp luật có quy định khác.
                                    </span>
                                </li>
                            </ol>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginBottom: "7.5pt",
                                    textAlign: "justify",
                                    textIndent: "-0.1pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        11. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; THÔNG TIN THU THẬP BỞI CÁC BÊN THỨ BA
                                    </span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-0.1pt",
                                    lineHeight: "107%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "107%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay, và các bên thứ ba, có thể thỉnh thoảng cung cấp các bản tải về ứng dụng phần mềm cho bạn sử dụng bởi Nền
                                    tảng hoặc thông qua Các Dịch Vụ. Những ứng dụng này có thể truy cập riêng, và cho phép một bên thứ ba xem, thông
                                    tin nhận dạng của bạn, chẳng hạn như tên, ID người dùng của bạn, Địa chỉ IP của thiết bị của bạn hoặc thông tin
                                    khác, và bất kỳ cookie nào trước đây bạn có thể đã cài đặt hoặc đã được cài đặt cho bạn bởi một ứng dụng phần mềm
                                    hoặc trang mạng của bên thứ ba. Ngoài ra, các ứng dụng này có thể yêu cầu bạn cung cấp thêm thông tin trực tiếp
                                    cho các bên thứ ba. Các sản phẩm hoặc dịch vụ của bên thứ ba được cung cấp thông qua các ứng dụng này không thuộc
                                    sở hữu hay quyền kiểm soát của HPay. Bạn nên đọc các điều khoản và các chính sách khác được công bố bởi các bên
                                    thứ ba đó trên trang mạng của họ hoặc nơi khác.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    lineHeight: "107%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "107%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        12. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LOẠI TRỪ TRÁCH NHIỆM VỀ NGHĨA VỤ BẢO MẬT VÀ CÁC TRANG MẠNG BÊN THỨ BA
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "107%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    12.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPAY KHÔNG ĐẢM BẢO TÍNH BẢO MẬT ĐỐI VỚI DỮ LIỆU CÁ NHÂN VÀ/HOẶC THÔNG TIN KHÁC MÀ BẠN CUNG CẤP TRÊN CÁC TRANG MẠNG
                                    CỦA BÊN THỨ BA. HPay thực hiện các biện pháp bảo mật khác nhau để duy trì sự an toàn của dữ liệu cá nhân của bạn
                                    mà HPay lưu giữ hoặc kiểm soát. Dữ liệu cá nhân của bạn được lưu{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    trữ, quản lý và bảo vệ bằng nhiều lớp tường lửa và lớp bảo mật an toàn; Dữ liệu cá nhân c
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    hỉ có thể được truy cập bởi một số cá nhân giới hạn có quyền truy cập đặc biệt đến các hệ thống của HPay,
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    và đã được yêu cầu bảo mật dữ liệu cá nhân đó. Tất cả dữ liệu cá nhân hoặc thông tin cá nhân bạn cung cấp sẽ được
                                    mã hóa vào các cơ sở dữ liệu của HPay để chỉ được truy cập như mô tả bên trên.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    12.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nhằm cung cấp cho bạn giá trị gia tăng, HPay có thể chọn các trang mạng hoặc ứng dụng hoặc dịch vụ của bên thứ ba
                                    khác nhau để liên kết, và{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    tích hợp với
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    Nền tảng. HPay cũng có thể tham gia các quan hệ cùng tiếp thị
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    ,
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    các quan hệ khác và các dịch vụ và tính năng khác cho{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    người dùng
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    truy cập. Những{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    Dịch vụ
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    được liên kết này có các chính sách về quyền riêng tư cũng như các biện pháp bảo mật riêng và độc lập. Ngay cả khi
                                    bên thứ ba đó có liên kết với HPay, HPay cũng không kiểm soát các trang mạng/ứng dụng/dịch vụ được liên kết này,
                                    mỗi trang đó có các phương pháp bảo vệ quyền riêng tư và thu thập dữ liệu riêng biệt, độc lập với HPay. Dữ liệu
                                    thu thập bởi các đối tác cùng tiếp thị của HPay hoặc các trang mạng/ứng dụng/dịch vụ của bên thứ ba (ngay cả khi
                                    được cung cấp trên hoặc thông qua Nền tảng của HPay) có thể không được HPay tiếp cận và/hoặc lưu giữ.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    12.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Do đó HPay không{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    có
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    nghĩa vụ
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    hay trách nhiệm pháp lý đối với nội dung, các biện pháp bảo mật (hoặc sự thiếu biện pháp bảo mật) và các hoạt động
                                    của các trang{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    mạng
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    /ứng dụng/dịch vụ được liên kết này. Những trang{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    mạng
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    /ứng dụng/dịch vụ được liên kết này chỉ vì sự thuận tiện cho bạn và do đó bạn tự chịu trách nhiệm khi truy cập
                                    chúng. Tuy nhiên, HPay
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                    sẽ luôn
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    tìm cách bảo vệ tính toàn vẹn của Dịch vụ của HPay và các liên kết{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    nếu{" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    được đặt trên từng trang{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    mạng
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    đó và do đó HPay hoan nghênh ý kiến phản hồi về các trang{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    mạng
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    được liên kết này (bao gồm nếu một trang{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    mạng
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    cụ thể không hoạt động).&nbsp;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36.1pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        13. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        <span
                                            style={{
                                                backgroundImage: "initial",
                                                backgroundPosition: "initial",
                                                backgroundSize: "initial",
                                                backgroundRepeat: "initial",
                                                backgroundAttachment: "initial",
                                                backgroundOrigin: "initial",
                                                backgroundClip: "initial",
                                            }}
                                        >
                                            PHƯƠNG THỨC VÀ CÔNG CỤ ĐỂ NGƯỜI DÙNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU CÁ NHÂN CỦA MÌNH
                                        </span>
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    13.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Rút Lại Sự Đồng ý cung cấp thông tin:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bạn có thể rút lại sự đồng ý cho phép thu thập, sử dụng và/hoặc tiết lộ dữ liệu cá nhân của bạn mà HPay đang lưu
                                    giữ hoặc kiểm soát bằng cách gửi email cho HPay tại địa chỉ email:&nbsp;
                                </span>
                                <span lang="vi" style={{ color: "black", msoColorAlt: "windowtext" }}>
                                    <a href="mailto:cskh@htpgroup.com.vn" target="_blank">
                                        <span
                                            style={{
                                                fontSize: "12pt",
                                                lineHeight: "115%",
                                                fontFamily: '"Times New Roman", serif',
                                                color: "rgb(17, 85, 204)",
                                                backgroundImage: "initial",
                                                backgroundPosition: "initial",
                                                backgroundSize: "initial",
                                                backgroundRepeat: "initial",
                                                backgroundAttachment: "initial",
                                                backgroundOrigin: "initial",
                                                backgroundClip: "initial",
                                            }}
                                        >
                                            cskh@htpgroup.com.vn
                                        </span>
                                    </a>
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    , và HPay sẽ xử lý các yêu cầu này theo Chính Sách Bảo Mật cũng như quy định pháp luật có liên quan.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Tuy nhiên, việc bạn rút lại sự đồng ý có thể có nghĩa là HPay sẽ không thể tiếp tục cung cấp Các Dịch Vụ cho bạn,
                                    và HPay có thể cần phải chấm dứt quan hệ hiện tại của bạn và/hoặc hợp đồng của bạn với HPay.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    13.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Yêu Cầu Truy Cập đến hoặc Sửa Dữ Liệu Cá Nhân
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nếu bạn truy cập Dịch vụ của HPay thông qua Tài khoản mạng xã hội. Cá nhân bạn có thể truy cập và/hoặc sửa dữ liệu
                                    cá nhân của bạn thông qua tài khoản mạng xã hội của bạn thông qua trang Thiết Lập Tài Khoản hoặc nhãn trên tài
                                    khoản (nếu có); Khi đó, thông tin của bạn sẽ được tự động cập nhật tại HPay tương ứng với thông tin mà bạn đã thay
                                    đổi tại Mạng xã hội.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nếu bạn chưa đăng ký tài khoản với HPay, cá nhân bạn có thể yêu cầu truy cập và/hoặc sửa dữ liệu cá nhân của bạn
                                    mà HPay đang lưu giữ hoặc kiểm soát bằng cách gửi yêu cầu bằng văn bản cho HPay. HPay sẽ cần có đủ thông tin từ
                                    bạn để xác định danh tính của bạn cũng như bản chất yêu cầu của bạn để có thể giải quyết yêu cầu của bạn trong
                                    phạm vi có thể giải quyết. Do đó, vui lòng gửi yêu cầu bằng văn bản của bạn bằng cách gửi email cho nhân viên Quản
                                    lý dữ liệu của HPay theo địa chỉ email đã cung cấp nêu trên.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    c.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay có thể tính một khoản phí hợp lý cho bạn để giải quyết và xử lý yêu cầu truy cập dữ liệu cá nhân của bạn. Nếu
                                    HPay có tính phí, HPay sẽ cung cấp cho bạn ước tính lệ phí bằng văn bản. Vui lòng lưu ý rằng HPay không buộc phải
                                    đáp ứng hay giải quyết yêu cầu truy cập của bạn trừ phi bạn đã đồng ý đóng phí.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    d.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay bảo lưu quyền từ chối sửa dữ liệu cá nhân của bạn theo các quy định của pháp luật về bảo vệ bí mật thông tin
                                    cá nhân, trường hợp các điều luật đó yêu cầu và/hoặc cho phép một tổ chức từ chối sửa dữ liệu cá nhân trong các
                                    trường hợp như thế.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    13.3.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Hạn chế xử lý:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginBottom: "7.5pt",
                                    textAlign: "justify",
                                    textIndent: "-0.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    13.3.1. Người dùng có quyền yêu cầu HPay hạn chế xử lý dữ liệu trong các trường hợp sau:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.4pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Trong thời gian HPay xác nhận tính chính xác hoặc trong thời gian cơ quan nhà nước có thẩm quyền giải quyết tranh
                                    chấp về dữ liệu cá nhân;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.4pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Việc xử lý dữ liệu là bất hợp pháp và người dùng phản đối việc xóa dữ liệu cá nhân và thay vào đó yêu cầu hạn chế
                                    sử dụng chúng;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.4pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    c.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay không còn nhu cầu lưu trữ dữ liệu cá nhân cho mục đích xử lý, những dữ liệu được Người dùng yêu cầu để thiết
                                    lập, thực hiện hoặc bảo vệ các khiếu nại pháp lý. Trong trường hợp này, dữ liệu sẽ chuyển sang trạng thái hạn chế
                                    xử lý thay vì xóa theo quy định;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.4pt",
                                    textAlign: "justify",
                                    textIndent: "-35.9pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    d.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Người dùng đã phản đối việc xử lý dữ liệu và trong khi chờ xác minh việc phản đối.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    13.3.2. Khi việc xử lý bị hạn chế theo quy định tại khoản 13.3.1, dữ liệu cá nhân đó, ngoại trừ việc lưu trữ, sẽ
                                    chỉ được xử lý khi có sự đồng ý của Người dùng hoặc để thiết lập, thực hiện hoặc bảo vệ các khiếu nại pháp lý hoặc
                                    để bảo vệ quyền của người khác hoặc pháp nhân hoặc theo quy định của pháp luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    13.3.3. Khi nguyên nhân phát sinh yêu cầu hạn chế xử lý dữ liệu (theo khoản 13.3.1) không còn, người dùng sẽ được
                                    HPay thông báo trước về việc biện pháp hạn chế xử lý được dỡ bỏ.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    13.4.&nbsp;&nbsp;&nbsp; Người dùng có các quyền khác đối với dữ liệu cá nhân của mình theo quy định của pháp luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginBottom: "7.5pt",
                                    textAlign: "justify",
                                    textIndent: "-0.1pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        14. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; QUYỀN PHẢN ĐỐI XỬ LÝ DỮ LIỆU
                                    </span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36.0pt",
                                    textAlign: "justify",
                                    textIndent: "-36.0pt",
                                    msoList: "l25 level1 lfo16",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    14.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Người dùng có quyền phản đối xử lý dữ liệu{" "}
                                    <span
                                        style={{
                                            backgroundImage: "initial",
                                            backgroundPosition: "initial",
                                            backgroundSize: "initial",
                                            backgroundRepeat: "initial",
                                            backgroundAttachment: "initial",
                                            backgroundOrigin: "initial",
                                            backgroundClip: "initial",
                                        }}
                                    >
                                        của mình nhằm ngăn chặn hoặc hạn chế tiết lộ dữ liệu cá nhân hoặc sử dụng cho mục đích quảng cáo, tiếp thị,
                                        trừ trường hợp luật có quy định khác.
                                    </span>
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "0cm",
                                    marginRight: "0cm",
                                    marginBottom: "10.0pt",
                                    marginLeft: "36.0pt",
                                    textAlign: "justify",
                                    textIndent: "-36.0pt",
                                    msoList: "l25 level1 lfo16",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    14.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    HPay thực hiện yêu cầu của người dùng trong vòng 72 giờ kể từ thời điểm nhận được yêu cầu. HPay sẽ không còn xử lý
                                    dữ liệu của Người dùng trừ khi chứng minh các cơ sở hợp pháp thuyết phục cho việc xử lý, những cơ sở này sẽ ảnh
                                    hưởng đến lợi ích, quyền và tự do của chủ thể dữ liệu hoặc để thiết lập, thực hiện hoặc bảo vệ các khiếu nại pháp
                                    lý hoặc Pháp luật có quy định khác.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginBottom: "7.5pt",
                                    textAlign: "justify",
                                    textIndent: "-0.1pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        15. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; XÓA, HỦY DỮ LIỆU
                                    </span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 0.0001pt 35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    15.1.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Người dùng được yêu cầu HPay xóa dữ liệu cá nhân của mình trong các trường hợp sau:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nhận thấy không còn cần thiết cho mục đích thu thập đã đồng ý và chấp nhận các thiệt hại có thể xảy ra khi yêu cầu
                                    xóa dữ liệu;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Rút lại sự đồng ý;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    c)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Phản đối việc xử lý dữ liệu;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    d)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay không có lý do chính đáng để tiếp tục thu thập, xử lý dữ liệu theo quy định tại Khoản 8.2 của Chính sách này;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    e)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dữ liệu cá nhân được xử lý không đúng với mục đích đã đồng ý hoặc việc xử lý dữ liệu cá nhân là vi phạm quy định
                                    của pháp luật;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    f)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dữ liệu cá nhân phải xóa theo quy định của pháp luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    15.2.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Việc xóa dữ liệu sẽ không áp dụng khi có đề nghị của chủ thể dữ liệu trong các trường hợp:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Pháp luật quy định không cho phép xóa dữ liệu;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dữ liệu cá nhân được xử lý bởi cơ quan nhà nước có thẩm quyền với mục đích phục vụ hoạt động của cơ quan nhà nước
                                    theo quy định của pháp luật;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    c)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dữ liệu cá nhân đã được công khai theo quy định của pháp luật;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    d)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Dữ liệu cá nhân được xử lý nhằm phục vụ yêu cầu pháp lý, nghiên cứu khoa học, thống kê theo quy định của pháp
                                    luật;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    e)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc gia, trật tự an toàn xã hội, thảm họa lớn, dịch
                                    bệnh nguy hiểm; khi có nguy cơ đe dọa an ninh, quốc phòng nhưng chưa đến mức ban bố tình trạng khẩn cấp; phòng,
                                    chống bạo loạn, khủng bố, phòng, chống tội phạm và vi phạm pháp luật;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-22.5pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    f)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Ứng phó với tình huống khẩn cấp đe dọa đến tính mạng, sức khỏe hoặc sự an toàn của chủ thể dữ liệu hoặc cá nhân
                                    khác.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.45pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    15.3.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Việc xóa dữ liệu được thực hiện trong 72 giờ sau khi có yêu cầu của người dùng, trừ trường hợp pháp luật có quy
                                    định khác.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "35.9pt",
                                    textAlign: "justify",
                                    textIndent: "-36pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    15.4.
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay sẽ thực hiện xóa dữ liệu và không thể khôi phục trong trường hợp:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    a)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Xử lý dữ liệu không đúng mục đích hoặc đã hoàn thành mục đích xử lý dữ liệu cá nhân được chủ thể dữ liệu đồng ý;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "36pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    b)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Việc lưu trữ dữ liệu cá nhân không còn cần thiết với hoạt động của HPay, Bên thứ ba;
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 6pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-27pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    c)
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    HPay, Bên thứ ba bị giải thể hoặc không còn hoạt động hoặc tuyên bố phá sản hoặc bị chấm dứt hoạt động kinh doanh
                                    theo quy định của pháp luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 36pt",
                                    textAlign: "justify",
                                    textIndent: "-36.1pt",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        16. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LIÊN HỆ VỚI HPAY VỀ CHÍNH SÁCH BẢO MẬT VÀ CÁC THÔNG TIN CÓ LIÊN QUAN
                                    </span>
                                </b>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "6pt 0cm 6pt 36pt",
                                    textAlign: "justify",
                                    lineHeight: "107%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "107%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        Tiếp nhận và giải quyết khiếu nại
                                    </span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 6pt 36pt",
                                    textAlign: "justify",
                                    lineHeight: "107%",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "107%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Khi phát hiện hoặc nghi ngờ thông tin cá nhân bị sử dụng sai mục đích hoặc phạm vi HPay đã công bố. Người dùng có
                                    thể phản ánh, khiếu nại theo các bước sau:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "14pt 0cm 6pt 36pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bước 1: Người dùng (khách
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    hàng/người dùng) khiếu nại/phản ánh qua email: cskh@htpgroup.com.vn
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 6pt 36pt",
                                    textAlign: "justify",
                                    lineHeight: "normal",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bước 2: Bộ phận Chăm Sóc Khách Hàng của HPay sẽ tiếp nhận các khiếu nại của khách hàng/người dùng và phản hồi
                                    trong vòng 24 giờ kể từ thời điểm nhận được khiếu nại, phản ánh. Tùy theo tính chất và mức độ của khiếu nại thì
                                    HPay sẽ có những biện pháp cụ thể để giải quyết khiếu nại, tranh chấp đó.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 36pt",
                                    textAlign: "justify",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Bước 3: Trong trường nằm ngoài khả năng và thẩm quyền của HPay thì{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    B
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    an quản trị sẽ yêu cầu khách hàng/người dùng đưa vụ việc này ra cơ quan nhà nước có thẩm quyền giải quyết theo
                                    pháp luật.
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    margin: "0cm 0cm 7.5pt 36pt",
                                    textAlign: "justify",
                                    backgroundImage: "initial",
                                    backgroundPosition: "initial",
                                    backgroundSize: "initial",
                                    backgroundRepeat: "initial",
                                    backgroundAttachment: "initial",
                                    backgroundOrigin: "initial",
                                    backgroundClip: "initial",
                                }}
                            >
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Nếu bạn có bất kỳ thắc mắc hoặc khiếu nại nào về các phương pháp bảo vệ quyền riêng tư của HPay vui lòng liên hệ
                                    với{" "}
                                </span>
                                <span
                                    lang="EN-US"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    chúng tôi
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    {" "}
                                    tại:
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "6.0pt",
                                    marginRight: "0cm",
                                    marginBottom: ".0001pt",
                                    marginLeft: "67.5pt",
                                    textAlign: "justify",
                                    textIndent: "-18.0pt",
                                    msoList: "l10 level1 lfo12",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Noto Sans Symbols"',
                                        msoFareastFontFamily: '"Noto Sans Symbols"',
                                        msoBidiFontFamily: '"Noto Sans Symbols"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ●
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    >
                                        CÔNG TY CỔ PHẦN{" "}
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="EN-US"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                            msoAnsiLanguage: "EN-US",
                                        }}
                                    >
                                        TẬP ĐOÀN CÔNG NGHỆ HTP
                                    </span>
                                </b>
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    ></span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "6.0pt",
                                    marginRight: "0cm",
                                    marginBottom: ".0001pt",
                                    marginLeft: "67.5pt",
                                    textAlign: "justify",
                                    textIndent: "-18.0pt",
                                    msoList: "l10 level1 lfo12",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Noto Sans Symbols"',
                                        msoFareastFontFamily: '"Noto Sans Symbols"',
                                        msoBidiFontFamily: '"Noto Sans Symbols"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ●
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Địa chỉ:&nbsp;
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                    }}
                                >
                                    Số A7 Lô A, Khu 5.2 ha - dự án nhà ở cho cán bộ cao cấp và cán bộ của Ban Đảng Trung Ương,
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoAnsiLanguage: "EN-US",
                                    }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                    }}
                                >
                                    P.Yên Hoà - Q.Cầu Giấy - Hà Nội - Việt Nam
                                </span>
                                <b>
                                    <span
                                        lang="vi"
                                        style={{
                                            fontSize: "12.0pt",
                                            lineHeight: "115%",
                                            fontFamily: '"Times New Roman",serif',
                                            msoFareastFontFamily: '"Times New Roman"',
                                            color: "black",
                                            msoThemecolor: "text1",
                                        }}
                                    ></span>
                                </b>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "67.5pt",
                                    textAlign: "justify",
                                    textIndent: "-18.0pt",
                                    msoList: "l10 level1 lfo12",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Noto Sans Symbols"',
                                        msoFareastFontFamily: '"Noto Sans Symbols"',
                                        msoBidiFontFamily: '"Noto Sans Symbols"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ●
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    Email:{" "}
                                </span>
                                <span lang="vi">
                                    <a href="mailto:cskh@htpgroup.com.vn" target="_blank">
                                        <span
                                            style={{
                                                fontSize: "12pt",
                                                lineHeight: "115%",
                                                fontFamily: '"Times New Roman", serif',
                                                color: "rgb(17, 85, 204)",
                                                backgroundImage: "initial",
                                                backgroundPosition: "initial",
                                                backgroundSize: "initial",
                                                backgroundRepeat: "initial",
                                                backgroundAttachment: "initial",
                                                backgroundOrigin: "initial",
                                                backgroundClip: "initial",
                                            }}
                                        >
                                            cskh@htpgroup.com.vn
                                        </span>
                                    </a>
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                ></span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginLeft: "67.5pt",
                                    textAlign: "justify",
                                    textIndent: "-18.0pt",
                                    msoList: "l10 level1 lfo12",
                                }}
                            >
                                {/*[if !supportLists]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Noto Sans Symbols"',
                                        msoFareastFontFamily: '"Noto Sans Symbols"',
                                        msoBidiFontFamily: '"Noto Sans Symbols"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    ●
                                    <span
                                        style={{
                                            fontVariantNumeric: "normal",
                                            fontVariantEastAsian: "normal",
                                            fontVariantAlternates: "normal",
                                            fontKerning: "auto",
                                            fontOpticalSizing: "auto",
                                            fontFeatureSettings: "normal",
                                            fontVariationSettings: "normal",
                                            fontVariantPosition: "normal",
                                            fontStretch: "normal",
                                            fontSize: "7pt",
                                            lineHeight: "normal",
                                            fontFamily: '"Times New Roman"',
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </span>
                                </span>
                                {/*[endif]*/}
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12.0pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman",serif',
                                        msoFareastFontFamily: '"Times New Roman"',
                                        color: "black",
                                        msoThemecolor: "text1",
                                    }}
                                >
                                    Hotline:
                                </span>
                                <span
                                    lang="vi"
                                    style={{
                                        fontSize: "12pt",
                                        lineHeight: "115%",
                                        fontFamily: '"Times New Roman", serif',
                                        color: "black",
                                        backgroundImage: "initial",
                                        backgroundPosition: "initial",
                                        backgroundSize: "initial",
                                        backgroundRepeat: "initial",
                                        backgroundAttachment: "initial",
                                        backgroundOrigin: "initial",
                                        backgroundClip: "initial",
                                    }}
                                >
                                    1900 0128
                                </span>
                            </p>
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Term;
