import React, { useEffect, useState } from "react";
import time from "../../assets/icon/Clip path group.png";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import articlesWebService from "../../services/webService/ArticleWebService";
import { toast } from "react-toastify";
import { Skeleton } from "antd";

const SingleNews = () => {
    const [articles, setArticles] = useState({});
    const [relatedArticles, setRelatedArticles] = useState([]);
    const location = useLocation();
    const ArticlesSlug = location.pathname.split("/")[2];
    const formatDate = (date) => moment.utc(date).format("DD-MM-YYYY HH:mm:ss");

    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            const data = articlesWebService.getArticle(ArticlesSlug);
            data.then((res) => {
                if (res?.status === 200) {
                    setArticles(res?.article);
                    setRelatedArticles(res?.related_articles);
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 500,
                        theme: "light",
                    });
                }
            });
        } catch (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 500,
                theme: "light",
            });
        }
    }, [ArticlesSlug]);

    return (
        <div className="section-top">
            <div className="container">
                <div className="row my-5">
                    <div className="col-md-5 col-12 pe-md-4 pe-none mb-4">
                        <div className="d-flex flex-column justify-content-center">
                            {articles?.image && articles?.title && articles?.description ? (
                                <>
                                    <img src={articles?.image} className="img-fluid img-single" alt="img" />

                                    <h4 className="fs-6 text-hover text-uppercase text-limit mt-3" style={{ height: "40px" }}>
                                        {articles?.title}
                                    </h4>
                                    <p className="text-lowercase my-2 text-secondary text-limit">{articles?.description}</p>
                                    <div className="d-flex align-items-center">
                                        <img src={time} className="img-fluid me-2" alt="" />
                                        <span className="text-secondary">{formatDate(articles?.created_at)}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Skeleton.Image active={true} className="w-100" style={{ height: "400px" }} />
                                    <div className="mt-3">
                                        <Skeleton
                                            paragraph={{
                                                rows: 4,
                                            }}
                                            title={false}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-md-7 col-12">
                        <h2 className="fs-1 fw-bold">{articles?.title}</h2>
                        <p className="text-secondary my-3">{articles?.description}</p>
                        <div>{!!articles?.content ? parse(articles?.content) : null}</div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-12">
                        <h2 className="fs-1 fw-bold">Tin liên quan</h2>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="row">
                            {relatedArticles?.map((relatedArticle) => (
                                <Link
                                    key={relatedArticle.id}
                                    to={`/bai-viet/${relatedArticle.slug}`}
                                    className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-none card-custom"
                                >
                                    <button className="btn-card">Xem ngay</button>

                                    <div className="d-flex flex-column justify-content-center">
                                        <img src={relatedArticle.thumbnail_url} className="img-fluid img-card" alt="img" />

                                        <h4 className="fs-6 text-hover text-uppercase text-limit mt-3" style={{ height: "40px" }}>
                                            {relatedArticle.title}
                                        </h4>
                                        <p className="text-lowercase my-2 text-secondary text-limit">{relatedArticle.description}</p>
                                        <div className="d-flex align-items-center">
                                            <img src={time} className="img-fluid me-2" alt="" />
                                            <span className="text-secondary">{formatDate(relatedArticle.created_at)}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleNews;
