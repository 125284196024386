import React, { useEffect, useState } from "react";
import "./Contact.scss";
import Banner from "../../components/Banner/Banner";
import imgC1 from "../../assets/images/imgC1.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import contactWebService from "../../services/webService/ContactWebService";

const Contact = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const formik = useFormik({
        initialValues: {
            name: "",
            company: "",
            mobile: "",
            email: "",
            title: "",
            content: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Vui lòng điền tên"),
            company: Yup.string().required("Vui lòng điền tên công ty"),
            mobile: Yup.string().required("Vui lòng điền số điện thoại"),
            email: Yup.string().email("Địa chỉ email không hợp lệ").required("Vui lòng điền Email"),
            title: Yup.string().required("Vui lòng điền tiêu đề"),
            content: Yup.string().required("Vui lòng điền nội dung"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append("name", values.name);
                formData.append("company", values.company);
                formData.append("mobile", values.mobile);
                formData.append("email", values.email);
                formData.append("title", values.title);
                formData.append("content", values.content);

                const data = contactWebService.createContact(formData);

                data.then((res) => {
                    if (res?.status === 200) {
                        toast.success(res?.message, {
                            position: "top-center",
                            autoClose: 1000,
                            theme: "light",
                        });
                        resetForm({
                            values: {
                                name: "",
                                company: "",
                                mobile: "",
                                email: "",
                                title: "",
                                content: "",
                            },
                        });
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.warn(res?.message, {
                            position: "top-center",
                            autoClose: 1000,
                            theme: "light",
                        });
                    }
                }).catch((res) => {
                    setLoading(false);
                    toast.error(res.message, {
                        position: "top-center",
                        autoClose: 1000,
                        theme: "light",
                    });
                });
            } catch (error) {
                setLoading(false);
                toast.error(error, {
                    position: "top-center",
                    autoClose: 1000,
                    theme: "light",
                });
            }
        },
    });
    return (
        <div className="section-top">
            <div className="container">
                <Banner
                    img={imgC1}
                    title={"Chào mừng bạn đến với HPay"}
                    desc={[
                        "Địa chỉ: Phường Yên Hoà, Quận Cầu Giấy, Thành phố Hà Nội, Việt Nam",
                        "Điện thoại: 1900 0128",
                        "Địa chỉ email: support@hpay.com.vn",
                    ]}
                    bgr={"to right, #FFEFBD, #EBC1F5"}
                />
                <div className="section-top-big">
                    <h2 className="text-center fw-bold fs-1 mb-4">Liên hệ</h2>
                    <div className="d-flex justify-content-center">
                        <form className="form-contact mb-5" onSubmit={formik.handleSubmit} method="POST">
                            <div>
                                <input
                                    type="text"
                                    className="custom-input"
                                    placeholder="Họ và tên"
                                    onChange={formik.handleChange("name")}
                                    onBlur={formik.handleBlur("name")}
                                    value={formik.values.name}
                                />
                                <div className="form-text text-danger ms-3">
                                    {formik.touched.name && formik.errors.name ? formik.errors.name : null}
                                </div>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="custom-input mt-3"
                                    placeholder="Công ty"
                                    onChange={formik.handleChange("company")}
                                    onBlur={formik.handleBlur("company")}
                                    value={formik.values.company}
                                />
                                <div className="form-text text-danger ms-3">
                                    {formik.touched.company && formik.errors.company ? formik.errors.company : null}
                                </div>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="custom-input mt-3"
                                    placeholder="Số điện thoại"
                                    onChange={formik.handleChange("mobile")}
                                    onBlur={formik.handleBlur("mobile")}
                                    value={formik.values.mobile}
                                />
                                <div className="form-text text-danger ms-3">
                                    {formik.touched.mobile && formik.errors.mobile ? formik.errors.mobile : null}
                                </div>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="custom-input mt-3"
                                    placeholder="Địa chỉ email"
                                    onChange={formik.handleChange("email")}
                                    onBlur={formik.handleBlur("email")}
                                    value={formik.values.email}
                                />
                                <div className="form-text text-danger ms-3">
                                    {formik.touched.email && formik.errors.email ? formik.errors.email : null}
                                </div>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="custom-input mt-3"
                                    placeholder="Tiêu đề"
                                    onChange={formik.handleChange("title")}
                                    onBlur={formik.handleBlur("title")}
                                    value={formik.values.title}
                                />
                                <div className="form-text text-danger ms-3">
                                    {formik.touched.title && formik.errors.title ? formik.errors.title : null}
                                </div>
                            </div>
                            <div>
                                <textarea
                                    type="text"
                                    className="custom-input mt-3"
                                    rows="5"
                                    cols="50"
                                    placeholder="Nội dung..."
                                    onChange={formik.handleChange("content")}
                                    onBlur={formik.handleBlur("content")}
                                    value={formik.values.content}
                                />
                                <div className="form-text text-danger ms-3">
                                    {formik.touched.content && formik.errors.content ? formik.errors.content : null}
                                </div>
                            </div>
                            <button type="submit" className="button-submit mt-4">
                                {loading ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    "Gửi"
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
