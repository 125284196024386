import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="section-top">
            <div className="container">
                <Breadcrumb pathFrom={"/"} textFrom={"Trang chủ"} to={"Quy định hoàn tiền và khiếu nại"} />
                <div className="mx-3 mx-md-5">
                    <h2 className="fs-3 fw-bold my-4 text-center text-uppercase">Quy định hoàn tiền và khiếu nại</h2>
                    <div className="mb-5">
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>1. Các tình huống và lý do khiếu nại</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    - Khách hàng không nhận được hàng hóa, dịch vụ mặc dù đã thanh toán thành công.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>- Khách hàng nhận được hàng hóa, dịch vụ không đúng với yêu cầu.</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>2. Điều kiện tiếp nhận khiếu nại</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>Khiếu nại của quý khách được xem là hợp lệ khi thỏa mãn các điều kiện sau:</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    - Thời điểm khiếu nại không vượt quá 6 tháng kể từ khi thực hiện giao dịch.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    - Thông tin quý khách cung cấp trong mẫu yêu cầu khiếu nại hoặc khi quý khách trao đổi&nbsp;trực&nbsp;tiếp với
                                    tổng đài viên qua số tổng đài chăm sóc khách hàng 1900 0128 trùng khớp với thông tin&nbsp;quý khách đã sử dụng khi
                                    thực hiện giao dịch.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>3. Thủ tục tiếp nhận khiếu nại</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    - Quý khách gọi điện thoại lên số tổng đài chăm sóc khách hàng 1900 0128 hoặc thực hiện gửi&nbsp;thông tin khiếu
                                    nại theo các bước sau:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 1: Quý khách tải về file mẫu Yêu cầu khiếu nại và cung cấp các thông tin theo yêu cầu.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 2: Quý khách gửi email đính kèm file Yêu cầu khiếu nại, đã cung cấp thông tin đầy đủ,&nbsp;đến địa chỉ email
                                    khieunai@hpay.com.vn với tiêu đề “Yêu cầu khiếu nại”.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>4. Quy trình xử lý khiếu nại</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Ngay sau khi nhận được email khiếu nại của quý khách, bộ phận Chăm Sóc Khách Hàng của Hpay&nbsp;sẽ thực hiện quy
                                    trình xử lý khiếu nại theo các bước sau:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 1: Kiểm tra thông tin khiếu nại do quý khách cung cấp. Nếu thông tin khiếu nại không&nbsp;phù hợp với các
                                    điều kiện tiếp nhận khiếu nại thì HPay sẽ yêu cầu quý khách bổ sung thông tin&nbsp;hoặc từ chối giải quyết khiếu
                                    nại.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 2: Nếu thông tin quý khách cung cấp là đầy đủ, chính xác, HPay sẽ kiểm tra lại tình trạng&nbsp;giao dịch trên
                                    hệ thống và kết quả cung cấp sản phẩm, dịch vụ với các đối tác của HPay. Nếu lỗi&nbsp;thuộc về phía HPay thì HPay
                                    sẽ thực hiện cung cấp lại sản phẩm, dịch vụ hoặc thực hiện quy trình&nbsp;hoàn tiền cho quý khách, tùy thuộc vào
                                    sản phẩm, dịch vụ quý khách đã thực hiện giao dịch. Thời&nbsp;gian giải quyết tối đa là 3 ngày làm việc kể từ khi
                                    nhận được thông tin yêu cầu khiếu nại của quý&nbsp;khách.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Bước 3: Sau khi cung cấp lại dịch vụ hoặc hoàn tiền thành công, HPay sẽ xác nhận lại với quý&nbsp;khách bằng email
                                    hoặc điện thoại và kết thúc quy trình xử lý khiếu nại.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>5. Quy trình hoàn tiền</span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    HPay chỉ chấp nhận thanh toán trực tuyến bằng tài khoản ngân hàng, qua cổng thanh toán HPay,&nbsp;mà không nhận
                                    tiền mặt. Do đó, khi tiến hành hoàn tiền cho quý khách, HPay cũng sẽ hoàn tiền vào&nbsp;tài khoản ngân hàng hoặc
                                    tài khoản Ví HPay mà quý khách đã sử dụng khi thanh toán. Số tiền hoàn&nbsp;là 100% số tiền quý khách đã thanh
                                    toán cho sản phẩm, dịch vụ tại HPay.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    HPay không chịu trách nhiệm hoàn tiền cho các giá trị khuyến mãi quý khách có thể được hưởng&nbsp;khi mua sản
                                    phẩm, dịch vụ như chiết khấu bán hàng, tặng phẩm, các chương trình khuyến mãi của&nbsp;mạng viễn thông hoặc các
                                    đối tác cung cấp dịch vụ khác của HPay.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    HPay sẽ gửi yêu cầu hoàn tiền sang ngân hàng mà quý khách đã sử dụng khi thanh toán. Khi đó,&nbsp;ngân hàng có thể
                                    yêu cầu quý khách xác nhận để hoàn tất thủ tục, quý khách vui lòng thực hiện&nbsp;theo các hướng dẫn của ngân
                                    hàng.
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    Nếu quý khách có bất cứ yêu cầu nào liên quan đến việc sử dụng dịch vụ tại HPay, xin quý khách&nbsp;vui lòng liên
                                    hệ đến Bộ phận Chăm sóc Khách hàng của HPay qua số điện thoại đường dây nóng:
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>
                                    1900 0128. Bộ phận Chăm sóc Khách hàng sẽ tiếp nhận và xử lý yêu cầu của quý khách trong thời
                                </span>
                            </span>
                        </p>
                        <p style={{ lineHeight: "107%", margin: "0cm 0cm 8pt", textAlign: "justify" }}>
                            <span style={{ fontFamily: '"Times New Roman",serif', fontSize: "13.0pt" }}>
                                <span style={{ lineHeight: "107%" }}>gian sớm nhất.</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
