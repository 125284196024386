const configHeaders = (customHeaders) => ({
    headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        ...customHeaders,
    },
});


export default configHeaders;
