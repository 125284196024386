import React, { useEffect } from "react";
import "./PaymentGateways.scss";
import Banner from "../../../components/Banner/Banner";
import imgP1 from "../../../assets/images/imgP1.png";
import ggplay from "../../../assets/icon/ggplay.png";
import appstore from "../../../assets/icon/appstore.png";
import imgP2 from "../../../assets/images/imgP2.png";
import tick from "../../../assets/icon/tick-02.svg";
import tutorial1 from "../../../assets/icon/tutorial-1.png";
import tutorial2 from "../../../assets/icon/tutorial-2.png";
import tutorial3 from "../../../assets/icon/tutorial-3.png";
import tutorial4 from "../../../assets/icon/tutorial-4.png";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "antd";
const PaymentGateways = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const items = [
        {
            label: (
                <Link to={"/san-pham"} className="desc-2 text-center">
                    Cổng thanh toán
                </Link>
            ),
            key: "0",
        },
        {
            label: (
                <Link to={"/san-pham/vi-dien-tu"} className="desc-2 text-center">
                    Ví điện tử
                </Link>
            ),
            key: "1",
        },

        {
            label: (
                <Link to={"/san-pham/thu-ho-chi-ho"} className="desc-2 text-center">
                    Dịch vụ Thu hộ/Chi hộ
                </Link>
            ),
            key: "3",
        },
    ];
    return (
        <div className="section-top">
            <div className="container">
                <Banner
                    img={imgP1}
                    title={"Sản phẩm - dịch vụ"}
                    desc={["HPay cung cấp các giải pháp trung gian thanh toán toàn diện với nhiều dịch vụ và tiện ích cho tất cả khách hàng."]}
                    bgr={"to right, #FFEFBD, #EBC1F5"}
                    apps={[ggplay, appstore]}
                />
                <div className="section-top-big">
                    <div className="row">
                        <div className="col-md-4 d-none d-md-block">
                            <ul className="list-unstyled">
                                <li>
                                    <Link to={"/san-pham"} className="link-hover-tab active">
                                        Cổng thanh toán
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <Link to={"/san-pham/vi-dien-tu"} className="link-hover-tab">
                                        Ví điện tử
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <Link to={"/san-pham/thu-ho-chi-ho"} className="link-hover-tab">
                                        Dịch vụ Thu hộ/Chi hộ
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-8 col-12">
                            <h2 className="fw-bold mt-lg-0 fs-1">Cổng thanh toán trực tuyến</h2>
                            <div className="col-md-4 d-md-none">
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottom"
                                    arrow
                                    trigger={["click"]}
                                >
                                    <Button
                                        className="w-100 py-3 rounded-pill desc-2 d-flex align-items-center justify-content-center my-4"
                                        style={{ backgroundColor: "#F1F2F4" }}
                                    >
                                        Cổng thanh toán
                                    </Button>
                                </Dropdown>
                            </div>
                            <ul className="my-4 list-unstyled">
                                <li className="mb-4">
                                    <h4 className="title-2 mb-2">Đa dạng nền tảng</h4>
                                    <p className="desc-1">Hỗ trợ giao diện tương thích trên mọi trình duyệt, máy tính và thiết bị di động.</p>
                                </li>
                                <li className="mb-4">
                                    <h4 className="title-2 mb-2">Phương thức thanh toán hỗ trợ</h4>
                                    <div>
                                        <p className="desc-1">Hỗ trợ hệ điều hành Android và IOS.</p>
                                        <p className="desc-1">Giao diện thanh toán trên In-App.</p>
                                    </div>
                                </li>
                                <li className="mb-4">
                                    <h4 className="title-2 mb-2">Đa dạng nền tảng</h4>
                                    <span className="desc-1">
                                        Tạo đường link thanh toán và gửi cho khách hàng của bạn qua mail, ứng dụng chat,...
                                    </span>
                                </li>
                            </ul>
                            <div className="my-5">
                                <div data-aos="fade-left">
                                    <img src={imgP2} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="mt-5">
                                <h2 className="fw-bold fs-1">Giải pháp thanh toán</h2>
                                <ul className="my-4 list-unstyled">
                                    <li>
                                        <img src={tick} alt="" />
                                        <span className="desc-2">Thanh toán ATM nội địa</span>
                                    </li>
                                    <li className="mt-3">
                                        <img src={tick} alt="" />
                                        <span className="desc-2">Thanh toán thẻ Quốc tế</span>
                                    </li>
                                    <li className="mt-3">
                                        <img src={tick} alt="" />
                                        <span className="desc-2">Thanh toán trả góp</span>
                                    </li>
                                    <li className="mt-3">
                                        <span className="desc-2">Hỗ trợ hơn 40 ngân hàng</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-5">
                                <h2 className="fw-bold fs-1">Hướng dẫn tích hợp</h2>

                                <div className="tutorials my-3">
                                    <div data-aos="fade-down">
                                        <div className="tutorial-wrapper-left">
                                            <div className="tutorial-color" data-color="D7B1FF"></div>
                                            <div className="tutorial-icon">
                                                <img src={tutorial1} className="img-fluid" alt="" />
                                            </div>
                                            <div className="d-flex">
                                                <h2 className="px-3 fw-bold">01</h2>

                                                <div className="tutorial-content-text">
                                                    <span className="tutorial-content-text_title fw-bold">Ký hợp đồng với HPay</span>
                                                    <p className="tutorial-content-text_desc">
                                                        Quý khách vui lòng liên hệ HPay để ký kết hợp đồng hợp tác
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-down">
                                        <div className="tutorial-wrapper tutorial-top">
                                            <div className="tutorial-color" data-color="71FFB6"></div>

                                            <div className="tutorial-icon">
                                                <img src={tutorial2} className="img-fluid" alt="" />
                                            </div>
                                            <div className="d-flex">
                                                <h2 className="px-3 fw-bold">02</h2>

                                                <div className="tutorial-content-text">
                                                    <p className="tutorial-content-text_desc">Kích hoạt cổng thanh toán HPay cho merchant</p>
                                                    <p className="tutorial-content-text_desc">Hướng dẫn tích hợp theo file đính kèm phía dưới</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-down">
                                        <div className="tutorial-wrapper-left">
                                            <div className="tutorial-color" data-color="FFCC97"></div>

                                            <div className="tutorial-icon">
                                                <img src={tutorial3} className="img-fluid" alt="" />
                                            </div>
                                            <div className="d-flex">
                                                <h2 className="px-3 fw-bold">03</h2>

                                                <div className="tutorial-content-text">
                                                    <p className="tutorial-content-text_desc">
                                                        Sau khi tích hợp thành công hình thức thanh toán HPay vào merchant site.
                                                    </p>
                                                    <p className="tutorial-content-text_desc">
                                                        Phương thức thanh toán qua cổng thanh toán HPay sẽ được hiển thị khi khách hàng thực hiện đến
                                                        bước đặt hàng và thanh toán.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div data-aos="fade-down">
                                        <div className="tutorial-wrapper tutorial-top tutorial-item-top mb-5">
                                            <div className="tutorial-color" data-color="D7B1FF"></div>

                                            <div className="tutorial-icon">
                                                <img src={tutorial4} className="img-fluid" alt="" />
                                            </div>
                                            <div className="d-flex">
                                                <h2 className="px-3 fw-bold">04</h2>

                                                <div className="tutorial-content-text">
                                                    <p className="tutorial-content-text_desc">
                                                        Khi có đơn hàng được thanh toán, quản trị website có thể truy cập vào hệ thống tra cứu của
                                                        HPay để kiểm tra giao dịch.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5 mb-5 d-flex gap-3 flex-wrap clear-both">
                                <a
                                    href="https://api-ref.hpay.com.vn/#introduction"
                                    className="button-link-large"
                                    style={{ width: "15rem" }}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Link tích hợp thanh toán
                                </a>
                                <Link to={"/lien-he"} className="button-link-large-replace" style={{ width: "15rem" }}>
                                    Liên hệ
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentGateways;
