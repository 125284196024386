import React, { useEffect, useState } from "react";
import { Table, Select, Card } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import contactAdminService from "../../services/adminService/ContactAdminService";
import checkStatusUser from "../../config/UnAuthenticated";
import { useNavigate } from "react-router-dom";
import Authority from "../../components/Authority/Authority";

const ListContact = () => {
    const [data, setData] = useState([]);
    const [isStatusUser, setIsStatusUser] = useState(false);
    const navigate = useNavigate();

    const handleChangeStatus = (id, selectedValue) => {
        try {
            const formData = new FormData();
            formData.append("status", selectedValue);

            const data = contactAdminService.updatedContact(id, formData);

            data.then((res) => {
                if (res?.status === 200) {
                    toast.success(res?.message, {
                        position: "top-center",
                        autoClose: 500,
                        theme: "light",
                    });
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 1000,
                        theme: "light",
                    });
                }
            }).catch((err) => {
                if (checkStatusUser(err?.response?.statusText)) {
                    setIsStatusUser(true);
                } else {
                    return;
                }
            });
        } catch (err) {
            toast.error(err, {
                position: "top-center",
                autoClose: 1000,
                theme: "light",
            });
        }
    };
    const columns = [
        {
            title: "Tên",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Công ty",
            dataIndex: "company",
            key: "company",
        },
        {
            title: "Số điện thoại",
            dataIndex: "mobile",
            key: "mobile",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Tiêu đề",
            dataIndex: "title",
            key: "title",
        },
        {
            width: "20%",
            title: "Nội dung",
            dataIndex: "content",
            key: "content",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            render: (status) => {
                return (
                    <Select
                        defaultValue={status?.text}
                        style={{
                            width: "120px",
                        }}
                        onChange={(selectedValue) => handleChangeStatus(status?.id, selectedValue)}
                        options={[
                            {
                                value: "1",
                                label: "Chờ hỗ trợ",
                            },
                            {
                                value: "2",
                                label: "Đã hỗ trợ",
                            },
                        ]}
                    />
                );
            },
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "updated_at",
            key: "updated_at",
        },
    ];

    useEffect(() => {
        try {
            const data = contactAdminService.getContacts();
            data.then((res) => {
                if (res?.status === 200) {
                    setData(res?.data);
                } else {
                    toast.warn(res?.message, {
                        position: "top-center",
                        autoClose: 1000,
                        theme: "light",
                    });
                }
            }).catch((err) => {
                if (checkStatusUser(err?.response?.statusText)) {
                    setIsStatusUser(true);
                } else {
                    return;
                }
            });
        } catch (err) {
            toast.error(err, {
                position: "top-center",
                autoClose: 1000,
                theme: "light",
            });
        }
    }, []);

    const handleData = data?.map((item) => {
        const formatDate = (date) => moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
        return {
            ...item,
            created_at: formatDate(item.created_at),
            updated_at: formatDate(item.updated_at),
            status: { text: item.status === 1 ? "Chờ hỗ trợ" : item.status === 2 ? "Đã hỗ trợ" : "", id: item.id },
        };
    });

    const handleOkCheckStatusUser = () => {
        setIsStatusUser(false);
        localStorage.clear();
        navigate("/admin/login");
    };

    return (
        <div className="p-3">
            {isStatusUser && <Authority oke={handleOkCheckStatusUser} status={isStatusUser} />}

            <Card title="LIÊN HỆ" bordered={false}>
                <Table columns={columns} dataSource={handleData?.map((item, index) => ({ ...item, status: item.status, key: index }))} />
            </Card>
        </div>
    );
};

export default ListContact;
