import React from "react";

const NotFound = () => {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: "#1a202c", height: "100vh", width: "100%" }}>
            <div className=" d-flex gap-3 fs-5 text-secondary">
                404 <span className="border-start ps-3">NOT FOUND</span>
            </div>
        </div>
    );
};

export default NotFound;
