import React, { useEffect } from "react";
import "./Services";
import { Link } from "react-router-dom";
import Banner from "../../../components/Banner/Banner";
import ggplay from "../../../assets/icon/ggplay.png";
import appstore from "../../../assets/icon/appstore.png";
import imgE3 from "../../../assets/images/imgE3.png";
import tick from "../../../assets/icon/tick-02.svg";
import chi_ho from "../../../assets/images/chi_ho.png";
import thu_ho from "../../../assets/images/thu_ho.png";
import { Button, Dropdown } from "antd";

const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const items = [
        {
            label: (
                <Link to={"/san-pham"} className="desc-2 text-center">
                    Cổng thanh toán
                </Link>
            ),
            key: "0",
        },
        {
            label: (
                <Link to={"/san-pham/vi-dien-tu"} className="desc-2 text-center">
                    Ví điện tử
                </Link>
            ),
            key: "1",
        },

        {
            label: (
                <Link to={"/san-pham/thu-ho-chi-ho"} className="desc-2 text-center">
                    Dịch vụ Thu hộ/Chi hộ
                </Link>
            ),
            key: "3",
        },
    ];
    return (
        <div className="section-top">
            <div className="container">
                <Banner
                    img={imgE3}
                    title={"Sản phẩm - dịch vụ"}
                    desc={["HPay cung cấp các giải pháp trung gian thanh toán toàn diện với nhiều dịch vụ và tiện ích cho tất cả khách hàng."]}
                    bgr={"to right, #FFEFBD, #EBC1F5"}
                    apps={[ggplay, appstore]}
                />
                <div className="section-top-big">
                    <div className="row">
                        <div className="col-md-4 d-none d-md-block">
                            <ul className="list-unstyled">
                                <li>
                                    <Link to={"/san-pham"} className="link-hover-tab">
                                        Cổng thanh toán
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <Link to={"/san-pham/vi-dien-tu"} className="link-hover-tab">
                                        Ví điện tử
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <Link to={"/san-pham/thu-ho-chi-ho"} className="link-hover-tab active">
                                        Dịch vụ Thu hộ/Chi hộ
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-8 col-12">
                            <h2 className="fw-bold mt-lg-0 fs-1">Trải nghiệm tiện ích dịch vụ thu hộ - chi hộ</h2>
                            <div className="col-md-4 d-md-none">
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottom"
                                    arrow
                                    trigger={["click"]}
                                >
                                    <Button
                                        className="w-100 py-3 rounded-pill desc-2 d-flex align-items-center justify-content-center my-4"
                                        style={{ backgroundColor: "#F1F2F4" }}
                                    >
                                        Dịch vụ Thu hộ/Chi hộ
                                    </Button>
                                </Dropdown>
                            </div>

                            <ul className="my-4 list-unstyled">
                                <li>
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Phục vụ 24/7</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Dễ dàng tích hợp đa kênh</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Giao dịch nhanh chóng, chính xác</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Theo dõi thời gian thực</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">An toàn, bảo mật</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Tiết kiệm thời gian - chi phí - nhân lực</span>
                                </li>
                            </ul>
                            <div className="mb-4">
                                <h2 className="fw-bold mt-5 mb-1 fs-1 mb-3">Dịch vụ thu hộ/Chi hộ</h2>
                                <p className="desc-1">
                                    Dịch vụ Ví điện tử HPay cung cấp tính năng thanh toán trực tuyến các hóa đơn, dịch vụ phổ biến hiện nay như: thanh
                                    toán hóa đơn nước, điện, chuyển tiền qua số điện thoại,... một cách nhanh chóng, tiện lợi và tiết kiệm.
                                </p>
                            </div>
                            <div className="my-3">
                                <img src={thu_ho} className="img-fluid" alt="" />
                            </div>
                            <div className="mb-4">
                                <h2 className="fw-bold mt-5 mb-1 fs-1 mb-3">Dịch vụ hỗ trợ chi hộ</h2>
                                <p className="desc-1" style={{ textAlign: "justify" }}>
                                    Dịch vụ chi hộ là giải pháp HPay thay mặt các doanh nghiệp, tổ chức chi trả an toàn, nhanh chóng các khoản tiền
                                    cho nhân viên, đối tác, khách hàng
                                </p>
                            </div>
                            <div className="mb-5">
                                <img src={chi_ho} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
