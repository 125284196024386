import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
    const { pathFrom, textFrom, to } = props;
    return (
        <nav aria-label="breadcrumb" className="my-4">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to={pathFrom}>{textFrom}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    {to}
                </li>
            </ol>
        </nav>
    );
};

export default Breadcrumb;
