import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";
import dataLocalStorage from "../../config/DataLocalStorage";

const AdminLogin = async (formData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}auth/login`, formData, configHeaders());
    if (data.data) {
        localStorage.setItem("admin", JSON.stringify(data.data));
        localStorage.setItem("token", JSON.stringify(data.token));
    }
    return data;
};

const AdminLogout = async () => {
    const token = dataLocalStorage.token;
    if (!token) {
        throw new Error("Token không tồn tại");
    }
    const customHeaders = {
        Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}user/logout`, [], configHeaders(customHeaders));

    return data;
};

const authService = {
    AdminLogin,
    AdminLogout,
};

export default authService;
