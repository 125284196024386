import React, { useEffect, useState } from "react";
import { Card, Statistic } from "antd";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import categoryService from "../../services/adminService/CateAdminService";
import articleService from "../../services/adminService/ArticleAdminService";
import contactAdminService from "../../services/adminService/ContactAdminService";
import { toast } from "react-toastify";

const Dashboard = () => {
    const [cateAmount, setCateAmount] = useState(0);
    const [articlesAmount, setArticlesAmount] = useState(0);
    const [contactAmount, setContactAmount] = useState(0);
    const formatter = (value) => <CountUp end={value} separator="," />;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataCates = categoryService.getCategories();
                const dataArticles = articleService.getArticles();
                const dataContact = contactAdminService.getContacts();

                const [resCate, resArticles, resContact] = await Promise.all([dataCates, dataArticles, dataContact]);

                if (resCate.status === 200) {
                    setCateAmount(resCate?.data?.length);
                } else {
                    toast.warn(resCate.message, {
                        position: "top-center",
                        autoClose: 1000,
                        theme: "light",
                    });
                    return;
                }
                if (resArticles.status === 200) {
                    setArticlesAmount(resArticles?.data?.length);
                } else {
                    toast.warn(resArticles.message, {
                        position: "top-center",
                        autoClose: 1000,
                        theme: "light",
                    });
                    return;
                }
                if (resContact.status === 200) {
                    setContactAmount(resContact?.data?.length);
                } else {
                    toast.warn(resContact.message, {
                        position: "top-center",
                        autoClose: 1000,
                        theme: "light",
                    });
                    return;
                }
            } catch (err) {
                toast.error(err, {
                    position: "top-center",
                    autoClose: 1000,
                    theme: "light",
                });
                return;
            }
        };
        fetchData();
    }, []);

    return (
        <div className="p-3">
            <Card title="Thống kê dữ liệu" bordered={false}>
                <div className="d-flex gap-4 justify-content-between align-items-center">
                    <Link to={"/admin/articles"} className="w-100">
                        <Card
                            title="Số lượng bài viết"
                            style={{
                                backgroundColor: "#AED9E0",
                                textAlign: "center",
                            }}
                        >
                            <Statistic value={articlesAmount} formatter={formatter} />
                        </Card>
                    </Link>
                    <Link to={"/admin/category"} className="w-100">
                        <Card
                            title="Số lượng danh mục"
                            style={{
                                backgroundColor: "#F9DCE1",
                                textAlign: "center",
                            }}
                        >
                            <Statistic value={cateAmount} formatter={formatter} />
                        </Card>
                    </Link>
                    <Link to={"/admin/contact"} className="w-100">
                        <Card
                            title="Số lượng liên hệ"
                            style={{
                                backgroundColor: "#FBE7B7",
                                textAlign: "center",
                            }}
                        >
                            <Statistic value={contactAmount} formatter={formatter} />
                        </Card>
                    </Link>
                </div>
            </Card>
        </div>
    );
};

export default Dashboard;
