import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";
import dataLocalStorage from "../../config/DataLocalStorage";

const getContacts = async () => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_ADMIN}contact`, configHeaders(customHeaders));
    return data;
};

const updatedContact = async (contactID, formData) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}contact/update/${contactID}`, formData, configHeaders(customHeaders));
    return data;
};

const contactAdminService = {
    getContacts,
    updatedContact,
};

export default contactAdminService;
