import Contact from "../../pages/Contact/Contact";
import Home from "../../pages/Home/Home";
import News from "../../pages/News/News";
import EWallets from "../../pages/Products/EWallets/EWallets";
import PaymentGateways from "../../pages/Products/PaymentGateways/PaymentGateways";
import Services from "../../pages/Products/Services/Services";
import About from "../../pages/About/About";
import SingleNews from "../../pages/SingleNews/SingleNews";
import NewsByCategory from "../../pages/NewsByCategory/NewsByCategory";
import Agreement from "../../pages/Agreement/Agreement";
import Privacy from "../../pages/Privacy/Privacy";
import Term from "../../pages/Term/Term";

const routerWeb = [
    {
        path: "/",
        element: Home,
    },
    {
        path: "/ve-chung-toi",
        element: About,
    },
    {
        path: "/thoa-thuan-nguoi-dung",
        element: Agreement,
    },
    {
        path: "/quy-dinh-khieu-nai-va-hoan-tien",
        element: Privacy,
    },
    {
        path: "/san-pham",
        element: PaymentGateways,
    },
    {
        path: "/san-pham/vi-dien-tu",
        element: EWallets,
    },
    {
        path: "/san-pham/thu-ho-chi-ho",
        element: Services,
    },
    {
        path: "/lien-he",
        element: Contact,
    },
    {
        path: "/tin-tuc",
        element: News,
    },
    {
        path: "/bai-viet/:id",
        element: SingleNews,
    },

    {
        path: "/tin-tuc/:id",
        element: NewsByCategory,
    },
    {
        path: "/chinh-sach-bao-mat",
        element: Term,
    },
];

export default routerWeb;
