import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import logoLogin from "../../assets/icon/hpay-white.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../../services/adminService/AuthAdminService";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("token"))) {
            navigate("/admin");
        }
    }, [navigate]);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Địa chỉ email không hợp lệ").required("Vui lòng điền Email"),
            password: Yup.string().required("Vui lòng điền Mật Khẩu"),
        }),
        onSubmit: (values) => {
            setLoading(true);

            if (!values.email || !values.password) {
                setLoading(false);
                return;
            }

            try {
                const formData = new FormData();
                formData.append("email", values.email);
                formData.append("password", values.password);

                const data = authService.AdminLogin(formData);
                data.then((res) => {
                    setLoading(false);
                    if (res?.status === 200) {
                        setTimeout(() => {
                            navigate("/admin");
                            window.location.reload();
                        }, 1000);
                    } else {
                        toast.warn(res?.message, {
                            position: "top-center",
                            autoClose: 3000,
                            theme: "light",
                        });
                        setLoading(false);
                    }
                }).catch((err) => {
                    toast.error(err?.message, {
                        position: "top-center",
                        autoClose: 2000,
                        theme: "light",
                    });
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
            }
        },
    });

    return (
        <div className="container-fluid px-0">
            <div className="row g-0">
                <div className="col-md-6 col-12">
                    <div className="hero-wrap">
                        <div className="hero-mask"></div>
                        <div className="hero-bg"></div>
                        <div className="hero-content mx-auto w-100 h-100 d-flex flex-column">
                            <div className="row g-0 ">
                                <div className="col-12">
                                    <div className="logo-login mb-5 mb-md-0">
                                        <img src={logoLogin} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-0 my-auto">
                                <div className="col-10 col-lg-9 mx-auto">
                                    <h1 className="text-large text-white mb-4">Chào mừng bạn đến với HPay!</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 d-flex align-items-center" style={{ backgroundColor: "#f0dede" }}>
                    <div className="container my-4 ">
                        <div className="row">
                            <div className="col-11 col-lg-9 col-xl-8 mx-auto">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-between">
                                        <h1 className="text-medium  mb-4">Đăng nhập</h1>
                                    </div>
                                </div>
                                <form method="POST" onSubmit={formik.handleSubmit}>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="mb-1">
                                            Email tài khoản
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control ps-3"
                                            id="email"
                                            placeholder="Email tài khoản"
                                            aria-describedby="emailHelp"
                                            onChange={formik.handleChange("email")}
                                            onBlur={formik.handleBlur("email")}
                                            value={formik.values.email}
                                        />
                                        <div id="emailHelp" className="form-text text-danger ps-1">
                                            {formik.touched.email && formik.errors.email ? formik.errors.email : null}
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="pass" className="mb-1">
                                            Mật khẩu
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control fs-6 px-4 text-error"
                                            id="pass"
                                            placeholder="Mật khẩu"
                                            aria-describedby="passHelp"
                                            onChange={formik.handleChange("password")}
                                            onBlur={formik.handleBlur("password")}
                                            value={formik.values.password}
                                        />
                                        <div id="passHelp" className="form-text text-danger ps-1">
                                            {formik.touched.password && formik.errors.password ? formik.errors.password : null}
                                        </div>
                                    </div>

                                    <button className="btn-login-submit mt-5" type="submit">
                                        {loading ? (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            <span className="text-white fs-6 fw-bold">Đăng nhập</span>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
