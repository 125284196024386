import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/Banner/Banner";
import ggplay from "../../../assets/icon/ggplay.png";
import appstore from "../../../assets/icon/appstore.png";
import imgE1 from "../../../assets/images/imgE1.png";
import imgE2 from "../../../assets/images/imgE2.png";
import tick from "../../../assets/icon/tick-02.svg";
import { Button, Dropdown } from "antd";

const EWallets = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const items = [
        {
            label: (
                <Link to={"/san-pham"} className="desc-2 text-center">
                    Cổng thanh toán
                </Link>
            ),
            key: "0",
        },
        {
            label: (
                <Link to={"/san-pham/vi-dien-tu"} className="desc-2 text-center">
                    Ví điện tử
                </Link>
            ),
            key: "1",
        },

        {
            label: (
                <Link to={"/san-pham/thu-ho-chi-ho"} className="desc-2 text-center">
                    Dịch vụ Thu hộ/Chi hộ
                </Link>
            ),
            key: "3",
        },
    ];
    return (
        <div className="section-top">
            <div className="container">
                <Banner
                    img={imgE1}
                    title={"Sản phẩm - dịch vụ"}
                    desc={["HPay cung cấp các giải pháp trung gian thanh toán toàn diện với nhiều dịch vụ và tiện ích cho tất cả khách hàng."]}
                    bgr={"to right, #FFEFBD, #EBC1F5"}
                    apps={[ggplay, appstore]}
                />
                <div className="section-top-big">
                    <div className="row">
                        <div className="col-md-4 d-none d-md-block">
                            <ul className="list-unstyled">
                                <li>
                                    <Link to={"/san-pham"} className="link-hover-tab">
                                        Cổng thanh toán
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <Link to={"/san-pham/vi-dien-tu"} className="link-hover-tab active">
                                        Ví điện tử
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <Link to={"/san-pham/thu-ho-chi-ho"} className="link-hover-tab">
                                        Dịch vụ Thu hộ/Chi hộ
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-8 col-12">
                            <h2 className="fw-bold mt-lg-0 fs-1">Ví điện tử HPay</h2>
                            <div className="col-md-4 d-md-none">
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottom"
                                    arrow
                                    trigger={["click"]}
                                >
                                    <Button
                                        className="w-100 py-3 rounded-pill desc-2 d-flex align-items-center justify-content-center my-4"
                                        style={{ backgroundColor: "#F1F2F4" }}
                                    >
                                        Ví điện tử
                                    </Button>
                                </Dropdown>
                            </div>
                            <p className="desc-1 my-4">
                                Dịch vụ Ví điện tử HPay cung cấp tính năng thanh toán trực tuyến các hóa đơn, dịch vụ phổ biến hiện nay như: thanh
                                toán hóa đơn nước, điện, chuyển tiền qua số điện thoại,... một cách nhanh chóng, tiện lợi và tiết kiệm.
                            </p>
                            <ul className="mb-4 list-unstyled">
                                <li>
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Hỗ trợ thanh toán đa dạng dịch vụ</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Giao dịch tự động, nhanh chóng</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Dữ liệu mã hóa, bảo mật</span>
                                </li>
                                <li className="mt-2">
                                    <img src={tick} className="me-2" alt="" />
                                    <span className="desc-2">Hỗ trợ hơn 40 thẻ ngân hàng ATM nội địa và thẻ ghi nợ quốc tế</span>
                                </li>
                            </ul>
                            <div className="my-5">
                                <div data-aos="fade-up-left">
                                    <img src={imgE2} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EWallets;
