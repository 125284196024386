import { Button, Modal } from "antd";
import React from "react";
import { FaFaceSadTear } from "react-icons/fa6";

const Authority = ({ oke, status }) => {
    return (
        <Modal title="THÔNG BÁO" open={status} footer={null} closable={false}>
            <div className="d-flex justify-content-center flex-column align-items-center gap-3">
                <FaFaceSadTear className="fs-1" />
                <p className="fs-5 fw-bold">Đã hết phiên đăng nhập </p>
                <span className="desc-2">hoặc tài khoản đã được đăng nhập ở nơi khác</span>
                <span className="desc-1">Vui lòng đăng nhập lại</span>
                <Button onClick={oke} type="primary">
                    Đăng nhập
                </Button>
            </div>
        </Modal>
    );
};

export default Authority;
