import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import imgN1 from "../../assets/images/imgN1.png";
import time from "../../assets/icon/Clip path group.png";
import { Link } from "react-router-dom";
import moment from "moment";
import cateWebService from "../../services/webService/CateWebService";
import { toast } from "react-toastify";
const News = () => {
    const [categories, setCategories] = useState([]);

    const formatDate = (date) => moment.utc(date).format("DD-MM-YYYY HH:mm:ss");

    useEffect(() => {
        try {
            const data = cateWebService.getCategories();
            data.then((res) => {
                if (res?.status === 200) {
                    setCategories(res?.categories);
                } else {
                    toast.warn(res?.status, {
                        position: "top-center",
                        autoClose: 500,
                        theme: "light",
                    });
                }
            }).catch((err) => {
                toast.error(err, {
                    position: "top-center",
                    autoClose: 500,
                    theme: "light",
                });
            });
        } catch (err) {
            toast.error(err, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, []);

    return (
        <div className="section-top">
            <div className="container">
                <Banner
                    img={imgN1}
                    title={"Tin tức"}
                    desc={[" HPay cung cấp các giải pháp trung gian thanh toán toàn diện với nhiều dịch vụ và tiện ích cho tất cả khách hàng."]}
                    bgr={"to right, #FFEFBD, #EBC1F5"}
                />
                <div className="section-top-big">
                    {categories.map((cates, index) => (
                        <div key={index} className="row px-3 px-md-5 mb-5 ">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <h2 className="fs-1 fw-bold">{cates.title}</h2>
                                    <Link to={`/tin-tuc/${cates.slug}`} className="button-transparent">
                                        Xem tất cả
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="row">
                                    {cates.articles.map((article) => (
                                        <Link
                                            key={article.id}
                                            to={`/bai-viet/${article.slug}`}
                                            className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-none card-custom"
                                        >

                                            <button className="btn-card">Xem ngay</button>
                                            <div className="d-flex flex-column justify-content-center">
                                                <img src={article.thumbnail_url} className="img-fluid img-card" alt="thumbnail" />

                                                <h4 className="fs-6 text-hover text-uppercase text-limit mt-3" style={{ height: "40px" }}>
                                                    {article.title}
                                                </h4>
                                                <p className="text-lowercase my-2 text-secondary text-limit">{article.description}</p>
                                                <div className="d-flex align-items-center">
                                                    <img src={time} className="img-fluid me-2" alt="" />
                                                    <span className="text-secondary">{formatDate(article.created_at)}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default News;
