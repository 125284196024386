import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";

const createContact = async (formData) => {
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_WEB}contact`, formData, configHeaders());
    return data;
};

const contactWebService = {
    createContact,
};

export default contactWebService;
