import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";
import dataLocalStorage from "../../config/DataLocalStorage";

const getCategoryList = async () => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_ADMIN}category/list`, configHeaders(customHeaders));
    return data;
};

const getCategories = async (params) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_ADMIN}category`, { ...configHeaders(customHeaders), params: params });

    return data;
};

const getCategory = async (categoryID) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_ADMIN}category/edit/${categoryID}`, configHeaders(customHeaders));
    return data;
};

const createdCategory = async (formData) => {
    const customHeaders = {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}category/store`, formData, configHeaders(customHeaders));
    return data;
};

const updatedCategory = async (formData, categoryID) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}category/update/${categoryID}`, formData, configHeaders(customHeaders));
    return data;
};

const deletedCategory = async (categoryID) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.delete(`${process.env.REACT_APP_HOST_ADMIN}category/${categoryID}`, configHeaders(customHeaders));
    return data;
};

const categoryService = {
    getCategoryList,
    getCategories,
    createdCategory,
    updatedCategory,
    getCategory,
    deletedCategory,
};

export default categoryService;
