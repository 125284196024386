import axios from "axios";
import configHeaders from "../../config/HeadersNetwork";
import dataLocalStorage from "../../config/DataLocalStorage";

const getInfoAdmin = async () => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.get(`${process.env.REACT_APP_HOST_ADMIN}user`, configHeaders(customHeaders));
    return data;
};
const changePass = async (formData) => {
    const customHeaders = {
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}user/change-password`, formData, configHeaders(customHeaders));

    return data;
};
const updateProfile = async (formData) => {
    const customHeaders = {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${dataLocalStorage.token}`,
    };
    const { data } = await axios.post(`${process.env.REACT_APP_HOST_ADMIN}user/update`, formData, configHeaders(customHeaders));

    return data;
};

const infoAdminService = {
    getInfoAdmin,
    changePass,
    updateProfile,
};

export default infoAdminService;
