import React, { useEffect } from "react";
import "./About.scss";
import Banner from "../../components/Banner/Banner";
import bnImgHome from "../../assets/images/bnImgAbout.png";
import ggplay from "../../assets/icon/ggplay.png";
import appstore from "../../assets/icon/appstore.png";
import imgA1 from "../../assets/images/imgA1.png";
import Aos from "aos";
import { typicalPartners } from "../../data/typical-partners";
const About = () => {
    useEffect(() => {
        Aos.init({ duration: 1300 });
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="section-top">
            <div className="container">
                <Banner
                    img={bnImgHome}
                    title={"Về HPay"}
                    desc={["HPay cung cấp các giải pháp trung gian thanh toán toàn diện với nhiều dịch vụ và tiện ích cho tất cả khách hàng."]}
                    bgr={"to right, #FFEFBD, #EBC1F5"}
                    apps={[ggplay, appstore]}
                />
                <div className="section-top-big">
                    <div className="row">
                        <div className="col-12 d-flex flex-column align-items-center mb-5">
                            <h2 className="fs-1 fw-bold mb-4">Hành trình phát triển</h2>
                            <div data-aos="fade-down">
                                <img src={imgA1} style={{ width: "320px" }} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mb-5">
                        <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center mt-5" data-aos="fade-down-right">
                            <div className="about-box-left ">
                                <h2 className="fs-1 fw-bold mb-2">Tầm nhìn</h2>
                                <p className="desc-2">
                                    HPay định hướng phát triển thành đơn vị cung cấp giải pháp thanh toán số được sử dụng phổ biến nhất Việt Nam
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center mt-5" data-aos="fade-down-left">
                            <div className="about-box-right">
                                <h2 className="fs-1 fw-bold mb-2">Sứ mệnh</h2>
                                <p className="desc-2">
                                    HPay hướng tới mục tiêu đưa giải pháp thanh toán không tiền mặt tới tất cả các mảng dịch vụ trên khắp Việt Nam.
                                    Với hệ thống thanh toán rộng khắp, đa dạng dịch vụ triển khai và hứa hẹn sẽ đem tới cho khách hàng và đối tác
                                    những giải pháp tối ưu nhất.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row section-distance">
                        <div className="col-12">
                            <div className="text-center">
                                <h2 className="fs-1 fw-bold ">Đối tác tiêu biểu</h2>
                                <p className="desc-1 my-4 text-center">Những doanh nghiệp đầu ngành đã lựa chọn chúng tôi</p>
                                <div className="d-flex flex-wrap justify-content-center gap-3 mt-4">
                                    {typicalPartners?.map((typicalPartner, index) => {
                                        return (
                                            <img
                                                key={index}
                                                src={typicalPartner}
                                                style={{ width: "96px", height: "56px" }}
                                                className="img-fluid"
                                                alt=""
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default About;
